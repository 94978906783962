import React from 'react'

export default function Blog() {
  return (
    <>
      <div className="blog-section-1 section-space-y bg-neutral-4">
        <div className="section-space-sm-bottom">
          <div className="container">
            <div className="row g-4 justify-content-between">
              <div className="col-md-5">
                <div className="d-flex align-items-center gap-4 flex-wrap">
                  <div className="w-30 subtitle-flush-x subtitle-flush-x--secondary subtitle_line_7" />
                  <h6 className="mb-0 fs-18 clr-secondary-key fw-extrabold animate-text-from-right"> Join Us </h6>
                </div>
              </div>
              <div className="col-md-6 col-xxl-5">
                <h3 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d"> Join & Contribute to <span className="clr-grad-1"> OwnXChain </span> </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13" style={{height:"500px"}}>
                <div className="d-flex align-items-center gap-2">
                  <div className="w-12 h-2px bg-primary-key" />
                  <span className="d-block fw-bold clr-primary-key"> Build  </span>
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/build.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a  className="link d-block clr-neutral-90"> Start building independent dApps for OwnXChain Ecosystem</a>
                  </h6>
                  <div className="d-flex align-items-center gap-2">
                    <span className="d-inline-block w-1 h-1 rounded-circle bg-neutral-90" />
                    <a href="/developers"  className="link d-inline-block clr-neutral-90 fs-14 fw-semibold"> Developer Docs </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13"  style={{height:"500px"}}>
                <div className="d-flex align-items-center gap-2">
                  <div className="w-12 h-2px bg-primary-key" />
                  <span className="d-block fw-bold clr-primary-key"> Govern  </span>
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/govern.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a  className="link d-block clr-neutral-90"> Vote on improvement proposals for developments & upgrades
                    </a>
                  </h6>
                  <div className="d-flex align-items-center gap-2">
                    <span className="d-inline-block w-1 h-1 rounded-circle bg-neutral-90" />
                    <a href="/governance" className="link d-inline-block clr-neutral-90 fs-14 fw-semibold"> Governance Forum 
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13"  style={{height:"500px"}}>
                <div className="d-flex align-items-center gap-2">
                  <div className="w-12 h-2px bg-primary-key" />
                  <span className="d-block fw-bold clr-primary-key"> Advocate  </span>
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/advocate.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a  className="link d-block clr-neutral-90"> AContribute to OwnXChain’s growth by spreading it to masses
                    </a>
                  </h6>
                  <div className="d-flex align-items-center gap-2">
                    <span className="d-inline-block w-1 h-1 rounded-circle bg-neutral-90" />
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScfyH8Y8-OFIsTLV0bKOqpGB4hddDSeeKnyvpoOCVIHuypBKQ/viewform?usp=sf_link" target='_blank' className="link d-inline-block clr-neutral-90 fs-14 fw-semibold"> Apply  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-space-sm-top">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-space-y px-4 top-shadow position-relative z-1 overflow-hidden rounded-3 bg-neutral-key bg-opacity-6">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-xl-8 col-xxl-6">
                      <h4 className="mb-14 text-center clr-neutral-90 animate-line-3d"> Whether a developer, enthusiasts or casual blockchain persona
                        <span className="clr-grad-1">– everyone are welcomed </span>
                      </h4>
                      <div className="d-flex align-items-center justify-content-center gap-6 flex-wrap fadein_bottom_14">
                        <a href="/community" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 px-6 py-3 rounded fw-bold text-center">
                          <span className="d-inline-block"> Join Community </span>
                          <span className="d-inline-block">
                            <i className="bi bi-arrow-right" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <img src="assets/img/nl-img-1.webp" alt="image" className="img-fluid newsletter-1-img newsletter-1-img--1 fadein_2" />
                  <img src="assets/img/nl-img-2.webp" alt="image" className="img-fluid newsletter-1-img newsletter-1-img--2 fadein_3" />
                  <img src="assets/img/nl-img-3.webp" alt="image" className="img-fluid newsletter-1-img newsletter-1-img--3 fadein_4" />
                  <img src="assets/img/nl-img-4.webp" alt="image" className="img-fluid newsletter-1-img newsletter-1-img--4 fadein_5" />
                  <img src="assets/img/nl-img-5.webp" alt="image" className="img-fluid newsletter-1-img newsletter-1-img--5 fadein_6" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/img-1/blog-section-1-img-1.png" alt="image" className="img-fluid blog-section-1__img blog-section-1__img--1" />
        <img src="assets/img/blog-section-1-img-2.webp" alt="image" className="img-fluid blog-section-1__img blog-section-1__img--2" />
      </div>

    </>
  )
}
