import React from 'react'
import Header from './Header'

export default function PrivacyPolicy() {
  return (
    <div>
        <Header/>
    <section className="hero-1">
<div className="container">
<div>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d "><span style={{color: '#ffffff'}}> Privacy Policy</span></h5>
  <div className="jss5"><span style={{color: '#ffffff'}}>&nbsp;</span></div>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>1. Our Role In Your Privacy</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>This privacy policy applies to you if you:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>Visit our website at&nbsp;<a style={{color: '#ffffff'}} rel="noopener">https://ownxchain.com</a></span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Engage with us in other related ways ― including any sales, marketing, or events.</span></li>
  </ul>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>In this privacy policy, if we refer to:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>“<strong>Website</strong>,” we are referring to any website of ours that references or links to this privacy policy.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>“<strong>Services</strong>,” we are referring to our Website, and other related services, including any sales, marketing, or events.</span></li>
  </ul>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>The purpose of this privacy policy is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Services immediately.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}><strong>Please read this privacy policy carefully, as it will help you understand what we do with the information that we collect.</strong></span></p>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>2. Your Responsibilities</span></h5>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>Read this privacy policy</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>If you provide us with personal information about other people, or if others give us your information, we will only use that information for the specific reason for which it was provided to us. By submitting the information you confirm that you have the right to authorise us to process it on our behalf in accordance with this privacy policy.</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>3. What Information Do We Collect?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>From the first moment you interact with Fantom we are collecting data. Sometimes you provide us with data, sometimes we collect data about you, either automatically or from other sources, like publicly available on websites or from a trusted data supplier.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>Here’s what data we collect:</span></p>
  <ul>
    <li><span style={{color: '#ffffff'}}><strong>a. Personal information you disclose to us</strong></span></li>
    <li><span style={{color: '#ffffff'}}>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Website or otherwise when you contact us.</span></li>
    <li><span style={{color: '#ffffff'}}>The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make and the products, Services, and features you use. The personal information we collect may include the following:</span></li>
    <ul>
      <li className="jss2"><span style={{color: '#ffffff'}}>Contact details – We may collect names; phone numbers; email addresses; mailing addresses; contact preferences; and other similar information.</span></li>
    </ul>
  </ul>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</span></p>
  <ul>
    <li><span style={{color: '#ffffff'}}><strong>b. Information automatically collected/ collected by u</strong></span></li>
    <li><span style={{color: '#ffffff'}}>We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include the following:</span></li>
    <ul>
      <li className="jss2"><span style={{color: '#ffffff'}}>Device and usage information – Your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, information about your activity in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use) and device event information (such as system activity, error reports (sometimes called ‘crash dumps’) and hardware settings).</span></li>
      <li className="jss2"><span style={{color: '#ffffff'}}>Location data – Country, location, information about how and when you use our Website and other technical information such as device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information. The amount of information we collect depends on the type and settings of the device you use to access the Website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your ‘location setting’ on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</span></li>
    </ul>
    <li><span style={{color: '#ffffff'}}>This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>4. How Do We Use Your Information?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We may use your information which we collect or receive for the purposes set out below:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To send administrative information to you</strong>&nbsp;– to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To protect our Services</strong>&nbsp;– as part of our efforts to keep our Website safe and secure (for example, for fraud monitoring and prevention).</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To enforce our terms, conditions and policies</strong>&nbsp;– for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To respond to legal requests and prevent harm</strong>&nbsp;– If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Fulfill and manage your orders</strong>&nbsp;– to fulfill and manage your orders, payments, returns, and exchanges made through the Website.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Administer prize draws and competitions</strong>&nbsp;– to administer prize draws and competitions when you elect to participate in our competitions.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To deliver and facilitate delivery of services to the user</strong>&nbsp;– to provide you with the requested service.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To respond to user inquiries/offer support to users</strong>&nbsp;– to respond to your inquiries and solve any potential issues you might have with the use of our Services.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>To send you marketing and promotional communications</strong>&nbsp;– We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, provided this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Website, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Deliver targeted advertising to you</strong>&nbsp;– to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Responding to Job Applications</strong>&nbsp;– to respond to an employment or independent contractor application you may have submitted with Fantom.</span></li>
  </ul>
  <h5 id="will-your-info" className=" clr-neutral-90 fw-extrabold animate-line-3d"><span style={{color: '#ffffff'}}>5. Will Your Information Be Shared With Anyone?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We may process or share your data that we hold based on the following legal basis:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Consent</strong>&nbsp;– if you have given us specific consent to use your personal information for a specific purpose.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Legitimate Interests</strong>&nbsp;– when it is reasonably necessary to achieve our legitimate business interests.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Performance of a Contract</strong>&nbsp;– Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Legal Obligations</strong>&nbsp;– where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Vital Interests</strong>&nbsp;– where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></li>
  </ul>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>More specifically, we may need to process your data or share your personal information in the following situations:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Business Transfers</strong>&nbsp;– We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Vendors, Consultants, and Other Third-Party Service Providers</strong>&nbsp;– We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Website, which will enable them to collect data on our behalf about how you interact with our Website over time. This information may be used to, among other things, analyse and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this privacy policy , we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Affiliates</strong>&nbsp;– We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>Business Partners</strong>&nbsp;– We may share your information with our business partners to offer you certain products, services, or promotions.</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>6. Who Will Your Information Be Shared With?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We only share and disclose your information with the following categories of third parties. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in section 14 below&nbsp;<a style={{color: '#ffffff'}}>“HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?”</a>.</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>Data Analytics Service Providers</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Data Storage Service Providers</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Cloud Computing Service Providers</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Communication &amp; Collaboration Tools</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Performance Monitoring Tools</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Product Engineering &amp; Design Tools</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Social Networks</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Sales &amp; Marketing Tools</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Testing Tools</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Website Hosting Service Providers</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>7. Do We Use Cookies And Other Tracking Technologies?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We do not use cookies or tracking technologies. Rather, we utilise anonymous page view tracking information provided by third-party analytics provider, which does not collect or store any personal data. As such, we make no use of cookies.</span></p>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>8. Is Your Information Transferred Internationally?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>Our servers are located in the Cayman Islands. If you are accessing our Website from outside the Cayman Islands, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see&nbsp;<a style={{color: '#ffffff'}}>“WILL YOUR INFORMATION BE SHARED WITH ANYONE?”</a>&nbsp;– see section 5 above), in Australia, India, United States, United Kingdom, and other countries.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>If you are a resident in the European Economic Area&nbsp;<strong>(EEA)</strong>&nbsp;or United Kingdom&nbsp;<strong>(UK)</strong>, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable laws.</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}><strong>European Commission’s Standard Contractual Clause</strong>We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>9. How Long Do We Keep Your Information?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this privacy policy will require us keeping your personal information for longer than 2 years.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>10. How Do We Keep Your Information Safe?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we collect and process.</span></p>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>However, please remember:</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>Despite our safeguards and efforts to secure your information, unfortunately no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>You are responsible for your username and password and should keep them safe.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>If you believe your privacy has been breached, please contact us immediately on&nbsp;<a style={{color: '#ffffff'}} href="mailto:legal@fantom.foundation" target="_blank" rel="noopener">legal@ownxchain.com</a>.</span></li>
  </ul>
  <h5 id="what-are-your-choices" className=" clr-neutral-90 fw-extrabold animate-line-3d"><span style={{color: '#ffffff'}}>11. What Are Your Privacy Choices And Rights?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right to :</span></p>
  <ul>
    <li className="jss2"><span style={{color: '#ffffff'}}>Request access and obtain a copy of your personal information.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Request rectification or erasure.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>Restrict the processing of your personal information and, if applicable, to data portability.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>In certain circumstances, you may also have the right to object to the processing of your personal information.</span></li>
    <li className="jss2"><span style={{color: '#ffffff'}}>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></li>
  </ul>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>12. Controls For Do-not-track Features</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track&nbsp;<strong>(“DNT”)</strong>&nbsp;feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</span></p>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>13. Do We Make Updates To This Privacy Policy?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</span></p>
  <h5  className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>14. How Can You Contact Us About This Privacy Policy?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>If you have questions or comments about this privacy policy, you may email us at <a style={{color: '#ffffff'}} href="#" target="_blank" rel="noopener">info@ownxchain.com</a>&nbsp;or by post to:</span></p>
  <h5 className=" clr-neutral-90 fw-extrabold animate-line-3d h5"><span style={{color: '#ffffff'}}>15. How Can You Review, Update, Or Delete The Data We Collect From You?</span></h5>
  <p className="MuiTypography-root MuiTypography-body2 mui-style-170lgzt"><span style={{color: '#ffffff'}}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, have any inaccurate information corrected or delete it in some circumstances. To request to review, update, or delete your personal information, please contact us on the above provided details.</span></p>
</div>

    </div>  
    </section>
    </div>
  )
}
