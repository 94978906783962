import React from 'react'
import Governancehero from './Governancehero'
import Header from '../Comman/Header'
import Governanceapp from './Governanceapp'
import GovernanceProgress from './GovernanceProgress'
import Footer from '../Comman/Footer'
import Governancepre from './Governancepre'
import Governanceste from './Governanceste'

export default function Governance() {
  return (
    <div>
        <Header/>
        <Governancehero/>
        <Governanceste/>
        <Governancepre/>
        <Governanceapp/>
        {/* <GovernanceProgress/> */}
        <Footer/>
    </div>
  )
}
