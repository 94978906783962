import React from 'react'
import Dapphero from './Dapphero'
import Dapp from './Dapp'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Submitdapp() {
  return (
    <div>
        <Header />
        <Dapphero/>
        <Dapp/>
        <Footer />

    </div>
  )
}
