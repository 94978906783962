import React from 'react'
import Newshero1 from './Newshero1'
import Newsmain from './Newsmain'
import NewsPress from './NewsPress'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Blogsnews() {
  return (
    <div>
        <Header/>
<Newshero1/>
<Newsmain/>
<NewsPress/>
        <Footer/>
    </div>
  )
}
