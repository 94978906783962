import React from "react";

export default function Brandkithmain() {
  return (
    <div>
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-12">
                   
                    <div className="blog-latest-content">
                      <h4 className="h3 mb-6 fw-extrabold text-r">
                        <span className="link clr-neutral-90  "> Logo Variants</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-12">
                  <div className="row g-4">
                  <h4 className="h5 fw-extrabold text-r">
                        <span className="link clr-neutral-90  clr-grad-1"> SVG </span>
                      </h4>
                      <hr className="text-white mb-6 me-2"/>
             <div className="col-12 col-md-6 col-lg-6 mt-4">
     <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12  text-center rounded-3 fadein_bottom_18" style={{height:"170px", translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-logo_light.svg" alt="" />
            <div>
            <a href="assets/img-1/b-logo_light.svg" download class=" mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white" style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12 text-center rounded-3 fadein_bottom_18" style={{ height:"170px",translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-logo_dark.svg" alt="" />
            <div>
            <a href="assets/img-1/b-logo_dark.svg" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>

            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12  text-center rounded-3 fadein_bottom_18" style={{height:"420px",translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-light_svg.svg" alt="" />
            <div>
            <a href="assets/img-1/b-light_svg.svg" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white" style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                   <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12  text-center rounded-3 fadein_bottom_18" style={{height:"420px",translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-dark_svg.svg" alt="" />
            <div>
            <a href="assets/img-1/b-dark_svg.svg" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="row g-4">
                    <h4 className="h5 fw-extrabold text-r">
                        <span className="link clr-neutral-90  clr-grad-1"> PNG </span>
                      </h4>
                      <hr className="text-white mb-6 mx-2"/>
                      <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12 text-center rounded-3 fadein_bottom_18" style={{height:"170px",translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-logo_light.png" alt="" />
            <div>
            <a href="assets/img-1/b-logo_light.png" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12 text-center rounded-3 fadein_bottom_18" style={{height:"170px", translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-logo_dark.png" alt="" />
            <div>
            <a href="assets/img-1/b-logo_dark.png" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12  text-center rounded-3 fadein_bottom_18" style={{height:"420px",translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-ownx_light.png" alt="" />
            <div>
            <a href="assets/img-1/b-ownx_light.png" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
                        <div className="ai-template-card d-flex flex-column justify-content-center p-6 py-lg-12  text-center rounded-3 fadein_bottom_18" style={{height:"420px", translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
            <img src="assets/img-1/b-ownx_dark.png" alt="" />
            <div>
            <a href="assets/img-1/b-ownx_dark.png" download class="mt-4 position-relative z-1 link d-inline-flex justify-content-center px-2 py-2 rounded fw-bold text-center text-white"style={{border:"1px solid #eb9456 "}}> Download </a>
            </div>
            </div>
          </div>
                  </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
