import React from 'react'

export default function Prooalliancemain() {
  return (
    <div>
        <section className="section-space-sm-top section-space-sm-bottom">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xxl-6 col-lg-8 text-center">
        <h6 className="clr-neutral-90"> What makes Proof-of-Alliance different?</h6>
      </div>
    </div>
    <div className="mt-12">
      <div className="pricing-compare-wrapper">
        <div className="pricing-compare-wrapper-inner">
          <span className="pricing-compare-title clr-neutral-90">Proof-of-Stake (PoS) 
</span>
          <span className="pricing-compare-title clr-neutral-90">Proof-of-Alliance (PoA) </span>
          <ul className="pricing-compare-list">
            <li>
              <span className="caption">Delegators</span>
              <div className="writebot-check">
                <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">Ease of upgradability </span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">Application centric </span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">Long-term sustainability</span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">Less energy consumption </span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">100% transaction verification</span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
            <li>
              <span className="caption">Off-chain computation</span>
              <div className="writebot-check">
              <span className="w-7 h-7 bg-danger bg-opacity-3 rounded-circle d-inline-flex justify-content-center align-items-center clr-primary-key">
                <i className="bi bi-x-lg text-white"/>
                </span>
              </div>
              <div className="chatgpt-check">
                <span className="w-7 h-7 bg-opacity-3 bg-success rounded-circle d-inline-flex align-items-center justify-content-center clr-danger-50">
                <i class="bi bi-check2 text-white" />
                </span>
              </div>
            </li>
       
          </ul>
        </div>
        <img src="assets/img/difference-line-top.png" alt="image" className="img-fluid difference-line difference-line-top" />
        <img src="assets/img/difference-line-bottom.png" alt="image" className="img-fluid difference-line difference-line-bottom" />
        <img src="assets/img/difference-line-left.png" alt="image" className="img-fluid difference-line difference-line-left" />
        <img src="assets/img/difference-line-right.png" alt="image" className="img-fluid difference-line difference-line-right" />
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
