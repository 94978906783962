import React from "react";

export default function Wallethero() {
  return (
    <div>
      <div className=" section-space-botmto section-space-top">
        <div className="container">
          <div className="row gy-4 align-items-center justify-content-center">
            <div className="col-lg-7 text-lg-start text-center">
              {/* <img
                src="assets/img/hero-5-title-top.webp"
                alt="top el image"
                className="img-fluid fadeIn_bottom"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              /> */}
              <h2
                className="hero-5-title mb-0 clr-primary-10 fw-extrabold mt-2 animate-text-from-right text-white"
                style={{}}
              >
                {" "}
                Get Your Wallet & Start Using{" "}
                <span className="clr-grad-4"> OwnXChain </span>{" "}
              </h2>
              <div
                className="d-flex flex-wrap align-items-center gap-6 mt-xl-10 mt-lg-8 mt-6 justify-content-lg-start justify-content-center fadeIn_bottom"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <a
                  href="/coming-soon"
                 
                  className="link d-inline-block py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1"
                >
                  {" "}
                  Go to wallet{" "}
                </a>
              </div>
            </div>
            <div className="col-md-10 col-lg-5">
              <div className="position-relative">
                    <img
                      src="assets/img-1/get_your_wallet_start_using_ownxchain.png"
                      alt="image"
                      className="img-fluid "
                  
                    />
                 
                
                   
              
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
