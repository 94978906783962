import React from "react";

export default function StakingWalle() {
  return (
    <div>
      <div className="single-blog-area section-space-top section-space-sm-bottom">
        <div className="container">
          <div className="swiper blog-single-slider ">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-6">
                <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                  <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                    <img
                      src="assets/icon-1/go_to_wallet.png"
                      alt="image"
                      className="img-fluid  object-fit-cover"
                      width="50px"
                    />
                  </div>
                  <div className="mt-auto">
                    <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                      <span className="d-inline-block flex-shrink-0">
                      <img
                          src="assets/img/icon-subtitle.png"
                          alt="image"
                          className="img-fluid"
                        />
                      </span>
                      <a href="/wallet" className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18" style={{textDecoration:"none"}}>
                        {" "}
                        Go to Wallet 
                      </a>
                    </div>
                    <p className="clr-neutral-80 animate-line-3d">
                    Stake OWNX directly from your wallet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-6">
                <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                  <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                    <img
                      src="assets/icon-1/buy_ownx.png"
                      alt="image"
                      className="img-fluid  object-fit-cover"
                      width="50px"
                    />
                  </div>
                  <div className="mt-auto">
                    <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                      <span className="d-inline-block flex-shrink-0">
                        <img
                          src="assets/img/icon-subtitle.png"
                          alt="image"
                          className="img-fluid"
                        />
                      </span>
                      <a href="#" className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18" style={{textDecoration:"none"}}>
                        {" "}
                        Buy OWNX
                      </a>
                    </div>
                    <p className="clr-neutral-80 animate-line-3d">
                    Purchase OWNX & start staking today. 

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
