import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function NewsPress() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div class="single-blog-area section-space-sm-top section-space-sm-bottom slik-ma">
        <div class="">
          <div class="swiper blog-single-slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="row align-items-center gy-4">
                  <div class="col-12">
                    <div class="blog-latest-content">
                      <h4 class="h3 mb-6 fw-extrabold text-r">
                        <span class="link clr-neutral-90 p-5 ">
                          OwnX in Press
                        </span>
                      </h4>
                      <div className="slider-container">
                        <Slider
                          ref={(slider) => {
                            sliderRef = slider;
                          }}
                          {...settings}
                        >
                          <div key={1}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div key={2}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div key={3}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div key={4}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div key={5}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div key={6}>
                            <div
                              className=" px-6 py-5 rounded-3 d-flex mx-2 gap-5 flex-column justify-content-between h-100 fadein_bottom_20"
                              style={{
                                backgroundColor: "rgb(19, 19, 19)",
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                              }}
                            >
                              <a href=""></a>
                              <img
                                src="assets/img/payment-img-1.png"
                                alt="image"
                                className="img-fluid"
                              />
                              <div className="d-flex flex-column gap-3 mt-3">
                                <h6
                                  className="mb-0 fs-18 clr-neutral-80 reveal-text"
                                  style={{}}
                                >
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Quibusdam, a.{" "}
                                </h6>
                                <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                                  <span className="d-inline-block clr-primary-2 ff-1 fs-5">
                                    {" "}
                                    By coindesk{" "}
                                  </span>
                                </div>
                                <p
                                  className="mb-0 clr-neutral-90 fs-6 reveal-text mt-5"
                                  style={{}}
                                >
                                  <a
                                    href=""
                                    className="link clr-neutral-90 :clr-primary-key"
                                  >
                                    READ ARTICLE{" "}
                                    <span>
                                      <i className="bi bi-arrow-up-right" />{" "}
                                    </span>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Slider>
                        <div className="mt-5 d-flex">
                          <div
                            className="testimonial-slider-3__nav-prev mx-2"
                            onClick={previous}
                            tabIndex={0}
                            role="button"
                            aria-label="Previous slide"
                            aria-controls="swiper-wrapper-482c46bcc482e7c6"
                          >
                            <a className="">
                              <i className="bi bi-arrow-left text-white" />
                            </a>
                          </div>
                          <div
                            className="testimonial-slider-3__nav-next mx-2"
                            onClick={next}
                            tabIndex={0}
                            role="button"
                            aria-label="Next slide"
                            aria-controls="swiper-wrapper-482c46bcc482e7c6"
                          >
                            <a>
                              <i className="bi bi-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
