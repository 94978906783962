import logo from './logo.svg';
import './App.css';
// import Home from './Components/Home-old/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './Components/Home/Index';
import Token from './Components/Token-old/Token';
import Submitdapp from './Components/SubmitDapp/Submitdapp';
import FTMToken from './Components/Token/FTMToken';
import Governance from './Components/Governance/Governance';
import Ecosystem from './Components/Ecosystem/Ecosystem';
import Gaming from './Components/Gaming/Gaming';
import Aboutus from './Components/About-Us/Aboutus';
import Roadmap from './Components/Roadmap/Roadmap';
import Accelerator from './Components/Accelerator/Accelerator';
import Wallet from './Components/Wallet/Wallet';
import Validator from './Components/Validator/Validator';
import Research from './Components/Research/Research';
import Developers from './Components/Developers/Developers';
import Staking from './Components/Staking/Staking';
import Prooalliance from './Components/Proof-of-Alliance/Prooalliance';
import Community from './Components/Community/Community';
import Facuetnew from './Components/Comman/Facuetnew';
import TermsCondition from './Components/Comman/TermsCondition';
import PrivacyPolicy from './Components/Comman/PrivacyPolicy';
import Brandkit from './Components/Brandkit/Brandkit';
import Partners from './Components/Partners/Partners';
import Blogsnews from './Components/Blogs&News/Blogsnews';
import Blogopne from './Components/Blogopen/Blogopne';
import Commingsoon from './Components/Comman/Commingsoon';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      {/* <Route path="/token" element={<Token />} /> */}
      <Route path="/submitdapp" element={<Submitdapp />} />
      <Route path="/ownx" element={<FTMToken />} />
      <Route path="/governance" element={<Governance />} />
      <Route path="/ecosystem" element={<Ecosystem />} />
      <Route path="/gaming" element={<Gaming/>} />
      <Route path="/about" element={<Aboutus/>} />
      <Route path="/roadmap" element={<Roadmap/>} />
      <Route path="/accelerator" element={<Accelerator/>} />
      <Route path="/wallet" element={<Wallet/>} />
      <Route path="/validator" element={<Validator/>} />
      <Route path="/research" element={<Research/>} />
      <Route path="/developers" element={<Developers/>} />
      <Route path="/staking" element={<Staking/>} />
      <Route path="/proof-of-alliance" element={<Prooalliance/>} />
      <Route path="/community" element={<Community/>} />
      <Route path="/facuet" element={<Facuetnew/>} />
      <Route path="/terms-condition" element={<TermsCondition/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/brand-kit" element={<Brandkit/>} />
      <Route path="/blogs-news" element={<Blogsnews/>} />
      <Route path="/blog-1" element={<Blogopne/>} />
      <Route path="/partners" element={<Partners/>} />
      <Route path="/coming-soon" element={<Commingsoon/>} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;
