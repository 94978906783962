import React from "react";

export default function Tokanpre() {
  return (
    <div>
      {" "}
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom my-10">
        <div className="container">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h4 className="h4 mb-6 fw-extrabold">
                        <h3
                      
                          className="link clr-neutral-90"
                        >
                       Tokenomics
                        </h3>
                      </h4>
                      <h6 className="clr-neutral-80 mb-0"> <span className="fs-4">Token Name : </span>OwnX Token</h6>
                      <h6 className="clr-neutral-80 mb-0"> <span className="fs-4">Ticker : </span>OwnX </h6>
                      <h6 className="clr-neutral-80 mb-0"> <span className="fs-4">Total Supply : </span>210,000,000</h6>
                     
                     
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/tokenomics.png"
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                      {/* <span className="blog-latest-label"> Latest News </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
