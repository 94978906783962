import React from "react";

export default function Validatorhero() {
  return (
    <div>
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container my-10 mt-20">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">
                        Join OwnXChain As
                        <span className="clr-grad-4"> Validator</span>
                      </h3>
                      <p className="clr-neutral-80 mb-6">
                        Join & secure our decentralized blockchain network by
                        becoming a validator.
                      </p>
                      <a
                        href="https://ownx.gitbook.io/ownxchain-documentation/guide/become-validator"
                        target="_blank"
                        className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center ms-2"
                      >
                        {" "}
                        Become Validator{" "}
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/join_validator.png"
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                      {/* <span className="blog-latest-label"> Latest News </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
