import React from 'react'
import Validatorhero from './Validatorhero'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Validatormain from './Validatormain'
import Validatorsnet from './Validatorsnet'
import Validatorrewards from './Validatorrewards'

export default function Validator() {
  return (
    <div>
        <Header/>
        <Validatorhero/>
        <Validatormain/>
        <Validatorsnet/>
        <Validatorrewards/>
        <Footer/>
    </div>
  )
}
