import React from 'react'

export default function FAQ() {
    return (
        <>
            <div className="overflow-hidden">
                {/* /Pricing Section  */}
                {/* FAQ 2 */}
                <div className="faq-section-2 section-space-sm-y">
                    <div className="faq-section-2__inner section-space-sm-top">
                        <div className="section-space-sm-y">
                            <div className="container">
                                <div className="row justify-content-lg-end">
                                    <div className="col-lg-8">
                                        <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                                            <div className="w-30 subtitle-flush-x subtitle-flush-x--secondary subtitle_line_6" />
                                            <h6 className="mb-0 fs-18 clr-secondary-key fw-extrabold animate-text-from-right"> FAQs </h6>
                                        </div>
                                        <h3 className="mb-0 clr-neutral-90 animate-line-3d"> Questions? We have <br /><span className="clr-grad-1">  Answers.  </span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <nav className="todo-nav">
                                        <ul className="list gap-5 nav todo-nav__menu">
                                            <li>
                                                <a href="#aOwnXChain" className="nav-link link todo-nav__link active" data-bs-toggle="tab" data-bs-target="#"> OwnXChain
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#modularity" className="nav-link link todo-nav__link" data-bs-toggle="tab" data-bs-target="#"> Modularity
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#Proofalliance" className="nav-link link todo-nav__link" data-bs-toggle="tab" data-bs-target="#"> Proof-of-Alliance
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-lg-8">
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="aOwnXChain">
                                            <div className="accordion custom-accordion custom-accordion--faq mb-8" id="faqAccordionOne">
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordion1" aria-expanded="true">
                                                            What is OwnXChain?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordion1" className="accordion-collapse collapse show" data-bs-parent="#faqAccordionOne">
                                                        <div className="accordion-body">
                                                            <p className="mb-0">OwnXChain is a decentralized blockchain platform that is distributed across hundreds of Nodes globally. It allows to develop permissionless, transparent, and decentralized solutions in the sector of finance, asset tokenization, decentralized storage, etc.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordion2" aria-expanded="false"> What are the novel features OwnXChain bring? </button>
                                                    </h2>
                                                    <div id="faqAccordion2" className="accordion-collapse collapse" data-bs-parent="#faqAccordionOne">
                                                        <div className="accordion-body">
                                                            <p className="mb-0">OwnXChain brings a variety of new features including modular architecture, Proof-of-Alliance (PoA) consensus, and linear scalability.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordion3" aria-expanded="false"> How different OwnXChain is from other blockchain networks?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordion3" className="accordion-collapse collapse" data-bs-parent="#faqAccordionOne">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> While other blockchain networks address general purpose functionality, OwnXChain is built to put forward modularity where specific functionality is offered rather than hosting every functionality and blocking consensus with unnecessary load.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="modularity">
                                            <div className="accordion custom-accordion custom-accordion--faq mb-8" id="faqAccordionTwo">
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionGV1" aria-expanded="true">
                                                            What is Modularity in Blockchain?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionGV1" className="accordion-collapse collapse show" data-bs-parent="#faqAccordionTwo">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> Modular blockchain are dedicated to one or two core functions of a blockchain while leaving the rest to developers to build on top of them. It may offer data availability, consensus proposition or scalability layer which could be used to launch specific dApps.  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionGV2" aria-expanded="false">What is the difference between Modular blockchain & traditional blockchain?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionGV2" className="accordion-collapse collapse" data-bs-parent="#faqAccordionTwo">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> Traditional blockchains may result in limited adaptability, restricting customization and response to system overloads. Modular blockchains boast high adaptability with specialized chains for specific tasks, offering greater design flexibility.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionGV3" aria-expanded="false"> What is monolithic & modular blockchain architecture?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionGV3" className="accordion-collapse collapse" data-bs-parent="#faqAccordionTwo">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> The core architecture of Monolithic blockchain involves every functionality a blockchain should have while modular architecture aims to propose only one or two functionality that specific application needs. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Proofalliance">
                                            <div className="accordion custom-accordion custom-accordion--faq mb-8" id="faqAccordionThree">
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionRBG1" aria-expanded="true"> What is Proof-of-Alliance (PoS) Consensus?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionRBG1" className="accordion-collapse collapse show" data-bs-parent="#faqAccordionThree">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> Proof-of-Alliance (PoS) consensus is a mechanism that OwnXChain employs to achieve efficiency and network security. It leverages functionality of Delegated Proof-of-Stake (DPoS) and combines it with a novel staking contract that encourages competition between Node operators.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionRBG2" aria-expanded="false">
                                                            How Different Proof-of-Alliance is from Proof-of-stake?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionRBG2" className="accordion-collapse collapse" data-bs-parent="#faqAccordionThree">
                                                        <div className="accordion-body">
                                                            <p className="mb-0"> Proof-of-stake is a simple consensus mechanism where validators stake specific amounts of assets to operate nodes. In Proof-of-Alliance, this basic functionality is layer with delegation method; other users can delegate their assets to Validators and increase their staking power. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button fs-20 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccordionRBG3" aria-expanded="false"> What is the scope of the Proof-of-Alliance (PoA) Consensus?
                                                        </button>
                                                    </h2>
                                                    <div id="faqAccordionRBG3" className="accordion-collapse collapse" data-bs-parent="#faqAccordionThree">
                                                        <div className="accordion-body">
                                                            <p className="mb-0">Proof-of-Alliance (PoA) proposes a novel mechanism that fully utilizes the power of decentralization. Under this consensus, every token holder can amplify their vote and choose to back the validators.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="faq.html" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 px-4 py-3 rounded fw-bold text-center z-1 mb-1">
                                                <span className="d-inline-block">Show More Faq</span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/img-1/faqs_bg.png" alt="image" className="img-fluid faq-section-2__img" />
                </div>
                {/* /FAQ 2 */}
            </div>

        </>
    )
}
