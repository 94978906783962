import React from 'react'

export default function Gaminghero() {
  return (
    <div>
       <div className="single-blog-area section-space-sm-bottom section-space-sm-top">
  <div className="container my-10 ">
    <div className="swiper blog-single-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="row align-items-center gy-4">
            <div className="col-lg-6">
              <div className="blog-latest-content">
                <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">Play Blockchain 
<br /> <span className='clr-grad-4'> Games & Win Rewards</span></h3>
                <p className="clr-neutral-80 mb-6">OwnXChain has a decentralized gaming platform where users can play different blockchain-based games & win exclusive rewards in crypto. </p>
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blog-latest-thumb">
                <div className="d-block">
                  <img src="assets/img-1/play_blockchain.png" alt="image" className="img-fluid w-100 object-fit-cover" />
                </div>
                {/* <span className="blog-latest-label"> Latest News </span> */}
              </div>
            </div>
          </div>
        </div>
       
      
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
