import React from "react";

export default function Facuetnew() {
  return (
    <div>
      <div className="container section-space-sm-top">
      <div className="d-flex justify-content-center">
      <a
        
          className="logo link d-inline-flex align-items-center flex-shrink-0"
        >
          <img
            src="assets/img/ownx_logo_light.png"
            alt="logo"
            className="img-fluid object-fit-contain"
          />
        </a>
      </div>

        <div className="row g-4 my-2 justify-content-center section-space-sm-top">
          <div
            className="col-12 col-md-6 col-lg-6 fadein_bottom_26"
            style={{
              translate: "none",
              rotate: "none",
              scale: "none",
              transform: "translate(0px, 0px)",
              opacity: 1,
            }}
          >
            <div className="p-4 p-xl-8 rounded-4 bg-neutral-6 post-3-bg h-100">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <h5 className=" clr-neutral-90 reveal-text" style={{}}>
                    Request Airdrop
                  </h5>
                  <p
                    className="mb-0 clr-neutral-80 animate-text-from-right"
                    style={{}}
                  >
                    {" "}
                    Maximum of 2 requests per hour
                  </p>
                </div>
                <div>
                  <div className="h-select">
                    {/* <i className="bi bi-currency-dollar" /> */}
                    <select className="px-3 bg-dark text-white">
                      <option>Testnet</option>
                      <option>Devnet </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="newsletter-1 d-inline-block w-100  position-relative z-1 overflow-hidden rounded-pill">
                <div className="d-flex p-2 bg-neutral-10 rounded ">
                  <input
                    type="text"
                    className="form-control newsletter-1__input bg-transparent clr-neutral-60"
                    placeholder="Wallet Adress"
                  />
               
                </div>
                
              </div>
              <div className="newsletter-1 d-inline-block w-100  position-relative z-1 overflow-hidden rounded-pill">
                <div className="d-flex p-2 bg-neutral-10 rounded ">
                  <input
                    type="number"
                    className="form-control newsletter-1__input bg-transparent clr-neutral-60"
                    placeholder="Amount"
                  />
               
                </div>
                
              </div>
              <a href="#" class="gradient-btn-1 position-relative gap-2 mt-3 border-none z-1 link py-3 px-6 w-100 rounded-1 d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"> Claim </a>

              
            </div>
          </div>
        </div>
        <div className="row g-4 my-2 justify-content-center">
          <div
            className="col-12 col-md-6 col-lg-6 fadein_bottom_26"
            style={{
              translate: "none",
              rotate: "none",
              scale: "none",
              transform: "translate(0px, 0px)",
              opacity: 1,
            }}
          >
            <div className="p-4 p-xl-8 rounded-4 bg-neutral-6 post-3-bg h-100">
              <h5 className=" clr-neutral-90 reveal-text" style={{}}>
                Need More OWNX?
              </h5>
              <p
                className="mb-0 clr-neutral-80 animate-text-from-right"
                style={{}}
              >
                Join our developer community & they will help you out.
              </p>

              
              <a href="/community" class="gradient-btn-1 position-relative gap-2 mt-3 border-none z-1 link py-3 px-6 w-100 rounded-1 d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"> Join </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
