import React from 'react'
import Aboutushero from './Aboutushero'
import Header from '../Comman/Header'
import Aboutour from './Aboutour'
import Aboutbrand from './Aboutbrand'
import Aboutget from './Aboutget'
import Footer from '../Comman/Footer'
import Aboutsta from './Aboutsta'

export default function Aboutus() {
  return (
    <div>
        <Header/>
        <Aboutushero/>
        <Aboutsta/>
        <Aboutour/>
        {/* <Aboutbrand/> */}
        {/* <Aboutget/> */}
        <Footer/>
    </div>
  )
}
