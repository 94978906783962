import React from 'react'

export default function Validatorsnet() {
  return (
    <div>
           <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
  <div className="container my-10 mt-20">
    <div className="swiper blog-single-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="row align-items-center gy-4">
          
       
            <div className="col-lg-6">
              <div className="blog-latest-thumb">
                <div className="d-block">
                  <img src="assets/img-1/validators-are-the-backbone-of-blockchain-networks.png" alt="image" className="img-fluid w-100 object-fit-cover" />
                </div>
                {/* <span className="blog-latest-label"> Latest News </span> */}
              </div>
            </div>
            <div className="col-lg-6 ps-md-10 ps-sm-1">
              <div className="blog-latest-content">
            
                <h3 className="h3 mb-6 fw-extrabold clr-neutral-90 ">Validators are the backbone of 
<span className='clr-grad-4'> blockchain networks</span></h3>
                <p className="clr-neutral-80 mb-6">By staking OWNX & running nodes, Validators provide power to the blockchain network. They process transactions, broadcast blocks & help achieve consensus in the decentralized environment. </p>

              </div>
            </div>
          </div>
        </div>
       
      
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
