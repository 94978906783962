import React from "react";

export default function Prooalliancehero() {
  return (
    <div>
    <section className="breadcrumb-section">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xxl-8 col-xl-8 text-center">
        {/* <div className="d-inline-flex align-items-center py-2 px-4 bg-info-10 bg-opacity-3 rounded-1">
          <a href="index-8.html" className="link fs-12 clr-white">Home</a>
          <span className="fs-12 clr-white">-</span>
          <span className="fs-12 clr-white">Use Cases</span>
        </div> */}
        <h2 className="h3 fw-bold clr-neutral-90 mt-4 max-text-32 ms-auto me-auto">Proof-of-Alliance (PoA) 
</h2>
<p className="clr-neutral-80">The novel consensus mechanism for modern blockchain infrastructure
</p>

      </div>
    </div>
  </div>
  <div className="use-case-breadcrumb-bottom">
    <div className="use-case-breadcrumb-bottom-circle">
      <div className="radar-logo-wrapper">
        <div className="radar">
          <div className="animated-text-wrapper">
           
          </div>
        </div>
        <div className="logo-wrapper d-flex justify-content-center align-items-center">
          <img src="../logo192.png" alt="image" className="img-fluid hero-7-logo" />
        </div>
      </div>
    </div>
  </div>
  <img src="assets/img/breadcrumb-shape-line-left.png" alt="image" className="img-fluid breadcrumb-shape breadcrumb-shape-line-left" />
  <img src="assets/img/breadcrumb-shape-line-right.png" alt="image" className="img-fluid breadcrumb-shape breadcrumb-shape-line-right" />
</section>

    </div>
  );
}
