import React from 'react'
import Roadmaphero from './Roadmaphero'
import Roadmapmain from './Roadmapmain'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Roadmap() {
  return (
    <div>
        <Header/>
        <Roadmaphero/>
        <Roadmapmain/>
        <Footer/>
    </div>
  )
}
