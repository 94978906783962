import React from "react";

export default function Webwalletnew() {
  return (
    <div>
      <section className="how-work-section section-space-sm-top section-space-sm-bottom position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="position-relative text-center">
                <h4 className="clr-white fw-extrabold mb-6 animate-line-3d">
                  OwnXChain provides three ways for creating a new wallet
                  including
                  <span className="clr-grad-4">
                    {" "}
                    web wallet, mobile wallet or a hard wallet.{" "}
                  </span>
                </h4>
                {/* <p className="max-text-60 mx-auto animate-text-from-bottom">Media.io Online Vocal Remover can separate all mingled-up and blended vocals and instrumental tracks from a song. This allows you to use the extracted.</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="section-space-sm-top">
          <div className="container">
            <div className="how-work-wrapper">
              <div className="how-work-single-row align-items-center">
                <div className="thumb fadeIn_bottom">
                  <img
                    src="assets/img-1/web_wallet.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4 className="animate-line-3d clr-white">Web Wallet </h4>
                  <p className="my-6 animate-text-from-right clr-neutral-80 animate-line-3d">
                    OwnX Web wallet is accessible from any web browser. Users
                    can create a wallet to send, receive or store OWNX token and
                    other other cryptocurrency assets. The simple infrastructure
                    of OwnX Web wallet makes it easy to use even for beginners.
                  </p>
                  <p className="my-6 animate-text-from-right clr-neutral-80 animate-line-3d">
                    Using OwnX web wallet, users could easily access OWNX
                    staking, swapping & other variety of in-built
                    functionalities.
                  </p>
                  <a
                    href="/coming-soon"
                    class="link d-inline-block py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1"
                  >
                    {" "}
                    Go To Web Wallet
                  </a>
                </div>
              </div>
              {/* how-work-single-row end */}
              <div className="how-work-single-row align-items-center">
                <div className="thumb fadeIn_bottom">
                  <img
                    src="assets/img-1/mobile-wallet.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4 className="animate-line-3d clr-white">Mobile Wallet</h4>
                  <p className="my-6 animate-text-from-right clr-neutral-80 animate-line-3d">
                    OwnX Mobile wallet also facilitates the same functionalities
                    as web wallet but in a convenient and portable way. The
                    mobile version offers a wide range of other features such as
                    in-app browser, scan & pay, instant crypto-to-fiat
                    conversion, etc.{" "}
                  </p>
                  <p className="my-6 animate-text-from-right clr-neutral-80 animate-line-3d">
                    Download Mobile Wallet{" "}
                  </p>
                  <a href="/coming-soon">
                    <img
                      src="assets/icon-1/playstore.svg"
                      alt="image"
                      className="w-30"
                    />
                  </a>
                  <a href="/coming-soon" className=" mx-2">
                    <img
                      src="assets/icon-1/appstore.svg"
                      alt="image"
                    className="w-30"
                    />
                  </a>
                </div>
              </div>
              {/* how-work-single-row end */}
              <div className="how-work-single-row align-items-center">
                <div className="thumb fadeIn_bottom">
                  <img
                    src="assets/img-1/hard_wallet.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4 className="animate-line-3d clr-white">
                    Hard Wallet{" "}
                    <span className="ms-3">
                      {" "}
                      <i className="clr-grad-4">(Coming soon)</i>
                    </span>{" "}
                  </h4>
                  <p className="clr-neutral-80 animate-line-3d ">
                    OwnX Hard wallet is a physical crypto wallet that ensures
                    utmost security for the crypto assets stored inside. OwnX
                    aims to launch Hard Crypto Wallets in partnership with
                    leading crypto cold wallet providers.{" "}
                  </p>
                </div>
              </div>
              {/* how-work-single-row end */}
            </div>
          </div>
        </div>
        <img
          src="assets/img/home-5-left-shape.png"
          alt="image"
          className="img-fluid how-work-shape how-work-shape-left"
        />
        <img
          src="assets/img/home-5-right-shape.png"
          alt="image"
          className="img-fluid how-work-shape how-work-shape-right"
        />
      </section>
    </div>
  );
}
