import React from "react";

export default function Researchmain() {
  return (
    <div>
      <div className="use-case-section section-space-sm-bottom  section-space-sm-top">
        <div className="container">
          <div className="mt-13">
            <div className="row gy-4">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <a  style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    {/* <div className="clr-neutral-50 fs-32">
              <img src="assets/img/use-case-icon-1.png" alt="image" />
            </div> */}
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                      Whitepaper
                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                      The whitepaper consists of an infrastructure blueprint
                      which proposes fundamental ideas behind the development of
                      OwnXChain. It can be analyzed to understand the scope and
                      driving force of the OwnXChain as well as the underlying
                      ecosystem.
                    </p>
                    <a
                      href="https://ownx.gitbook.io/ownxchain-whitepaper"
                      target="_blank"
                      className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 mt-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                      Read Whitepaper 
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <a  style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    {/* <div className="clr-neutral-50 fs-32">
              <img src="assets/img/use-case-icon-1.png" alt="image" />
            </div> */}
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                    Litepaper
                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                    OwnXChain Litepaper includes detailed analysis of technical aspects that it leverages. It provides review and understanding of core development methods, technical capabilities, and expected outputs from the design of OwnXChain’s blockchain network. 

                    </p>
                    <a
                      href="https://drive.google.com/file/d/1lUq3r852ZVIiJ0OadsgNiN6urX5Dgwin/view"
                      target="_blank"
                      className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 mt-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                       Read Litepaper 
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <a  style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    {/* <div className="clr-neutral-50 fs-32">
              <img src="assets/img/use-case-icon-1.png" alt="image" />
            </div> */}
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                    Documentation

                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                     
Read the documentation to get started with OwnXChain which aligns simple guides together and makes a complete brief on how it all works. 

                    </p>
                    <a
                      href="https://ownx.gitbook.io/ownxchain-documentation"
                      target="_blank"
                      className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 mt-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                      Documentation 
                    </a>
                  </div>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <a style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    {/* <div className="clr-neutral-50 fs-32">
              <img src="assets/img/use-case-icon-1.png" alt="image" />
            </div> */}
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                    Blogs & Reports

                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                    Find our latest blogs & quarterly reports where developments, operational insights, upcoming launches, and other organizational information is shared. 
                    </p>
                    <a
                      href="/blogs-news"
                      target="_blank"
                      className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 mt-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                      Blogs & Reports
                    </a>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
