import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Acceleratorhero from './Acceleratorhero'
import Acceleratormain from './Acceleratormain'

export default function Accelerator() {
  return (
    <div>
        <Header/>
<Acceleratorhero/>
<Acceleratormain/>
        <Footer/>
    </div>
  )
}
