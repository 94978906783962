import React from 'react'

export default function Product() {
    return (
        <>
            <div className="ticker-section section-space-sm-y overflow-hidden mx-auto">
                <div className="ticker-section__content section-space-xsm-top">
                    <div className="container-fluid p-0">
                        <div className="row g-0">
                            <div className="col-12">
                                <div className="scroller-x mb-4" data-direction="left" data-speed="slow">
                                    <ul className="list list-row align-items-center gap-4 scroller-x__list">
                                        <li>
                                            <h2 className="mb-0 clr-neutral-90"> Scaling Blockchain 


</h2>
                                        </li>
                                        <li>
                                            <img src="assets/img/icon-star-outline.png" alt="image" className="img-fluid" />
                                        </li>
                                        <li>
                                            <h2 className="mb-0 text-outline"> Ecosysteml </h2>
                                        </li>
                                        <li>
                                            <img src="assets/icon-1/icon-star-gradient.png" alt="image" className="img-fluid" />
                                        </li>
                                        <li>
                                            <h2 className="mb-0 clr-neutral-90">  With Modularity </h2>
                                        </li>
                                        <li>
                                            <img src="assets/icon-1/icon-star-gradient.png" alt="image" className="img-fluid" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
