import React from 'react'

export default function WhatWeDo() {
    return (
        <>
            <div className="what-todo-section section-space-sm-y">
                <div className="section-space-sm-bottom">
                    <div className="container">
                        <div className="row g-4 justify-content-between">
                            <div className="col-md-5">
                                <div className="d-flex align-items-center gap-4 flex-wrap">
                                    <div className="w-30 subtitle-flush-x subtitle_line_1" />
                                    <h6 className="mb-0 fs-18 clr-neutral-90 fw-extrabold animate-text-from-right"> What We Do </h6>
                                </div>
                            </div>
                            <div className="col-md-7 col-xl-5">
                                <h5 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d"> <span className="clr-grad-1">OwnXChain</span> inherits entailed blockchain functionalities </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-5 order-xl-2">
                            <nav className="todo-nav" id="toDoScrollspy">
                                <ul className="list gap-5 todo-nav__menu">
                                    <li>
                                        <a href="#imageGenerator" className="nav-link link todo-nav__link"> High-speed Transactions </a>
                                    </li>
                                    <li>
                                        <a href="#imageCustomization" className="nav-link link todo-nav__link"> Modular Architecture </a>
                                    </li>
                                    <li>
                                        <a href="#generateVideos" className="nav-link link todo-nav__link"> Proof-of-Alliance (PoA) Consensus </a>
                                    </li>
                                    <li>
                                        <a href="#removedBg" className="nav-link link todo-nav__link"> Cost Minimalized </a>
                                    </li>
                                    <li>
                                        <a href="#trainCustom" className="nav-link link todo-nav__link"> Decentralized </a>
                                    </li>
                                    <li>
                                        <a href="#imgCreation" className="nav-link link todo-nav__link"> Proven Scalability </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-7">
                            <div  data-bs-target="#toDoScrollspy" data-bs-smooth-scroll="true">
                                <div className="section-space-bottom" id="imageGenerator">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d"> High-speed Transactions</h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right">OwnXChain facilitates high-speed transaction processing with an average TPS of 5000</p>
                                        <div className="fadein_bottom_7">
                                            <a href="/coming-soon" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Testnet </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                            <a href="/coming-soon" className="gradient-btn-1 mx-3 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Mainnet </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        <img src="assets/img-1/high-speed-transactions.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/high-speed-transactions01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                                <div className="section-space-y" id="imageCustomization">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d"> Modular Architecture
                                        </h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right"> Fundamental development of OwnXChain is carried out with modularity at core </p>
                                        <div className="fadein_bottom_3">
                                            <a href="https://ownx.gitbook.io/ownxchain-whitepaper/features-and-functionality" target='_blank' className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Read More  </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        <img src="assets/img-1/modular-architecture.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/modular-architecture01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                                <div className="section-space-y" id="generateVideos">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d"> Proof-of-Alliance (PoA) Consensus </h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right"> Proposing the novel consensus mechanism Proof-of-Alliance (PoA) to upscale network security
                                        </p>
                                        <div className="fadein_bottom_4">
                                            <a href="/proof-of-alliance" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Read More </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        {/* <video autoPlay loop className="ratio ratio-16x9">
                                            <source src="assets/img/todo-video.mp4" type="video/mp4" />
                                        </video> */}
                                           <img src="assets/img-1/proof-of-alliance.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/proof-of-alliance01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                                <div className="section-space-y" id="removedBg">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d">Cost Minimalized  </h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right">Transaction cost on OwnX is cheaper than all other leading blockchain networks.
                                        </p>
                                        <div className="fadein_bottom_5">
                                            <a href="/wallet" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Go To Wallet </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        <img src="assets/img-1/cost-minimalized.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/cost-minimalized01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                                <div className="section-space-y" id="trainCustom">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d"> Decentralized
                                        </h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right">OwnXChain is a decentralized blockchain network & open for all to contribute.</p>
                                        <div className="fadein_bottom_6">
                                            <a href="/community" className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Join </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        <img src="assets/img-1/decentralized.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/decentralized01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                                <div className="section-space-y" id="imgCreation">
                                    <div className="todo-box position-relative z-1 bg-black py-10 pe-10">
                                        <h4 className="clr-neutral-90 animate-line-3d"> Proven Scalability </h4>
                                        <p className="mb-6 clr-neutral-80 animate-text-from-right"> Wider the OwnXChain grows, the more scalable it gets alongside increased throughput.
                                        </p>
                                        <div className="fadein_bottom_7">
                                            <a href="https://ownx.gitbook.io/ownxchain-whitepaper/role-of-ownxchain-in-blockchain-landscape" target='_blank' className="gradient-btn-1 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1">
                                                <span className="d-inline-block"> Test Scalability </span>
                                                <span className="d-inline-block">
                                                    <i className="bi bi-arrow-up-right" />
                                                </span>
                                            </a>
                                            
                                        </div>
                                    </div>
                                    <div className="todo-img">
                                        <img src="assets/img-1/proven-scalability.png" alt="image" className="img-fluid rounded-1 parallax-image" />
                                        <img src="assets/img/todo-img-1.png" alt="image" className="img-fluid todo-img__1" />
                                        <img src="assets/img-1/proven-scalability01.png" alt="image" className="img-fluid todo-img__2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
