import React from 'react'

export default function CommunitySocial() {
  return (
    <div>
        <section className="section-space-sm-bottom section-space-sm-top">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xxl-6 col-xl-8 text-center">
        <h3 className="clr-neutral-90">Join us 
</h3>
      </div>
    </div>
    <div className="mt-10">
      <div className="row gy-4 justify-content-center">
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://x.com/ownxchain" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
            <div className="clr-neutral-50 ">
            <i class="bi bi-twitter fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Twitter</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://t.me/ownxchain_en" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-telegram fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Telegram</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://www.facebook.com/profile.php?id=61554826180803" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-facebook fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">facebook</h4>
           
          </div>
          </a>
        </div>
    
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://www.reddit.com/r/ownxchain" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-reddit fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Reddit</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://medium.com/@ownxsocial" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-medium fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Medium</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://www.instagram.com/ownxchain/" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-instagram fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Instagram</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <a href="https://www.youtube.com/channel/UCUS9Ow9--NV-iael0h5Z9Gg" style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100 icon-hover">
          <div className="clr-neutral-50 ">
            <i class="bi bi-youtube fs-1 icon"></i>
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-2 mb-4">Youtube</h4>
           
          </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
            <a style={{textDecoration:"none"}}>
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 pt-xxl-12 text-center text-xl-start h-100  icon-hover">
          <div className="clr-neutral-50 icon-hover-d">
            <img src="assets/icon-1/forum.svg" className='icon img-fluid w-12 h-12 d-none-1' alt="" />
            
           <img src="assets/icon-1/forum_lcon.svg" className='icon img-fluid w-12 h-12 d-none d-block-1' alt="" />  
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-4 mb-4">Forum</h4>
           
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
