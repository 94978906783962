import React from "react";

export default function Governanceste() {
  return (
    <div>
      <div className=" section-space-bottom section-space-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-sm-6 col-lg-4 text-center">
              <div
                className=" py-10 border rounded-3 fadein_bottom_30"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <div className="d-flex flex-wrap align-items-center gap-4 justify-content-center">
                  {/* <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
              <i className="bi bi-apple" />
            </span> */}
                  <h5 class="clr-neutral-90 fw-extrabold mb-0 ">+5000 </h5>
                </div>
                <h4 className="mt-2 h6 fw-extrabold text-white">
                  Governing Members{" "}
                </h4>
                <div
                  className="fadein_bottom_6 mt-4"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 0px)",
                    opacity: 1,
                  }}
                >
                  <a
                    href="/community"
                    className="gradient-btn-1 py-2 px-5 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1"
                  >
                    <span className="d-inline-block"> Join </span>
                    <span className="d-inline-block">
                      <i className="bi bi-arrow-up-right" />
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4 text-center">
              <div
                className=" py-10 border rounded-3 fadein_bottom_30"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <div className="d-flex flex-wrap align-items-center gap-4 justify-content-center">
                  {/* <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
              <i className="bi bi-apple" />
            </span> */}
                  <h5 class="clr-neutral-90 fw-extrabold mb-0 ">+10 </h5>
                </div>
                <h4 className="mt-2  h6 fw-extrabold text-white">
                  Live Proposals{" "}
                </h4>
                <div
                  className="fadein_bottom_6 mt-4"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 0px)",
                    opacity: 1,
                  }}
                >
                  <a
                    href="/coming-soon"
                    className="gradient-btn-1 py-2 px-5 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1"
                  >
                    <span className="d-inline-block"> Vote </span>
                    <span className="d-inline-block">
                      <i className="bi bi-arrow-up-right" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 text-center">
              <div
                className=" py-10 border rounded-3 fadein_bottom_30"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <div className="d-flex flex-wrap align-items-center gap-4 justify-content-center">
                  {/* <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
              <i className="bi bi-apple" />
            </span> */}
                  <h5 class="clr-neutral-90 fw-extrabold mb-0 ">+50</h5>
                </div>
                <h4 className="mt-2  h6 fw-extrabold text-white">
                  In Discussion{" "}
                </h4>
                <div
                  className="fadein_bottom_6 mt-4"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 0px)",
                    opacity: 1,
                  }}
                >
                  <a
                    href="/coming-soon"
                    className="gradient-btn-1 py-2 position-relative link d-inline-flex justify-content-center align-items-center gap-2 p-4 rounded fw-bold text-center z-1"
                  >
                    <span className="d-inline-block"> Explore </span>
                    <span className="d-inline-block">
                      <i className="bi bi-arrow-up-right" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
