import React from 'react'

export default function About() {
    return (
        <>
            <div className="position-relative section-space-sm-top section-space-bottom">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <div className="text-xl-end pe-xl-6">
                                <div className="d-inline-block position-relative">
                                    <img src="assets/img-1/ownxchain-addresses-your-decentralized-needs.png" alt="image" className="w-100 parallax-image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center flex-wrap gap-4 mb-4">
                                <div className="w-30 border-bottom border-2x border-neutral-100 welcome-dash" />
                                <h6 className="mb-0 clr-neutral-90 animate-text-from-right"> Welcome </h6>
                            </div>
                            <h2 className="d4 clr-neutral-90 mb-6 fw-extrabold animate-line-3d"> OwnxChain Addresses 
                                <span className="clr-grad-1"> Decentralized Needs</span>
                            </h2>
                            <div className="pt-4 ps-4 pt-md-8 ps-md-16 border-start border-neutral-90 border-opacity-1">
                                <p className="mb-6 clr-neutral-80 animate-text-from-right"> 
                                    The foundation of OwnXChain ends all your needs for adapting to true decentralization. It offers whole block of features & functionality required at the core.
                                </p>
                                <ul className="list gap-6">
                                    <li className="welcome-list-1">
                                        <div className="d-flex gap-3">
                                            <span className="flex-shrink-0 d-grid place-content-center w-6 h-6 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-key">
                                                <i className="bi bi-check2 text-white" />
                                            </span>
                                            <span className="d-block clr-neutral-80"> Scalability  </span>
                                        </div>
                                    </li>
                                    <li className="welcome-list-1">
                                        <div className="d-flex gap-3">
                                            <span className="flex-shrink-0 d-grid place-content-center w-6 h-6 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-key">
                                                <i className="bi bi-check2 text-white" />
                                            </span>
                                            <span className="d-block clr-neutral-80"> Privacy </span>
                                        </div>
                                    </li>
                                    <li className="welcome-list-1">
                                        <div className="d-flex gap-3">
                                            <span className="flex-shrink-0 d-grid place-content-center w-6 h-6 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-key">
                                                <i className="bi bi-check2 text-white" />
                                            </span>
                                            <span className="d-block clr-neutral-80">  Interoperability </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <img src="assets/img/welcome-section-shape.webp" alt="image" className="img-fluid position-absolute pointer-none start-0 top-0 z-n1" />
            </div>

        </>
    )
}
