import React from 'react'

export default function Bcolor() {
  return (
    <div>
               <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper mb-10">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-12">
                   
                    <div className="blog-latest-content">

                      <h4 className="h3 fw-extrabold text-r">
                        <span className="link clr-neutral-90 px-5 ">Color </span>
                      </h4>
                      <div className="blog-latest-cntent">
                      
                    </div>
                    </div>
  

                  </div>
                </div>
                <div className="p-4 p-md-6 rounded-4  border border-neutral-variant-10 text-center bcolor" style={{width:"600px", height:"300px"}}>
</div>

              </div>
            </div>
            <div className="swiper-wrapper ">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-12 d-flex">
                  <div className="blog-latest-content mx-2">
                  <h4 className="h6 fw-extrabold text-r">
                      <span className="link clr-neutral-90 px-5 ">Ownx Orange  </span>
                      </h4>
                      <p className="fs-5 text-r">
                      <span className="link clr-neutral-80 px-5 ">#F9713A</span>
                      </p>
                      <div className="blog-latest-cntent">
                    </div>
                    <div className="p-4 p-md-6 rounded-4  border border-neutral-variant-10 text-center " style={{width:"290px", height:"200px",backgroundColor:"#F9713A"}}>
                  </div>
                    </div>
                    <div className="blog-latest-content mx-2">
                      <h4 className="h6 fw-extrabold text-r">
                      <span className="link clr-neutral-90 px-5 ">Ownx Yellow  </span>
                      </h4>
                      <p className="fs-5 text-r">
                      <span className="link clr-neutral-80 px-5 ">#FCD54F</span>
                      </p>
                      <div className="blog-latest-cntent">
                    </div>
                    <div className="p-4 p-md-6 rounded-4  border border-neutral-variant-10 text-center " style={{width:"290px", height:"200px",backgroundColor:"#FCD54F"}}>
                  </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
