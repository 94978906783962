import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="footer-5 section-space-sm-top pb-9">
            <div className="footer-4">
            <ul className="list list-row gap-4 flex-wrap justify-content-center">
  <li>
    <a href="https://x.com/ownxchain" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
      <i className="bi bi-twitter" />
    </a>
  </li>
  <li>
    <a href=" https://www.facebook.com/profile.php?id=61554826180803" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
    <i class="bi bi-facebook"/>
    </a>
  </li>
  <li>
    <a href="https://www.instagram.com/ownxchain/" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
    <i class="bi bi-instagram"></i>
    </a>
  </li>
  <li>
    <a href=" https://t.me/ownxchain_en" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
    <i class="bi bi-telegram"></i>
    </a>
  </li>

  <li>
    <a href="https://medium.com/@ownxsocial" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
    <i class="bi bi-medium"></i>
    </a>
  </li>
  <li>
    <a href="https://www.reddit.com/user/ownxchain/" target='_blank' className="link d-grid place-content-center w-12 h-12 bg-neutral-10 :bg-grad-4 rounded-circle clr-white fs-20">
    <i className="bi bi-reddit"/>
    </a>
  </li>
</ul>

  <div className="section-space-sm-y">
    <div className="container">
      <div className="row g-4">
        <div className="col-md-4 col-xl-3">
          <div className="d-flex flex-column justify-content-between h-100">
            <a href="/" className="logo link d-inline-flex align-items-center flex-shrink-0 mb-8 fadeIn_bottom">
              <img src="assets/img/ownx_logo_light.png" alt="logo" className="img-fluid object-fit-contain" />
            </a>
            {/* <ul className="list list-row gap-4 flex-wrap">
              <li className="fadein_bottom_28">
                <a href="https://twitter.com/" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
                  <i className="bi bi-twitter" />
                </a>
              </li>
              <li className="fadein_bottom_28">
                <a href="https://www.behance.net/" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
                  <i className="bi bi-behance" />
                </a>
              </li>
              <li className="fadein_bottom_28">
                <a href="https://dribbble.com/" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
                  <i className="bi bi-dribbble" />
                </a>
              </li>
              <li className="fadein_bottom_28">
                <a href="https://www.facebook.com/" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
                  <i className="bi bi-facebook" />
                </a>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="col-xl-9">
          <div className="row g-4 d-flex text-xl-start text-lg-start text-md-start text-center">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <h6 className="mb-6 clr-neutral-80 fs-18 fadeIn_bottom"> General </h6>
              <ul className="list gap-4">
                <li>
                  <a href="/about" className="link d-inline-block clr-neutral-80 :clr-primary-key"> About us </a>
                </li>
                <li>
                  <a href="/brand-kit" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Brand Kit </a>
                </li>
                <li>
                  <a href="mailto:info@ownxchain.com" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Contact Us  </a>
                </li>
                <li>
                  <a href="https://forms.gle/NVUrqjoA6mkVhyMH8" target='_blank' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Submit dApps </a>
                </li>
                <li>
                  <a href="/blogs-news" className="link d-inline-block clr-neutral-80 :clr-primary-key"> News & Blog </a>
                </li>
                <li>
                  <a href="/partners" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Partners </a>
                </li>
                <li>
                  <a href="/roadmap" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Roadmap </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <h6 className="mb-6 clr-neutral-80 fs-18 fadeIn_bottom"> Network </h6>
              <ul className="list gap-4">
                <li>
                  <a href="/coming-soon" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Testnet </a>
                </li>
                <li>
                  <a href="/coming-soon" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Mainnet  </a>
                </li>
                <li>
                  <a href="/staking" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Staking </a>
                </li>  
                  <li>
                  <a href="/validator" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Validators </a>
                </li>
                <li>
                  <a href="/facuet" target='_blank' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Faucet </a>
                </li>
                <li>
                  <a href="https://ownx.gitbook.io/ownxchain-documentation/resources/audits" target='_blank' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Security Audits </a>
                </li>
                <li>
                  <a href="/gaming" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Gaming </a>
                </li>
                <li>
                  <a href="/wallet" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Wallet </a>
                </li>
                <li>
                  <a href="/ecosystem" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Ecosystem  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <h6 className="mb-6 clr-neutral-80 fs-18 fadeIn_bottom"> Learn </h6>
              <ul className="list gap-4">
                <li>
                  <a href="/proof-of-alliance" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Proof-of-Alliance  </a>
                </li>
                <li>
                  <a href="/ownx" className="link d-inline-block clr-neutral-80 :clr-primary-key"> $OWNX </a>
                </li>
                <li>
                  <a href="https://ownx.gitbook.io/ownxchain-documentation/" target='_balck' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Documentation  </a>
                </li>
                <li>
                  <a href="https://ownx.gitbook.io/ownxchain-whitepaper" target='_blank' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Whitepaper  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1lUq3r852ZVIiJ0OadsgNiN6urX5Dgwin/view?usp=sharing" target='_balck' className="link d-inline-block clr-neutral-80 :clr-primary-key"> Litepaper   </a>
                </li>
                <li>
                  <a href="/research" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Research  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <h6 className="mb-6 clr-neutral-80 fs-18 fadeIn_bottom"> Join  </h6>
              <ul className="list gap-4">
                <li>
                  <a href="/community" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Community </a>
                </li>
                <li>
                  <a href="/developers" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Developers </a>
                </li>
                <li>
                  <a href="/governance " className="link d-inline-block clr-neutral-80 :clr-primary-key">Governance  </a>
                </li>
              
                {/* <li>
                  <a href="https://ownx.gitbook.io/ownxchain-whitepaper" className="link d-inline-block clr-neutral-80 :clr-primary-key"> whitepaper  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="section-space-xsm-y">
    <div className="container">
      <div className="row g-4">
        <div className="col-md-5 col-lg-6">
          <ul className="list list-row flex-wrap gap-4">
            <li>
              <a href="/privacy-policy" className="link d-inline-block fs-14 clr-neutral-80 :clr-primary-key fw-medium"> Privacy Policy </a>
            </li>
            <li>
              <a href="/terms-condition" className="link d-inline-block fs-14 clr-neutral-80 :clr-primary-key fw-medium"> Terms of Conditions </a>
            </li>
          </ul>
        </div>
        <div className="col-md-7 col-lg-6">
          <p className="mb-0 fs-14 clr-neutral-80 fw-medium text-md-end"> Copyright ©2023 All Rights Reserved by <a href="#" className="link d-inline-block clr-primary-key">Ownx</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

            </footer>

        </>
    )
}
