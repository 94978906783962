import React from 'react'
import Header from '../Comman/Header'
import Prooalliancehero from './Prooalliancehero'
import Networkefficiency from './Networkefficiency'
import Footer from '../Comman/Footer'
import Prooalliancemain from './Prooalliancemain'
import ProoallianceDesigned from './ProoallianceDesigned'

export default function Prooalliance() {
  return (
    <div>
        <Header/>
        <Prooalliancehero/>
        <Networkefficiency/>
        <Prooalliancemain/>
        <ProoallianceDesigned/>
        <Footer/>
    </div>
  )
}
