import React from 'react'

export default function Aboutushero() {
  return (
    <div>
       <div className="single-blog-area section-space-top section-space-sm-bottom">
        <div className="container">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h4 className="h4 mb-6 fw-extrabold text-r">
                        <span className="link clr-neutral-90  ">
                        About Us
                        </span>
                        {/* <span className="clr-grad-4">  methods, functions, & libraries </span> */}
                      </h4>
                      <p className="clr-neutral-80 mb-6">
                      OwnXChain is a decentralized blockchain platform that operates on the distributed ledger technology (DLT). It is distributed across hundreds of machines globally and designed to be permissionless, performant, and secure, establishing a robust and decentralized data layer for the emerging web.

                      </p>
                     
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/about_us_hero.png"
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
