import React from "react";

export default function StartUsing() {
  return (
    <>
      <div className="section-space-sm-top">
        <div className="section-space-sm-bottom">
          <div className="container">
            <div className="row g-4 justify-content-between">
              <div className="col-md-6 col-lg-7 col-xxl-6">
                <h3 className="mb-0 clr-neutral-90 animate-line-3d">
                  {" "}
                  Start Using OwnXChain
                </h3>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                  <div className="w-30 subtitle-flush-x subtitle-flush-x--secondary subtitle_line_2" />
                  <h6 className="mb-0 clr-secondary-key fs-18 animate-text-from-right">
                    {" "}
                    Unboxing Modularity{" "}
                  </h6>
                </div>
                <p className="mb-0 clr-neutral-80 animate-text-from-right">
                  {" "}
                  OwnXChain empowers decentralization with proposing the most
                  efficient modular infrastructure
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="creative-approach-section position-relative overflow-hidden">
          <div className="container">
            <div className="row g-4 g-xl-0">
              <div className="col-md-6 col-xl-3 creative-approach-section__item">
                <div className="p-6 py-xl-18 fadein_bottom_8">
                  <div className="d-inline-block mb-10">
                    <img
                      src="assets/icon/get_started.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="mb-6 clr-neutral-90">
                    <a
                      href="https://ownx.gitbook.io/ownxchain-documentation"
                      target="_blank"
                      className=" link d-inline-block clr-neutral-80 :clr-secondary-key animate-text-from-bottom"
                    >
                      {" "}
                      Get Started <i className="bi bi-arrow-up-right"></i>
                    </a>
                  </h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                    Explore the documentation that helps understand all you need
                    to know about OwnXChain.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 creative-approach-section__item">
                <div className="p-6 py-xl-18 fadein_bottom_8">
                  <div className="d-inline-block mb-10">
                    <img
                      src="assets/icon/read_whitepaper.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="mb-6 clr-neutral-90">
                    <a
                      href="https://ownx.gitbook.io/ownxchain-whitepaper"
                      target="_blank"
                      className=" link d-inline-block clr-neutral-80 :clr-secondary-key animate-text-from-bottom"
                    >
                      {" "}
                      Read Whitepaper <i className="bi bi-arrow-up-right"></i>
                    </a>
                  </h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                    Read our whitepaper to find out the fundamental idea behind
                    launching OwnXChain & why it matters.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 creative-approach-section__item">
                <div className="p-6 py-xl-18 fadein_bottom_8">
                  <div className="d-inline-block mb-10">
                    <img
                      src="assets/icon/explore_ecosystem.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="mb-6 clr-neutral-90">
                    <a
                      href="/ecosystem"
                      className=" link d-inline-block clr-neutral-80 :clr-secondary-key animate-text-from-bottom"
                    >
                      {" "}
                      Explore Ecosystem <i className="bi bi-arrow-up-right"></i>
                    </a>
                  </h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                    Start using ecosystem dApps that streamlines decentralized
                    values in finance & public applications.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 creative-approach-section__item">
                <div className="p-6 py-xl-18 fadein_bottom_8">
                  <div className="d-inline-block mb-10">
                    <img
                      src="assets/icon/join_community.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="mb-6 clr-neutral-90">
                    <a
                      href="/community"
                      className=" link d-inline-block clr-neutral-80 :clr-secondary-key animate-text-from-bottom"
                    >
                      Join Community<i className="bi bi-arrow-up-right"></i>
                    </a>
                  </h6>
                  <p className="mb-0 clr-neutral-80">
                    Connect to our wide community which is continually expanding
                    in the realm of decentralized finance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
