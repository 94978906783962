import React from 'react'
import Partnershero from './Partnershero'
import Partnersmain from './Partnersmain'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Partners() {
  return (
    <div>
        <Header/>
        <Partnershero/>
        <Partnersmain/>
        <Footer/>

    </div>
  )
}
