import React from 'react'

export default function Gamingmain() {
  return (
    <div>
        <div className="pricing-section-3 section-space-sm-y">
  <div className="section-space-xsm-bottom">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="pricing-section-3__avatar-group">
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section-space-sm-bottom">
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h3 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d"> Built For The Next GameFi  <span className="d-inline-block gradient-flow gradient-flow--start"> Evolution</span>
          </h3>
          <p className="clr-neutral-80 mt-6">The OwnX gaming platform is developed to support the next wave of online blockchain-based gaming ecosystem </p>

        </div>
      </div>
    </div>
  </div>
  <div className="container">
 <div className="row g-4">
  <div className="col-md-6 col-lg-4 col-xl-4 fadein_bottom_23" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
      <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
        <div className="mb-6 d-inline-block mx-auto">
          <img src="assets/img-1/joining_bonus.png" alt="image" className="img-fluid" />
        </div>
        <h6 className="clr-neutral-90 fw-extrabold">Joining Bonus </h6>
        <p className="mb-0 clr-neutral-80"> Instant bonus for joining & connecting wallet  </p>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-lg-4 col-xl-4  fadein_bottom_23" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
      <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
        <div className="mb-6 d-inline-block mx-auto">
          <img src="assets/img-1/daily_jackpot.png" alt="image" className="img-fluid" />
        </div>
        <h6 className="clr-neutral-90 fw-extrabold"> Daily Jackpot
 </h6>
        <p className="mb-0 clr-neutral-80">Play games & get chance to win daily jackpots</p>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-lg-4 col-xl-4  fadein_bottom_23" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
      <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
        <div className="mb-6 d-inline-block mx-auto">
          <img src="assets/img-1/safe_secure.png" alt="image" className="img-fluid" />
        </div>
        <h6 className="clr-neutral-90 fw-extrabold"> Safe & Secure</h6>
        <p className="mb-0 clr-neutral-80">Safe & secure platform for online gaming </p>
      </div>
    </div>
  </div>
  
</div>

  </div>
  <img src="assets/img-1/gmaningbg.png" alt="image" className="img-fluid d-none d-xl-block position-absolute top-0 start-0 pointer-none z-n1" />
</div>

    </div>
  )
}
