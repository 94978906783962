import React from 'react'

export default function Governanceapp() {
  return (
    <div>
    <div>
  <div className="container section-space-sm-top">
    <div className="row">
      <div className="col-md-10 col-xl-8 my-5">
        <h5 className="mb-0 max-text-24 clr-neutral-90 fw-extrabold animate-line-3d"> How OwnXChain <span className="d-inline-block gradient-flow gradient-flow--start mt-2"> Governance Works</span>
        </h5>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row g-4">
      <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
        <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
          <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
            <div className="mb-6 d-inline-block mx-auto">
              <img src="assets/icon-1/proposal.png" alt="image" className="img-fluid" />
            </div>
            <h6 className="clr-neutral-90 fw-extrabold"> Proposal  </h6>
            <p className="mb-0 clr-neutral-80"> A proposal is created with all the development detail </p>
          </div>
          <img src="assets/img/gradient-bottom-half-border.png" alt="image" className="img-fluid w-100 position-absolute start-0 end-0 bottom-0 z-n1" />
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
        <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
          <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
            <div className="mb-6 d-inline-block mx-auto">
              <img src="assets/icon-1/discussion.png" alt="image" className="img-fluid" />
            </div>
            <h6 className="clr-neutral-90 fw-extrabold"> Discussion  </h6>
            <p className="mb-0 clr-neutral-80"> Users discuss the potential benefits of the proposal </p>
          </div>
          <img src="assets/img/gradient-top-half-border.png" alt="image" className="img-fluid w-100 position-absolute start-0 end-0 top-0 z-n1" />
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
        <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
          <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
            <div className="mb-6 d-inline-block mx-auto">
              <img src="assets/icon-1/voting.png" alt="image" className="img-fluid" />
            </div>
            <h6 className="clr-neutral-90 fw-extrabold"> Voting </h6>
            <p className="mb-0 clr-neutral-80">Voting on proposal starts for specific period  </p>
          </div>
          <img src="assets/img/gradient-bottom-half-border.png" alt="image" className="img-fluid w-100 position-absolute start-0 end-0 bottom-0 z-n1" />
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
        <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
          <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
            <div className="mb-6 d-inline-block mx-auto">
              <img src="assets/icon-1/deployment.png" alt="image" className="img-fluid" />
            </div>
            <h6 className="clr-neutral-90 fw-extrabold"> Deployment </h6>
            <p className="mb-0 clr-neutral-80"> Upgrade is deployed if majority votes are in favor </p>
          </div>
          <img src="assets/img/gradient-top-half-border.png" alt="image" className="img-fluid w-100 position-absolute start-0 end-0 top-0 z-n1" />
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}
