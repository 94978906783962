import React from "react";

export default function Aboutsta() {
  return (
    <div>
      <section className="hero-1">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 text-center">
              <h3 className="clr-white fw-extrabold mb-6 animate-line-3d">
              OwnXChain stays at the forefront of 
<br />
                <span className="clr-grad-1"> blockchain evolution</span>
              </h3>
              <p className="fs-18 fw-bold clr-neutral-80 max-text-80 mx-auto mb-10 animate-text-from-bottom">
                {" "}
                With the novel modular architecture, OwnXChain aims to bring diversity in the blockchain landscape & boost its adoption further to the masses.
              </p>
              <div className="newsletter-1 d-inline-block w-100 max-text-60 mx-auto mb-4 position-relative z-1 overflow-hidden rounded fadeIn_bottom" />
            </div>
          </div>
        </div>
       
      </section>
    </div>
  );
}
