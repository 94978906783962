import React from "react";

export default function Communitywords() {
  return (
    <div>
      <section className="cta-section-5 section-space-sm-top section-space-sm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="cta-wrapper-5">
                <div className="text-center">
                  {/* <span className="rounded-1 bg-primary-key bg-opacity-2 clr-white fs-12 fw-bold px-4 py-2 d-inline-block mb-4">
                    rewards
                  </span> */}
                  <h4 className="clr-neutral-90 fw-bold">
                  Want to become an advocate for OwnXChain? Fill in the details & spread the words! 

                  </h4>
                  <a
                    href="login.html"
                    className="link d-inline-flex justify-content-center align-items-center gap-2 py-4 px-6 border border-primary-key :border-primary-30 bg-primary-key :bg-primary-30 rounded-1 fw-bold clr-white mt-8 :arrow-btn"
                  >
                    <span> Become Advocate</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
                <img
                 src="assets/img-1/validator_bg.png"
                  alt="image"
                  className="img-fluid cta-wrapper-4-bg w-100 h-100 object-fit-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
