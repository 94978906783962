import React from 'react'

export default function Dapphero() {
  return (
    <div>
    <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
  <div className="container">
    <div className="swiper blog-single-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="row align-items-center gy-4">
          
            <div className="col-lg-6">
              <div className="blog-latest-content">
            
                <h4 className="h3 mb-6 fw-extrabold link clr-neutral-90  ">Performing an SEO Audit : <span className="clr-grad-4">The Definitive Checklist</span></h4>
                <p className="clr-neutral-80 mb-6">With our AI Generators, the possibilities are endless. From generating compelling marketing copy designing stunning visuals to automating data analysis and creating personalizes user experiences.</p>
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blog-latest-thumb">
                <div className="d-block">
                  <img src="assets/img/blog-list-2.png" alt="image" className="img-fluid w-100 object-fit-cover" />
                </div>
                <span className="blog-latest-label"> Latest News </span>
              </div>
            </div>
          </div>
        </div>
       
      
      </div>
    </div>
  </div>
</div>


    </div>
  )
}
