import React from "react";

export default function Global() {
  return (
    <div>
      <div className="container">
        <div className="section-space-sm-top">
          <div className="section-space-sm-y">
            <div className="row">
              <div className="col-12">
                <div className="  rounded-4 bg-black border border-neutral-10">
                  <div className="p-4 p-md-8 p-xl-10 py-xl-12 rounded-4 bg-neutral-4 border border-neutral-variant-20">
                    <div className="section-space-sm-y ">
                      <div className="row justify-content-lg-end">
                        <div className="col-lg-9">
                          <h3 className="mb-0 clr-neutral-90 animate-line-3d">
                            Build & launch decentralized applications (dApps)
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-12 col-sm-3 col-md-4 col-lg-3">
                          <nav className="">
                            <ul className="list gap-5 nav todo-nav__menu">
                              <li>
                                <a
                                  href="#aiImgGenerateTab"
                                  className="nav-link link todo-nav__link active"
                                  data-bs-toggle="tab"
                                  data-bs-target="#"
                                >
                                  {" "}
                                  DeFi
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#generateVideoTab"
                                  className="nav-link link todo-nav__link"
                                  data-bs-toggle="tab"
                                  data-bs-target="#"
                                >
                                  {" "}
                                  Gaming
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#removeBgTab"
                                  className="nav-link link todo-nav__link"
                                  data-bs-toggle="tab"
                                  data-bs-target="#"
                                >
                                  {" "}
                                  Utility
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-12 col-sm-9 col-md-8  col-lg-9">
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="aiImgGenerateTab"
                            >
                              <div className="row g-4 ">
                                <div className=" col-lg-6 col-xxl-7">
                                  {/* <h5
                                    className="mb-2 clr-neutral-90 fw-extrabold animate-line-3d"
                                    style={{ perspective: "400px" }}
                                  >
                                    {" "}
                                    Track user activity and control access.{" "}
                                  </h5> */}
                                  <p
                                    className="mb-5 max-text-40 fs-7 fw-medium clr-neutral-80 animate-text-from-right"
                                    style={{}}
                                  >
                                    OwnXChain enables development of tailored &
                                    composable financial applications that
                                    enhance the DeFi ecosystem.
                                  </p>
                                  <a
                                    href="/developers"
                                    className="gradient-btn-1 mt-5 position-relative link d-inline-flex justify-content-center align-items-center gap-2 px-4 py-3 rounded fw-bold text-center z-1 mb-1"
                                  >
                                    <span className="d-inline-block">
                                      Build DeFi dApps
                                    </span>
                                    <span className="d-inline-block">
                                      <i className="bi bi-arrow-up-right" />
                                    </span>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-xxl-5">
                                  <div
                                    className="text-lg-center fadeIn_bottom"
                                    style={{
                                      translate: "none",
                                      rotate: "none",
                                      scale: "none",
                                      transform: "translate(0px, 0px)",
                                      opacity: 1,
                                    }}
                                  >
                                    <img
                                      src="assets/img-1/herodefi.png"
                                      alt="image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="generateVideoTab"
                            >
                              <div className="row g-4 ">
                                <div className=" col-lg-6 col-xxl-7">
                                  <p
                                    className="mb-5 max-text-40 fs-7 fw-medium clr-neutral-80 animate-text-from-right"
                                    style={{}}
                                  >
                                    Use customized gaming functions from
                                    OwnXChain development library to build
                                    decentralized play-to-earn games.
                                  </p>
                                  <a
                                    href="/developers"
                                    className="gradient-btn-1 mt-5 position-relative link d-inline-flex justify-content-center align-items-center gap-2 px-4 py-3 rounded fw-bold text-center z-1 mb-1"
                                  >
                                    <span className="d-inline-block">
                                      Build Gaming dApps
                                    </span>
                                    <span className="d-inline-block">
                                      <i className="bi bi-arrow-up-right" />
                                    </span>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-xxl-5">
                                  <div
                                    className="text-lg-center fadeIn_bottom"
                                    style={{
                                      translate: "none",
                                      rotate: "none",
                                      scale: "none",
                                      transform: "translate(0px, 0px)",
                                      opacity: 1,
                                    }}
                                  >
                                    <img
                                      src="assets/img-1/herogaming.png"
                                      alt="image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="removeBgTab">
                              <div className="row g-4 ">
                                <div className=" col-lg-6 col-xxl-7">
                                  <p
                                    className="mb-5 max-text-40 fs-7 fw-medium clr-neutral-80 animate-text-from-right"
                                    style={{}}
                                  >
                                    Explore endless possibilities for developing
                                    a variety of blockchain-based decentralized
                                    utility applications.
                                  </p>
                                  <a
                                    href="/developers"
                                    className="gradient-btn-1 mt-5 position-relative link d-inline-flex justify-content-center align-items-center gap-2 px-4 py-3 rounded fw-bold text-center z-1 mb-1"
                                  >
                                    <span className="d-inline-block">
                                      Build Utility dApps
                                    </span>
                                    <span className="d-inline-block">
                                      <i className="bi bi-arrow-up-right" />
                                    </span>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-xxl-5">
                                  <div
                                    className="text-lg-center fadeIn_bottom"
                                    style={{
                                      translate: "none",
                                      rotate: "none",
                                      scale: "none",
                                      transform: "translate(0px, 0px)",
                                      opacity: 1,
                                    }}
                                  >
                                    <img
                                      src="assets/img-1/heroutility.png"
                                      alt="image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    src="assets/img/faq-section-2-shape.webp"
                    alt="image"
                    className="img-fluid faq-section-2__img"
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row mt-10 justify-content-center">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-4 my-2">
                <a href="/coming-soon" style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    <div className="clr-neutral-50 fs-32">
                      <img src="assets/img-1/developer_forum.png" alt="image" />
                    </div>
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                      Developer Forum
                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                      Connect to developers to discuss queries or
                      troubleshootings.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-4 my-2">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQKqiqOpimkk50sKILJ1UcHkOEbK-kUsgG1X2CPOIBM0WjoA/viewform"  target="_blank" style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    <div className="clr-neutral-50 fs-32">
                      <img src="assets/img-1/grants.png" alt="image" />
                    </div>
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Grants</h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                      Get funding to build your decentralized application on
                      OwnXChain.
                    </p>
                  </div>
                </a>
              </div>
              <div className=" col-12 col-sm-6 col-lg-4 col-xl-4 my-2">
                <a href="/coming-soon" style={{ textDecoration: "none" }}>
                  <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                    <div className="clr-neutral-50 fs-32">
                      <img src="assets/img-1/bounties.png" alt="image" />
                    </div>
                    <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                      Bounties
                    </h4>
                    <p className="clr-neutral-80 mb-0 fs-16">
                      Find vulnerabilities in our ecosystems dApps & win
                      bounties.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
