import React from 'react'

export default function Validatorrewards() {
  return (
    <div>
        <section className="cta-section-5 section-space-sm-top section-space-sm-bottom">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-12">
        <div className="cta-wrapper-5">
          <div className="text-center">
            <span className="rounded-1 bg-primary-key bg-opacity-2 clr-white fs-12 fw-bold px-4 py-2 d-inline-block mb-4">rewards</span>
            <h4 className="clr-neutral-90 fw-bold">Don’t want to set up a node? Delegate your OWNX to other validators & start earning rewards. 
</h4>
            {/* <p className="mb-0 fs-14 clr-neutral-80">Write 10x faster, engage your audience, &amp; never struggle with the blank page again.</p> */}
            <a href="https://ownx.gitbook.io/ownxchain-documentation/guide/delegate-ownx" target='_blank' className="link d-inline-flex justify-content-center align-items-center gap-2 py-4 px-6 border border-primary-key :border-primary-30 bg-primary-key :bg-primary-30 rounded-1 fw-bold clr-white mt-8 :arrow-btn">
              <span>Become Delegato</span>
              <i className="bi bi-arrow-right" />
            </a>
          </div>
          <img src="assets/img-1/validator_bg.png" alt="image" className="img-fluid cta-wrapper-4-bg w-100 h-100 object-fit-cover" />
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
