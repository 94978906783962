import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Communityhero from './Communityhero'
import Communitymain from './Communitymain'
import CommunitySocial from './CommunitySocial'
import Communitywords from './Communitywords'

export default function Community() {
  return (
    <div>
        <Header/>
        <Communityhero/>
        <Communitymain/>
        <CommunitySocial/>
        <Communitywords/>
        <Footer/>
    </div>
  )
}
