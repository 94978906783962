import React from "react";

export default function Newsmain() {
  return (
    <div>
      <div className="container vh-100">
        <div className="row mt-20">
          <div className="col-12">
            <ul
              className="nav nav-underline nav-bordered use-case-tab d-flex  "
              style={{ flexWrap: "wrap" }}
            >
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link active"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#aiSalesEnable"
                >
                  All{" "}
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#learningDevelopment"
                >
                  {" "}
                  Weekly Reports
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#customerService"
                >
                  Partnerships
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#informationSecurity"
                >
                  Releases
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#merketingTeam"
                >
                  Newsletters
                </button>
              </li>
              {/* <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#Wallet"
                >
                  (1) Other{" "}
                </button>
              </li> */}
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="aiSalesEnable"
                role="tabpanel"
                aria-labelledby="aiSalesEnable"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-10">
                      <div
                        className=" px-6 pt-5 rounded-3 d-flex gap-5 flex-column justify-content-between h-100 fadein_bottom_20 border py-4"
                        style={{
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform: "translate(0px, 0px)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/what_is_ownx_chain.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <div className="d-flex flex-column gap-3 mt-3">
                          <h6
                            className="mb-0 fs-18 clr-neutral-80 reveal-text"
                            style={{}}
                          >
                            {" "}
                            9 MAY 2024{" "}
                          </h6>
                          <h5
                            className="mb-0 clr-neutral-90 fs-4 reveal-text"
                            style={{}}
                          >
                            <a
                              href="/blog-1"
                              className="link clr-neutral-90 :clr-primary-key"
                            >
                             What is OwnXChain?
                            </a>
                          </h5>
                          <p className="mt-10 clr-neutral-80 animate-text-from-right">
                          OwnXChain is a decentralized application platform that operates on the distributed ledger technology (DLT). 
                          </p>
                          <p
                            className="mb-0 clr-neutral-90 fs-6 reveal-text"
                            style={{}}
                          >
                            <a
                              href="/blog-1"
                              className="link clr-neutral-90 :clr-primary-key"
                            >
                              READ ARTICLE{" "}
                              <span>
                                <i className="bi bi-arrow-up-right" />{" "}
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="learningDevelopment"
                role="tabpanel"
                aria-labelledby="learningDevelopment"
                tabIndex={0}
              ></div>
              <div
                className="tab-pane fade"
                id="customerService"
                role="tabpanel"
                aria-labelledby="customerService"
                tabIndex={0}
              ></div>
              <div
                className="tab-pane fade"
                id="informationSecurity"
                role="tabpanel"
                aria-labelledby="informationSecurity"
                tabIndex={0}
              ></div>
              <div
                className="tab-pane fade"
                id="merketingTeam"
                role="tabpanel"
                aria-labelledby="merketingTeam"
                tabIndex={0}
              ></div>
              <div
                className="tab-pane fade"
                id="Wallet"
                role="tabpanel"
                aria-labelledby="Wallet"
                tabIndex={0}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
