import React from 'react'

export default function Communitymain() {
  return (
    <div>
        <div className="ai-integration-section section-space-sm-y">
  <div className="section-space-sm-bottom">
    <div className="container">
      <div className="row g-4 justify-content-xxl-between align-items-center">
        <div className="col-12">
          <div className="d-flex align-items-center gap-3 mb-1 fadeIn_bottom">
            <span className="d-inline-block flex-shrink-0">
              {/* <img src="assets/img/icon-subtitle-primary.png" alt="image" className="img-fluid" /> */}
            </span>
            {/* <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18"> AI Video Featured </span> */}
          </div>
          <h4 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d"> Our community is spread across hundreds of countries & native languages so that &nbsp;
 <span className="d-inline-block ff-4 clr-grad-4 fw-norml">  everyone can benefit with what we are building. </span></h4>
        </div>
       
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-10 col-xl-8">
        <div className="text-center fadeIn_bottom">
          <img src="assets/img-1/our_community_is_spread_across.png" alt="image" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
