import React from "react";

export default function Resources() {
  return (
    <div>
      <section className="section-space-sm-top section-space-sm-bottom voice-upload-section position-relative">
        <div className="section-space-sm-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="position-relative text-center">
                  <h3 className="fw-extrabold animate-line-3d text-white">
                    Tools & Resources
                  </h3>
                  {/* <p className="animate-text-from-bottom">Media.io Online Vocal Remover can separate all mingled-up and blended vocals and instrumental tracks from a song. This allows you to use the extracted.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xx-12 col-xl-112">
              {/* <div className="bg-primary-90 p-2 rounded-3 fadeIn_bottom">
                <ul
                  className="nav bg-white pill-tabs rounded-3 justify-content-between py-1 px-2"
                  id="pills-tab"
                  role="tablist"
                >
                
                
                 
                 
                  
                 
             
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link rounded-3 px-3 fw-medium text-white"
                      id="pills-voice-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#lang"
                      type="button"
                      role="tab"
                      aria-controls="pills-voice"
                      aria-selected="false"
                    >
                      Lang{" "}
                    </button>
                  </li>
                </ul>
              </div> */}
              <ul className="nav nav-underline nav-bordered use-case-tab" role="tablist">
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link active" type="button" data-bs-toggle="tab" data-bs-target="#pills-vocal" aria-selected="true" role="tab" >   All </button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#pills-music" aria-selected="false" role="tab" tabIndex={-1}> Native </button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link " type="button" data-bs-toggle="tab" data-bs-target="#pills-video" aria-selected="false" role="tab" tabIndex={-1}>  Wallets{" "} </button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#pills-noise" aria-selected="false" role="tab" tabIndex={-1}>    Explorers{" "}</button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#pills-voice" aria-selected="false" role="tab" tabIndex={-1}>   Oracles{" "} </button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#Providers" aria-selected="false" role="tab" tabIndex={-1}>     RPC Providers</button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#ide" aria-selected="false" role="tab" tabIndex={-1}>  IDE </button>
  </li>
  <li className="nav-item animate-text-from-right mx-3" role="presentation" >
    <button className="nav-link" type="button" data-bs-toggle="tab" data-bs-target="#lang" aria-selected="false" role="tab" tabIndex={-1}>    Lang{" "} </button>
  </li>
</ul>

              <div
                className="tab-content voice-upload-tab-content  position-relative text-center fadeIn_bottom"
                id="pills-tabContent"
              >
                {/* <img src="assets/img/voice-upload-tab-bg.webp" alt="image" className="img-fluid voice-upload-tab-bg" /> */}
                <div
                  className="tab-pane fade show active"
                  id="pills-vocal"
                  role="tabpanel"
                  aria-labelledby="pills-vocal-tab"
                  tabIndex={0}
                >
                  <div className="row d-flex justify-content-center">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/explorer.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/coming-soon"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            OwnXChain Explorer
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/testne-icont.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/coming-soon"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            OwnXChain Testnet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                           src="assets/icon-1/wallet-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/wallet"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            {" "}
                            OwnX Wallet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/sdk.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-documentation/resources/sdk"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            {" "}
                            OwnX SDK
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/meta.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://metamask.io/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Metamask
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/trustwallet-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                           href="https://trustwallet.com/"
                           target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Trust Wallet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
             
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12  mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/bscscan.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://bscscan.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            BscScan
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                   
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12  mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/supra.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://supra.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Supra
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12  mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/chainlink.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                             href="https://chain.link/"
                             target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Chainlink
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                      src="assets/icon-1/shinami.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://www.shinami.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Shinami
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/alchemy.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://www.alchemy.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Alchemy{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/remix.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://remix.ethereum.org"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Remix
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                       src="assets/icon-1/hardhat.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://hardhat.org/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Hardhat{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/truffle.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://archive.trufflesuite.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Truffle{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/vscode-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                           href="https://code.visualstudio.com/"
                           target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            VSCode{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/solidity.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://soliditylang.org/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Solidity
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/nodejs.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                                href="https://nodejs.org/en"
                                target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Node.js{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-music"
                  role="tabpanel"
                  aria-labelledby="pills-music-tab"
                  tabIndex={0}
                >
                  <div className="row ">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                              src="assets/icon-1/explorer.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/coming-soon"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            OwnXChain Explorer
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/testne-icont.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/coming-soon"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            OwnXChain Testnet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/wallet-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/wallet"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            {" "}
                            OwnX Wallet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/sdk.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-documentation/resources/sdk"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            {" "}
                            OwnX SDK
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-video"
                  role="tabpanel"
                  aria-labelledby="pills-video-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/meta.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                              href="https://metamask.io/"
                              target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Metamask
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/trustwallet-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://trustwallet.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Trust Wallet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/wallet-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/wallet"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            {" "}
                            OwnX Wallet
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-noise"
                  role="tabpanel"
                  aria-labelledby="pills-noise-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/bscscan.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                             href="https://bscscan.com/"
                             target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            BscScan
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/explorer.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="/coming-soon"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            OwnXChain Explorer
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-voice"
                  role="tabpanel"
                  aria-labelledby="pills-voice-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/supra.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://supra.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Supra
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/chainlink.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://chain.link/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Chainlink
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="Providers"
                  role="tabpanel"
                  aria-labelledby="pills-voice-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/shinami.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                             href="https://www.shinami.com/"
                             target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Shinami
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/alchemy.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://www.alchemy.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Alchemy{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="ide"
                  role="tabpanel"
                  aria-labelledby="pills-voice-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/remix.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                           href="https://remix.ethereum.org"
                           target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Remix
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/hardhat.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://hardhat.org/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Hardhat{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                  src="assets/icon-1/truffle.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                                href="https://archive.trufflesuite.com/"
                                target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Truffle{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/vscode-icon.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://code.visualstudio.com/"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            VSCode{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="lang"
                  role="tabpanel"
                  aria-labelledby="pills-voice-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/solidity.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                              href="https://soliditylang.org/"
                              target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Solidity
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="gradient-card rounded-4 p-4 text-center">
                        <img
                          src="assets/icon-1/nodejs.png"
                          alt="image"
                          className="img-fluid"
                        />
                        <h5 className="clr-neutral-90 fs-16 fw-bold mt-5 mb-3">
                          <a
                            href="https://nodejs.org/en"
                            target="_blank"
                            className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"
                          >
                            Node.js{" "}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src="assets/img/home-5-header-top-el.webp" alt="image" className="img-fluid section-top-bg-shape" /> */}
        <img
          src="assets/img/home-5-right-shape.png"
          alt="image"
          className="img-fluid section-right-bg-shape"
        />
      </section>
    </div>
  );
}
