import React from "react";

export default function Tokansecurity() {
  return (
    <div>
      <div className="container">
        <div className="row gy-4">
          <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
            <div className="pricing-5-card popular text-center">
              <h4 className="pricing-5-card-name  fw-bold clr-neutral-90">
              Acquire
              </h4>
              <div className="d-flex align-items-center  gap-2">
                <div className="text-start">
                  <h4 className="text-white fw-semibold clr-neutral-40 mb-0 mt-1">
                  Purchase OWNX token from live markets or decentralized exchanges.

                  </h4>
                </div>
              </div>

              <a
                href="#"
                className="link pricing-5-card-btn py-2 px-4 fs-14 clr-neutral-80 d-inline-flex align-items-center gap-2 rounded-1 border border-neutral-80 mt-10"
              >
                Buy <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
            <div className="pricing-5-card text-center">
              <h4 className="pricing-5-card-name  fw-bold clr-neutral-90">
              Stake 
              </h4>
              <div className="d-flex align-items-center  gap-2">
                <div className="text-start">
                  <h4 className="text-white fw-semibold clr-neutral-40 mb-0 mt-1">
                  Stake your OWNX tokens to run an OwnXChain node or become a validator.

                  </h4>
                </div>
              </div>

              <a
                href="/staking "
                className="link pricing-5-card-btn py-2 px-4 fs-14 clr-neutral-80 d-inline-flex align-items-center gap-2 rounded-1 border border-neutral-80 mt-10"
              >
                Stake  <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
            <div className="pricing-5-card text-center">
              <h4 className="pricing-5-card-name  fw-bold clr-neutral-90">
              Use
              </h4>
              <div className="d-flex align-items-center  gap-2">
                <div className="text-start">
                  <h4 className="text-white fw-semibold clr-neutral-40 mb-0 mt-1">
                  Start using OWNX for decentralized application development or payments.
                  </h4>
                </div>
              </div>

              <a
                href="/wallet"
                className="link pricing-5-card-btn py-2 px-4 fs-14 clr-neutral-80 d-inline-flex align-items-center gap-2 rounded-1 border border-neutral-80 mt-10"
              >
               Wallet  <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
