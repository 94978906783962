import React from 'react'

export default function Developmentjourney() {
  return (
    <div>
        <div className="section-space-top section-space-sm-bottom ">
  <div className="container">
    <div className="mb-10">
      <div className="row">
        <div className="col-lg-5">
          <h2 className="h4 clr-neutral-90 fw-extrabold mb-4">Kickstart your development journey</h2>
          <p className="clr-neutral-80 mb-0 ">Build & launch blockchain applications in a simplified manner. 
</p>
        </div>
      </div>
    </div>
    <div className="row gy-4">
      <div className="col-md-6 col-xl-4">
      <div className="pb-6">
  <div className="d-flex align-items-center gap-3 mb-4">
   
    <a href='https://ownx.gitbook.io/ownxchain-documentation/guide/setup-environment' target='_blank' className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"> Set-up Environment ↗</a>
  </div>
<div className='border-top-new'>  <p className="mb-0 clr-neutral-80 pt-2 ">Install OwnXChain CLI to set up development environment  </p></div>
</div>

      </div>
      <div className="col-md-6 col-xl-4">
      <div className="pb-6">
  <div className="d-flex align-items-center gap-3 mb-4">
   
    <a href='https://ownx.gitbook.io/ownxchain-documentation/guide/deploy-a-contract' target='_blank' className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key"> Deploy a Smart Contract ↗</a>
  </div>
<div className='border-top-new'>  <p className="mb-0 clr-neutral-80 pt-2 "> Program & deploy smart contract on OwnXChain.. </p></div>
</div>

      </div>
      <div className="col-md-6 col-xl-4">
      <div className="pb-6">
  <div className="d-flex align-items-center gap-3 mb-4">
   
    <a href='https://ownx.gitbook.io/ownxchain-documentation/api-reference/public-api-endpoints' target='_blank' className="d-block ff-1 fs-18 fw-semibold clr-white link clr-neutral-90 :clr-primary-key">Use APIs ↗</a>
  </div>
<div className='border-top-new'>  <p className="mb-0 clr-neutral-80 pt-2 "> Integrate OwnXChain APIs to embed transaction data. </p></div>
</div>

      </div>
    </div>
  </div>
</div>

    </div>
  )
}
