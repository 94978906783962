import React from 'react'

export default function Networkefficiency() {
  return (
    <div>
        <div className="use-case-section section-space-sm-bottom">
  <div className="container">
    <div className="p-4 p-md-6 py-xxl-10 bg-neutral-10 border border-neutral-17 rounded-4">
      <div className="row gy-4 align-items-center">
        <div className="col-lg-6">
          <img src="assets/img-1/proof_of_alliance_consensus.png" alt="image" className="img-fluid" />
        </div>
        <div className="col-lg-6">
          {/* <span className="py-2 px-3 bg-primary-key clr-white fw-bold fs-12 rounded-2">Featured Use Case </span> */}
          <h4 className="h5 clr-neutral-90 fw-extrabold mt-3 mb-6">The singular consensus for OwnXChain</h4>
          <p className="clr-neutral-80">The Proof-of-Alliance consensus is developed to boost capabilities of OwnXChain and other blockchain networks adopting it. With inheriting functionality from the ever-green Proof-of-Stake consensus method, Proof-of-Alliance leverages staking mechanism to encourage fair-participation & ensure network security. 
</p>
<a href="https://ownx.gitbook.io/ownxchain-whitepaper/proof-of-alliance-poa-consensus" target='_blank' className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center mt-4 px-4 py-2 rounded fw-bold text-center"> Read more  <span className="d-inline-block"><i className="bi bi-arrow-up-right"></i></span> </a>
        </div>
      </div>
    </div>
    <div className="mt-13">
      <div className="row gy-4">
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/energy_efficient.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Energy Efficient</h4>
            <p className="clr-neutral-80 mb-0 fs-16">Proof-of-Alliance uses less energy than leading consensus like PoW & PoS.</p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/utmost_secure.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Utmost Secure</h4>
            <p className="clr-neutral-80 mb-0 fs-16">Involvement of Delegator alliances helps in enhancing overall network security. </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/cost_optimized.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Cost-optimized</h4>
            <p className="clr-neutral-80 mb-0 fs-16">In-built optimization makes running validator nodes on Proof-of-Alliance inexpensive.</p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/open_source.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Open-source</h4>
            <p className="clr-neutral-80 mb-0 fs-16">Anyone can make use of Proof-of-Alliance consensus as its code is open-source.</p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/modern_architecture.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Modern Architecture </h4>
            <p className="clr-neutral-80 mb-0 fs-16">Developed using latest releases & capable of adapting futuristic upgrades. </p>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
            <div className="clr-neutral-50 fs-32">
              <img src="assets/icon-1/tailored_solution.png" alt="image" />
            </div>
            <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">Tailored Solution</h4>
            <p className="clr-neutral-80 mb-0 fs-16">Infrastructure of Proof-of-Alliance can be tailored for specific applications. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
