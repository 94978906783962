import React from 'react'

export default function Acceleratorhero() {
  return (
    <div>
           <section className="hero-1">
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col-md-10 col-lg-9 col-xxl-10 text-center">
        <h2 className="clr-white fw-extrabold mb-6 animate-line-3d">Does Your dApp Holds Greater Potentials? 
 <span className="clr-grad-1"> Let’s Grow It Together</span>
        </h2>
        <p className="fs-18 fw-bold clr-neutral-80 max-text-80 mx-auto mb-10 animate-text-from-bottom"> Let’s make the OwnXChain ecosystem more exclusive with the developing & growing high-utility decentralized applications (dApps). Your development idea could become a success with our combined efforts.
 </p>
 <a href="#" className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-5 py-2 rounded fw-bold text-center"> Apply  </a>
       
      </div>
    </div>
  </div>
  {/* <img src="assets/img/hero-shape-img-1.webp" alt="image" className="img-fluid hero-1-shapes__img hero-1-shapes__img--5" />
  <img src="assets/img/hero-shape-img-2.webp" alt="image" className="img-fluid hero-1-shapes__img hero-1-shapes__img--6" /> */}
</section>
    </div>
  )
}
