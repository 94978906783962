import React from 'react'
import Gaminghero from './Gaminghero'
import Header from '../Comman/Header'
import Gamingmain from './Gamingmain'
import Footer from '../Comman/Footer'
import GamesSoon from './GamesSoon'

export default function Gaming() {
  return (
    <div>
      <Header/>
       <Gaminghero/> 
       <Gamingmain/>
       <GamesSoon/>
       <Footer/>
    </div>
  )
}
