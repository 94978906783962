import React from 'react'

export default function Governancepre() {
  return (
    <div>
         <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
  <div className="container">
    <div className="swiper blog-single-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="row align-items-center gy-4">
          
            <div className="col-lg-6">
              <div className="blog-latest-content">
            
                <h4 className="h4 mb-6 fw-extrabold clr-neutral-90">OwnXChain’s open governance allow everyone to <span className='clr-grad-1'> contribute for its development & growth </span>
</h4>
                <p className="clr-neutral-80 mb-6">OwnXChain has inherited an open-governance mechanism for proposing & voting on protocol changes. This approach empowers the community to actively shape the trajectory of the blockchain while fostering transparency, community engagement, & a sense of collective ownership in the ecosystem.</p>
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blog-latest-thumb">
                <div className="d-block">
                  <img src="assets/img-1/ownxchains_open_governance.png" alt="image" className="img-fluid w-100 object-fit-cover" />
                </div>
                
              </div>
            </div>
          </div>
        </div>
       
      
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
