import React from 'react'
import Developershero from './Developershero'
import Developersmain from './Developersmain'
import Header from '../Comman/Header'
import Developmentjourney from './Developmentjourney'
import Resources from './Resources'
import Footer from '../Comman/Footer'
import Developersjoin from './Developersjoin'

export default function Developers() {
  return (
    <div>
        <Header/>
        <Developershero/>
        {/* <Developersmain/> */}
        <Developmentjourney/>
        <Resources/>
        <Developersjoin/>
        <Footer/>
    </div>
  )
}
