import React from 'react'
import Ecosystemhero from './Ecosystemhero'
import Ecosystemtab from './Ecosystemtab'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Ecosystem() {
  return (
    <div>
      <Header/>
      <Ecosystemhero/>
      <Ecosystemtab/>
      <Footer/>
    </div>
  )
}
