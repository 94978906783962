import React from 'react'

export default function Governancehero() {
  return (
    <div>
    <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
  <div className="container my-10 mt-20">
    <div className="swiper blog-single-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="row align-items-center gy-4">
            <div className="col-lg-6">
              <div className="blog-latest-content">
                <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">Participate in OwnXChain Governance & 
<span className='clr-grad-4'> Games & Win Rewards</span></h3>
                <p className="clr-neutral-80 mb-6">OwnXChain is fully decentralized & governed by the community members. Every OWNX token holder can create proposals or vote on existing proposals.  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="blog-latest-thumb">
                <div className="d-block">
                  <img src="assets/img-1/games_win_rewards.png" alt="image" className="img-fluid w-100 object-fit-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
