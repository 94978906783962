import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Brandkithero from './Brandkithero'
import Brandkithmain from './Brandkithmain'
import Branding from './Branding'
import Bcolor from './Bcolor'

export default function Brandkit() {
  return (
    <div>
        <Header/>
<Brandkithero/>
<Brandkithmain/>
{/* <Branding/> */}
<Bcolor/>
        <Footer/>
    </div>
  )
}
