import React from "react";

export default function ProoallianceDesigned() {
  return (
    <div>
      <section className="hero-1">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 text-center">
              <h3 className="clr-white fw-extrabold mb-6 animate-line-3d">
                Designed to achieve efficiency in
                <span className="clr-grad-1"> blockchain </span>
              </h3>
              <p className="fs-18 fw-bold clr-neutral-80 max-text-80 mx-auto animate-text-from-bottom">
                {" "}
                Proof-of-Alliance is designed to enhance community participation
                in blockchain network security. By using staking-based
                validation & delegation method synchronously, Proof-of-Alliance
                improves network efficiency while broadening its sustainability.
              </p>
            </div>
            <div className="col-12 text-center">
              <video autoPlay muted loop playsInline preload="metadata">
                <source
                  src="assets/video/proof_of_alliance_1.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
