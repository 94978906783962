import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function TermsCondition() {
  return (
    <div>
        <Header/>
        <section className="hero-1">

      <div className="container">
        <h2>
          <span style={{ fontSize: "16pt", color: "rgb(255, 255, 255)" }}>
            OwnXChain Terms &amp; Condition
          </span>
        </h2>
        <p>
              <span   className="clr-neutral-80 animate-line-3d">
            <span className="clr-neutral-80 animate-line-3d">
              NOTICE OF BINDING TERMS AND CONDITIONS: You are legally bound by
              these “Terms and Conditions” when you visit or use our “Site,”
              including but not limited to our Website (at
              http://www.ownx.foundation) and other software or internet-based
              locations and features as identified below. You should carefully
              read these “Terms and Conditions” before using any infrastructure
              available through the Site, including as you may access it
              directly or indirectly, such as where it may be embedded in any
              associated web applications or wallets you use. If you do not
              understand and accept all these Terms and Conditions, then you
              should avoid using our Site, refrain from downloading any
              materials from our Site, and promptly redirect your web browser
              (or other internet-based application you may be using) away from
              our Site.
            </span>
          </span>
        </p>
        <p>
          <span className="clr-neutral-80 animate-line-3d">
            NOTICE OF BINDING ARBITRATION: Any claim, dispute, or controversy of
            any nature arising out of these Terms and Conditions must be
            resolved by final and binding arbitration in accordance with the
            requirements described in Section 13.
          </span>
        </p>
        <p>
          <span className="clr-neutral-80 animate-line-3d">
            <br />
          </span>
        </p>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            1. Agreement to Terms and Conditions
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.1. Your Assent to these Terms and Conditions.&nbsp;
                </span>
              </strong>
              <span className="clr-neutral-80 animate-line-3d">
                These Terms and Conditions constitute a legally binding
                agreement between you, whether personally or on behalf of an
                entity or group (“you” or “your”) and OwnX (“we,” “us,” “our,”
                or “OwnX”) concerning your use of our Site (as defined in clause
                1.4). You are strictly prohibited from, and agree to refrain
                from, using or accessing any part of the Site if you do not
                consent to these Terms and Conditions.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.2. Entire Agreement.&nbsp;
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                These Terms and Conditions, together with any other terms,
                policies, or notices to which you may agree in writing, as
                applicable (including but not limited to those documents
                incorporated by clause 1.11, below) constitute the sole and
                entire agreement between you and OwnX and supersede all prior
                and contemporaneous understandings between the parties on your
                use of the Site. You agree that, by accessing the Site, you
                represent and warrant that you have read, understood, and agreed
                to all provisions of these Terms and Conditions.
              </span>
            </p>
          </li>
          <li className="clr-neutral-80 animate-line-3d">
            <strong>
              <ul>
                <li style={{ listStyleType: "none", fontSize: "11pt" }}>
                  <p>
                        <span   className="clr-neutral-80 animate-line-3d">
                      1.3. Contacting Us.&nbsp;
                    </span>
                  </p>
                </li>
              </ul>
            </strong>
          </li>
        </ul>
        <p>
          <span className="clr-neutral-80 animate-line-3d">
            a. VIA EMAIL at&nbsp;
          </span>
          <span className="clr-neutral-80 animate-line-3d">
            <a href="mailto:info@ownx.foundation">
              <u>
                    <span   className="clr-neutral-80 animate-line-3d">info@ownx.foundation</span>
              </u>
            </a>
          </span>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >1.4. Our Site.</span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                &nbsp;These Terms and Conditions govern your use of our “Site,”
                or the blockchain-based protocols of the underlying OwnXChain
                (which OwnX does not own, operate, or control), but which DOES
                INCLUDE the software and internet-based information, services,
                and features (and related communications) available through each
                of the following:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    a. Our website at http://www.ownx.foundation/ or any
                    subdomain thereof controlled by us (the “Website”);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    b. Our forum at http://forum.ownx.foundation (the “Forum”);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    c. Our blockchain Explorer available at
                    http://explorer.ownx.network (the “Explorer”);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    d. Other communication by us on infrastructure not operated
                    by us including accounts on social media platforms (such as
                    Twitter, Facebook, YouTube, Medium and the like) and
                    communication tools (such as Discord and Telegram) and other
                    public forums moderated by us (collectively, “Social
                    Media”).
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <br />
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.5. Disclaimer of Responsibility for the Chain.&nbsp;
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                OwnX has no control over the operation of the Chain and offers
                no warranties in relation to its use, and your use of the Chain
                is not governed by these Terms and Conditions. The Chain forms a
                decentralized, permissionless distributed ledger technology
                ecosystem with no single source of truth or central authority or
                point of control. By accepting these Terms and Conditions, you
                represent that you understand and accept this disclaimer and the
                disclosures provided in the Important Notice relevant to your
                use of the Chain. You should reference the Important Notice
                (available at www.ownx.foundation/notice) for further details
                before using the Chain or interacting with any decentralized
                applications (“dApps”) running on the Chain. OwnX is not
                responsible for any action in which you might engage using the
                Chain, or for any actions of unauthorized users of any account
                or credentials belonging to you to access any part of the Site,
                and OwnX expressly disclaims and all corresponding liability.
              </span>
            </p>
          </li>
        
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.6. Your Agreement to Modifications.
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                &nbsp;We may make changes to these Terms and Conditions at any
                time, and, by accessing the Site, you agree to be bound by the
                most current version. The date of the most current version of
                these Terms and Conditions will be indicated on the
                upper-left-most location of these Terms and Conditions, and any
                modified version will be effective immediately when published.
                You are responsible for reviewing these Terms and Conditions to
                stay informed of updates. We will interpret your continued use
                of the Site following any published modification of these Terms
                and Conditions as your acceptance of the new version
              </span>
            </p>
          </li>
         
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.7. Changes to our Site.
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                &nbsp;We may update or change any component of the Site from
                time to time to reflect changes to our products, user needs or
                business priorities, without providing notice to you.
              </span>
            </p>
          </li>
        
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.8. Access Restrictions.&nbsp;
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                The information and features provided on the Site are not
                intended for distribution to or use by any person or entity in
                any jurisdiction or country where distribution or use would be
                contrary to law or regulation, such as applicable sanctions, or
                require us to hold any special license or registration within
                your jurisdiction. We make no claims that the Site or any of its
                content or features are accessible or appropriate in the user’s
                jurisdiction. Access to the Site may not be legal by certain
                persons or in certain countries. If you access the Site, you are
                solely responsible for ensuring that your access to and use of
                the Site does not violate any applicable laws or regulations in
                any jurisdiction to which you may be subject.
              </span>
            </p>
          </li>
       
    
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.9. Representation of Capacity and Authority.&nbsp;
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
              
                The Site is only to be used by persons who are at least 18 years
                old. By using the Site, you agree to these Terms and Conditions
                and represent and warrant that you are at least 18 years old,
                and that you have both the authority (on your own behalf or as
                an agent of an entity) and capacity to assent to these Terms and
                Conditions.
              </span>
            </p>
          </li>
          
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.10. Incorporation of Other Terms.
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                &nbsp;As between you and OwnX, all other terms, conditions,
                policies, or notices to which you may agree, including but not
                limited to each of the following, are expressly incorporated
                into these Terms and Conditions by reference:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    a. Our Privacy Policy, available at
                    www.ownx.foundation/privacy, which governs our use of any
                    personal data we collect about you or you provide to us,
                    and, under which you consent to our use of your personal
                    information and warrant that all data you provide is
                    accurate (our “Privacy Policy”);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    b. The Important Notice, available at
                    www.ownx.foundation/notice, which provides you with certain
                    disclosures that you accept in relation to your use of the
                    Chain (the “Important Notice”);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    c. Any applicable terms, disclosures, and other documents
                    that may be posted on the Site from time to time;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    d. Any other terms and conditions to which you may
                    separately agree and govern your use of the software,
                    products, services, content, tools and market data provided
                    through the Site; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span className="clr-neutral-80 animate-line-3d" >
                    e. To the extent you use a Wallet through the Site, you are
                    additionally subject to the Supplemental Wallet Terms,
                    available at www.ownx.foundation/wallet_terms, which sets
                    out terms and conditions related to Wallets, which govern in
                    addition to these Terms and Conditions (the “Supplemental
                    Wallet Terms”).
                  </span>
                </p>
              </li>
             
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <strong>
                    <span   >
                  1.12. References to Your Account.
                </span>
              </strong>
              <span  className="clr-neutral-80 animate-line-3d">
                &nbsp;Any reference to an “account” in these Terms and
                Conditions refers to an account or credential maintained and
                used uniquely to identify you and permit your access to any
                component of the Site, such as in relation to your use of the
                Website, Vault Page, or the Forum. On the other hand, any
                reference to an “account” in these Terms and Conditions is
                completely unrelated to your connection and therefore accounts
                with any Social Media, the Chain, or any Wallet you may hold or
                interact with through OwnX.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            2. Acceptable Use
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                2.1. Use Restricted to Site Purposes. You may not access or use
                any part of the Site other than for the purpose or purposes
                expressly stated or readily implied by us through the Site or in
                other communications to you.
              </span>
            </p>
          </li>
        
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                2.2. Your Revocable Licence. Your use of our Site, including
                related software components, is subject to a limited, revocable
                license that we grant to you under these Terms and Conditions.
                We do not sell this license to you, and you acknowledge that we
                do not transfer or assign any title or ownership rights to any
                part of the Site to you.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                2.3. Prohibited Uses. OwnX is not responsible or liable for your
                acts or behaviors, or the acts or behaviors of other Site users
                or other third parties, including but not limited to, through
                the Forum, Social Media, or Third Party Products (as defined in
                clause 4.5). As a user of our Site, you agree not to do any of
                the following:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span className="clr-neutral-80 animate-line-3d" >
                    a. Make any unauthorized use of the Site, such as collecting
                    other users’ usernames or email addresses to send
                    unsolicited communications or creating user accounts
                    (including any accounts related to the Forum or when using
                    Social Media) under false pretenses;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span className="clr-neutral-80 animate-line-3d" >
                    b. Systematically retrieve data or other content from the
                    Site or users of the Site to compile a database or directory
                    without our written permission;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    c. Circumvent, disable, or otherwise interfere with our
                    Site’s security-related features, including features that
                    prevent or restrict using or copying any Site-related data
                    or content or enforce limitations on their use or copy;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span className="clr-neutral-80 animate-line-3d" >
                    d. Use any Site information or features to bully, stalk,
                    harass, abuse, assault, or harm any person, group or
                    organization;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    e. Use any Site information or features in any effort to
                    compete with us, create your own revenue-generating endeavor
                    or commercial enterprise, or offer any substitute for any of
                    the products, services or features we offer, either within
                    or outside the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    f. Harass, annoy, intimidate, or threaten any other Site
                    user or any of our employees, agents, contractors, partners,
                    or suppliers;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    g. Delete the copyright or other proprietary rights notice
                    from your replication or use of any information or other
                    content available on or through the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span className="clr-neutral-80 animate-line-3d" >
                    h. Decipher, decompile, disassemble, or reverse engineer any
                    of the software comprising or in any way making up any part
                    of the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    i. Upload or transmit (or attempt to upload or transmit) any
                    information, code, or other materials that function as a
                    mechanism for passive or active information collection,
                    including but not limited to clear graphics interchange
                    formats (or GIFs), 1×1 pixels, web bugs, cookies, or other,
                    similar devices (sometimes referred to as spyware or passive
                    collection mechanisms);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    j. Upload or transmit (or attempt to upload or transmit)
                    viruses, Trojan horses, or other material that interferes
                    with any person, organization, or group’s uninterrupted use
                    and enjoyment of the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    k. Use, launch, or engage in any automated use of the Site’s
                    system, such as using scripts to send comments or messages,
                    robots, scrapers, or offline readers, or deploying similar
                    data gathering or extraction tools;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    l. Disparage, tarnish, or otherwise harm the Site, or us or
                    our agents, employees, partners, or suppliers, or use the
                    Site to violate other persons’ privacy or other legal
                    rights;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    m. Use the Site in a manner inconsistent with, or that
                    violates, any applicable law or regulation;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    n. Trick, defraud, or mislead us and other Site users,
                    especially in any attempt to learn sensitive account
                    information, such as user passwords or other credentials;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    o. Make improper use of our support services or submit false
                    reports of abuse or misconduct;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    p. Interfere with, disrupt, or create an undue burden on any
                    component of the Site or networks connected to the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    q. Attempt to impersonate another user or person, use the
                    username or credentials of another user, or impersonate or
                    attempt to impersonate OwnX or any Foundation agent or
                    employee (including, without limitation, by using any
                    associated email addresses or usernames);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    r. Use the Site to advertise or sell goods and services;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    s. Access or attempt to access any part of the Site that you
                    are restricted from accessing;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    t. Misrepresent your or any other Site user’s experience,
                    skills, or information;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    u. Falsely imply a relationship with us or another company
                    with whom you do not have a relationship.
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    v. Engage in any overtly discriminatory, racist, sexist,
                    misogynistic, objectifying, homophobic, or transphobic act
                    or permeate any other form of bigotry or hatred;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    w. Act in any way to release personal information, including
                    real-world information about other users or any OwnX agents
                    or employees;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    x. Engage in, advocate for, or induce any act that would be
                    deemed illegal in any jurisdiction to which you may be
                    subject, including but not limited to any theft, aggravated
                    sexual act, self–harm, insurrection, property damage,
                    violent act, intimidation, libel, or other harm;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d" >
                    y. Expose any persons under 18 years old to dangerous
                    situations, including but not limited to grooming,
                    inappropriate content, or sexualization (regardless of
                    jurisdiction); or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    z. Impersonate or attempt to impersonate any other person,
                    including the Foundation or any Foundation agent or employee
                    (using an email address, name, username, title, or other
                    identifying information or credential).
                  </span>
                </p>
              </li>
             
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                2.4. Unauthorised Access or Use. You understand and agree that
                you are strictly prohibited from (a) making any attempt to gain
                unauthorised access to the Site or any networks, servers, or
                computer systems connected to the Site and (b) making any
                modifications, adaptations, additions, or enhancements to the
                Site or our Site Content for any reason (including error
                correction), including the modification of the paper or digital
                copies you may have downloaded.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            3. Your Responsibilities
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                3.1. Responsibility for Your Conduct. You accept and acknowledge
                that solely you are responsible for your conduct and all
                activity associated with your Site accounts and credentials, any
                Wallet you may hold, and your use of the Site and, if
                applicable, the Chain. You alone are responsible for any
                unauthorised access to and use of your accounts and credentials,
                and you accept all risk resulting from any other access to or
                use of (whether authorised or unauthorised) the Site or related
                third-party services, to the maximum extent permitted by law.
                You agree to use the Site for purposes that are legal, proper,
                and in accordance with any applicable laws or regulations and
                the provisions of these Terms and Conditions.
              </span>
            </p>
          </li>
          
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                3.2 Responsibility for Your Credentials. You understand and
                accept that you are responsible for securing your own private
                keys or other credentials you use to access any component of the
                Site, the Chain, your Wallet, or third-party applications. You
                represent and warrant that you are familiar with and accept the
                risks associated with digital applications, digital identity,
                private keys, and the importance of keeping these secure, and
                other related risks.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            4. Our Content
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.1. Rights to Site Content. Unless otherwise indicated by us in
                writing, we own all rights to our Site and all related content
                and features, including but not limited to source code,
                databases, functionality, software, website designs, audio,
                video, text, photographs, logos, and graphics available on,
                through, or in connection with any part of the Site (altogether,
                “Site Content”). Except where specifically attributed to someone
                else, we own all necessary moral, proprietary, and licensing
                rights related to Site Content, which is protected by copyright,
                trademark, and other applicable intellectual property laws and
                international conventions all around the world. You are not
                permitted to use our trademarks or copyrighted material without
                first receiving our written consent or the consent of the owner
                of the marks.
              </span>
            </p>
          </li>
         
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.2. Use of Open-Source Code. Some components of the Site and
                related services or features may include certain open source
                components that are subject to open source software licenses
                owned by one or more third parties (the “Open Source Code”).
                Your use of any Open-Source Code or any components of our Site
                is not subject to the provisions of these Terms and Conditions
                but, instead, is subject to the licence terms accompanying the
                Open-Source Code. Nothing in these Terms and Conditions limits
                your rights under, or grants you rights superseding, the terms
                of any Open-Source Code.
              </span>
            </p>
          </li>
        
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span className="clr-neutral-80 animate-line-3d" >
                4.3. Copying Our Site or Content. Unless otherwise expressly
                provided in these Terms and Conditions, you are prohibited from,
                and agree not to, copy, reproduce, aggregate, republish, upload,
                post, publicly display, encode, translate, transmit, distribute,
                sell, licence, or otherwise commercially exploit any part of the
                Site or any Site Content without first obtaining our express
                written permission.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.4. Your Limited Licence. If you are eligible to use the Site,
                we grant you a limited licence to access and use the Site and
                our Site Content in accordance with these Terms and Conditions,
                and to download or print a copy of any Site Content that you are
                authorised to access, but solely to the extent you use the
                copies for your own personal, non-commercial use.
              </span>
            </p>
          </li>

          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.5. Third-Party Products. In addition to or as part of our Site
                Content, third parties may advertise, make commercial offers, or
                provide you with information, products, or services, including
                through third-party integrations and links to other websites
                (collectively, “Third-Party Products”). Third-Party Products may
                be governed by separate agreements or licences with those third
                parties. We offer no guarantee and assume no responsibility or
                liability of any kind for Third-Party Products or their
                providers, including but not limited to your use of any content
                or features available through third-party sites. You understand
                and agree that you are responsible for the consequences of your
                use of any Third-Party Products, and that we bear no
                responsibility or liability for Third-Party Products or the
                actions of any third parties. We are not responsible for the
                actions of any third party or their compliance or noncompliance
                with any applicable laws or regulations.
              </span>
            </p>
          </li>
        
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.6. Purposes of Content. All Site Content is provided to you
                for informational purposes only. To the extent permitted by law
                and subject to clauses 8.6 and 8.7, we disclaim all liability in
                relation to your use of any Site Content. All other information,
                services, or features of third parties (whether available
                through our Website, on any Social Media, or by other means) is
                also for informational and educational purposes only. No party
                of any Site Content or third-party content should be construed
                as an endorsement by OwnX to use any part of the Site or any
                products or services whatsoever. No Third-Party Products should
                be considered an endorsement of OwnX. To the extent you use
                Third-Party Products or otherwise engage with third parties or
                third-party sites, links, or pop-ups, you do so at YOUR OWN
                RISK.
              </span>
            </p>
          </li>
         
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.7. Notifying Us of improper Copyright or Trademark use. If you
                are, or are authorised to act on behalf of, a copyright or
                trademark owner, and if you believe in good faith that your
                copyrighted work or trademark has been copied in a way that
                constitutes infringement of your intellectual property rights,
                then please provide us with written notice via mail or email (as
                specified in clause 1.3). Upon receipt of your notice, we will
                take whatever action we deem appropriate under the circumstances
                and applicable law, in our sole discretion.
              </span>
            </p>
          </li>

          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                4.8. Disclaimer of Warranties. Although we make reasonable
                efforts to update the information on our Site, we make no
                representations, warranties, or guarantees, whether express or
                implied, that our Site Content is accurate, complete, or up to
                date. The Site is provided to you AS IS and AS AVAILABLE, with
                all faults. We expressly disclaim all liability for any
                inaccuracies in the Site, Site Content, or information processed
                through our systems or provided by any third party. We disclaim
                all warranties, express or implied, with respect to the Site,
                Site Content, and Third-Party Products including but not limited
                to any warranty of fitness for a particular purpose,
                merchantability, or good and workmanlike product or service.
                Additionally, we do not guarantee our Site will be secure or
                free from bugs, viruses, or other malware. You should not
                interpret any Site Content or any communications from us as
                advice of any kind, including but not limited to financial
                advice. You should seek professional or specialist advice before
                you ever consider acting or not acting on the basis of any Site
                Content. We are not liable for any errors in actions taken in
                reliance Site Content or any communications you may receive from
                us, including with respect to the release of any tokens or
                funds.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            5. Your Content
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.1. Our Use of Your Content. The Site may provide you with
                opportunities to upload, display, or transmit data or other
                content (together, “User Content”). As part of these Terms and
                Conditions and subject to our Privacy Policy (available at
                www.ownx.foundation/privacy), you grant us a perpetual,
                irrevocable, worldwide, fully paid, non-exclusive, and
                non-sublicensable right and license to use and exploit any User
                Content in any form. We retain the unlimited right and exclusive
                discretion to copy, reproduce, fix, modify, adapt, translate,
                reformat, prepare derivatives of, manufacture, publish,
                distribute, sell, licence, transfer, lease, or transmit any User
                Content. We also reserve the right to incorporate any User
                Content as part of the Site or any Site Content, or into any
                other material.
              </span>
            </p>
          </li>
         
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.2. Your Feedback. No User Content you submit will be deemed to
                establish any additional or separate contractual relationship
                between you and us outside the provisions of these Terms and
                Conditions.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.3. Your Waiver of Moral Rights. Except to the extent
                prohibited by law, you agree to waive any intellectual property
                interest or other “moral rights” you may have in the User
                Content in any competent jurisdiction.
              </span>
            </p>
          </li>
          
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.4. Your Right to Submit Content. You represent and warrant
                that no User Content you upload through the Site in any way
                infringes on the copyright, trademark, patent, trade secret, or
                other intellectual property right of any third party.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.5. Prohibited Content. You represent and warrant that you will
                not use or contribute User Content that is unlawful, tortious,
                defamatory, obscene, invasive of personal privacy, threatening,
                harassing, abusive, hateful, racist, or otherwise objectionable,
                inappropriate, or which would otherwise violate clause 2.3.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                5.6. Our Removal of Content. We may remove any User Content or
                related content or elements from our Site at our sole
                discretion, and you understand and agree that we are not liable
                for removing or not removing any User Content or other content
                for any reason. We reserve the right to review any User Content.
                We may, in our discretion, take any appropriate action against
                you if you upload User Content in violation of these Terms and
                Conditions.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            6. Site Management
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                6.1. Our Functions. We reserve the right, in our sole
                discretion, to do any of the following:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    a. Monitor the Site for breaches of these Terms and
                    Conditions;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    b. Take appropriate legal action against anyone in breach of
                    applicable laws or these Terms and Conditions;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    c. Disable access to the Site to address any hacks,
                    exploits, system vulnerabilities, or anything that is in any
                    way a burden to our systems; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    d. Otherwise manage the Site in a manner designed to protect
                    our rights and property and to facilitate the proper
                    functioning of our Site.
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <br />
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                6.2. Site Uptime. We cannot guarantee or make any assurances of
                any kind that our Site will always be available and free from
                defects, viruses and malware and we expressly disclaim all
                warranties under clause 4.8. We may experience hardware,
                software, or other problems or need to perform maintenance
                related to the Site, which may result in interruptions, delays,
                or errors. You agree that we have no liability whatsoever for
                any loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of
                the Site. We are not obligated to maintain and support the Site
                or supply any corrections, updates, or releases.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            7. Information Collection
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                7.1. Privacy of Your Information. To use certain features of the
                Site, you may be required to provide us with your personal
                information, including but not limited to your name, address,
                and contact details. Our use of your personal information is
                subject to the terms of our Privacy Policy (available at
                www.OwnX.foundation/privacy), which is incorporated in these
                Terms and Conditions. Any data collected about you or from you
                by third parties on our behalf is also subject to our Privacy
                Policy.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                7.2. Collecting Anonymised Data. When you use the Site, we may
                monitor your computer or mobile device’s memory to ensure the
                Site is performing properly. During this process, we may collect
                anonymised data from you or your device and use it to inform our
                Site’s continued development.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            8. Limitation of Liability
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span className="clr-neutral-80 animate-line-3d">
                8.1. Scope of Limitations. All warranties, terms, conditions and
                undertakings, express or implied (including by statute, custom
                or usage, a course of dealing, or common law), which we
                expressly disclaim in clause 4.8, as well as the liability we
                disclaim under this Section 8, are excluded to the fullest
                extent permitted by applicable law.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                8.2. Force Majeure. We will not be responsible for any delay or
                failure to comply with our obligations under these Terms and
                Conditions if our delay or failure is caused by an event
                reasonably beyond our control.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                8.3. Your Acceptance of Risks. You warrant that you understand
                and accept the inherent security risks of providing information
                over the internet and dealing online, and you agree that we have
                no liability or responsibility for any breach of security unless
                it is due to our gross negligence. Any information you send or
                receive while using the Site may not be secure and may be
                intercepted or later acquired by unauthorised parties. Your use
                of the Site and any related features or tools is at your own
                risk. You accept that in using any of the Site, Wallet or Chain,
                you are additionally subject to and understand and accept all
                other associated risks such as ownership expectations,
                technology risk, token risk and market risk as described in
                clause 4 (Assumption of risks) in our Supplemental Wallet Terms.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                8.4. Liability Cap. Notwithstanding anything to the contrary
                contained in these Terms and Conditions, our liability to you
                (for any cause whatsoever and regardless of the form of action)
                will at all times be limited to no more than a total aggregate
                amount equal to the greater of (a) the sum of $10,000 (USD) or
                (b) the amount paid, if any, by you to us for the use of any
                part of the Site during the 6-month period preceding the rise of
                any lawful cause of action.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
              <span  className="clr-neutral-80 animate-line-3d">
                8.5. Our Responsibility for Loss or Damage. Regardless of
                whether you are a consumer or business user, we do not exclude
                or in any way limit our liability to you where it would be
                unlawful, such as liability for death or personal injury caused
                by our negligence or the negligence of our employees, agents, or
                contractors, or for fraud or fraudulent misrepresentation. We
                will not be responsible or liable to you for any losses you
                incur resulting from your use of the Site, your Wallet (if
                applicable), any other wallet (whether or not accessible from
                our Site) or any third-party dApps (whether or not accessible
                from our Site). To the extent permitted by law, we will not be
                responsible for any loss or damage, whether in contract, tort
                (including negligence), breach of statutory duty, or otherwise,
                even if foreseeable, arising under or in connection with your
                use of, or inability to use, our Site, or with your use of or
                reliance on any content displayed or otherwise obtained on or
                through our Site. Our exclusion from liability includes but is
                not limited to the following:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    a. Loss of profits, sales, business, or revenue;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    b. Business interruption;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    c. Loss of anticipated savings;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    d. Loss of business opportunity, goodwill or reputation;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    e. Any indirect or consequential loss or damage;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    f. Any damage suffered from unauthorised access or
                    activities by third parties, including the use of viruses,
                    phishing, brute forcing, or other means of attack against
                    the Site, the Chain, or your Wallet;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                  <span  className="clr-neutral-80 animate-line-3d">
                    g. Any user error, such as forgotten private keys or
                    incorrectly construed smart contracts or other transactions;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    h. Any server failure or data loss, including loss of
                    private keys;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    i. Corrupted wallet files; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    j. Infringement of intellectual property rights by other
                    users.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                8.6. Our Additional Responsibility if You Are a Consumer. If you
                are a consumer user of the Site, you agree that we have no
                liability to you for any loss of profit, loss of business or
                business opportunity, or business interruption resulting from
                your use of our Site for any commercial or business purposes. As
                a consumer, you may have legal rights in relation to goods that
                are faulty or not as described. Advice about your legal rights
                is available from the Cayman Islands Judicial Administration,
                the Cayman Islands Monetary Authority, and the Cayman Islands
                Ombudsman. Nothing in these Terms and Conditions will affect
                these legal rights.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            9. User Representations
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                9.1. Your Representations and Warranties. By using the Site, you
                represent and warrant each of the following (in addition to any
                other representations or warranties made by you in these Terms
                and Conditions):
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    a. Any registration information you submit is and will be
                    true, accurate, current, and complete;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    b. You will maintain the accuracy of your information and
                    promptly update your registration if your information
                    changes or as otherwise necessary;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    c. You have the legal capacity, and agree, to comply with
                    these Terms and Conditions, including that you are at least
                    18 years old and will not access the Site through automated
                    or non-human means, whether through a bot, script, or
                    otherwise;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    d. You will not use the Site for any illegal or unauthorised
                    purpose; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    e. Your use of the Site will not violate any applicable law
                    or regulation.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                9.2. Misrepresentations. If you provide any information that is
                untrue, inaccurate, not current, incomplete, or misleading, we
                have the right to suspend or terminate your account and refuse
                any and all current or future access to and use of the Site (or
                any part of the Site).
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            10. Indemnification
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                10.1. Our Indemnity. You agree to defend, indemnify, and hold
                harmless us, our subsidiaries, affiliates, and all our
                respective officers, agents, partners, and employees, from and
                against any loss, damage, liability, claim, or demand, including
                for reasonable attorneys’ fees, made by third party arising from
                any of the following:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    a. Your use of the Site, and any unauthorised use of any of
                    your accounts or credentials to access and use the Site;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    b. Your breach of these Terms and Conditions or your
                    representations and warranties in these Terms and
                    Conditions.
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    c. Your violation of the rights of any third party,
                    including but not limited to intellectual property rights;
                    or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    d. Any overt harmful act toward any other user of the Site.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                10.2. Defence of Our Indemnity. We reserve the right, at your
                expense, to assume the exclusive defence and control of any
                matter for which you indemnify us under these Terms and
                Conditions, and you agree to fully cooperate, at your expense,
                with our defence of any related claims. We will use reasonable
                efforts to notify you of any such claim, action or proceeding
                which is subject to this indemnification upon becoming aware of
                it. Our indemnity under this Section 10 is in addition to, and
                not in lieu of, any other indemnities set forth in any other
                written agreement between us and you.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            11. Term and Termination
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                11.1. Your Right to Not Use the Site. These Terms and Conditions
                will remain in full force and effect when you use any part of
                the or maintain any account through the Site. You may terminate
                your use or participation at any time, for any reason, by
                directing yourself away from the Site or following the
                instructions for terminating your account in your account
                settings on the Site, if available, or by contacting us at
                legal@OwnX.foundation. You can choose to voluntarily terminate
                your account with us by providing us with written notice, and,
                in doing so, you accept the following conditions:
              </span>
            </p>
            <ul style={{ color: "initial" }}>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    a. You are responsible for removing your digital assets from
                    the account prior to close;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    b. Any digital assets or other items or information you
                    leave in a closed account may not be able to be retrieved,
                    and we disclaim any corresponding liability to you; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "none",
                  
                  color: "rgb(255, 255, 255)",
                }}
              >
                <p>
                      <span   className="clr-neutral-80 animate-line-3d">
                    c. Your chosen user credentials will be locked, and you will
                    not be able to use them again to create a new account.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                11.2. Our Right to Restrict Access. Without limiting any other
                provision of these Terms and Conditions, we reserve the right,
                in our sole and absolute discretion and without notice or
                liability, to deny access to and use of the Site (including
                blocking certain IP addresses), to any person and for any
                reason, including but not limited to your breach of any
                representation, warranty, or covenant contained in these Terms
                and Conditions or violation of any applicable law or regulation.
                If we determine, in our sole discretion, that your use of the
                Site in any way breaches these Terms and Conditions, violates
                any applicable law or regulation, poses a security risk to our
                Site or any third party, or potentially subjects us or our
                affiliates to any third-party liability, then we may terminate
                your use of or participation with the Site or delete any of your
                accounts, User Content, or other information, without warning
                and at our sole discretion. If we terminate or suspend your
                account for any reason, you are prohibited from registering and
                creating a new account under your name, a fake or a borrowed
                name, or the name of any third party, even if you may be acting
                on behalf of your principal or other third party.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                11.3. Our Right to Seek Redress for your Breach. If we determine
                that your use of the Site in any way breaches these Terms and
                Conditions, violates any applicable law or regulation, poses a
                security risk to our Site or any third party, or potentially
                subjects us or our affiliates to any third-party liability, then
                we also reserve the right to take appropriate legal action
                against you, including but not limited to pursuing civil,
                criminal, and injunctive redress, if and as applicable. We may
                investigate and prosecute violations of these Terms and
                Conditions to the fullest extent permitted by law. We may also
                notify and cooperate with law enforcement or other competent
                authorities in prosecuting violations of law or these Terms and
                Conditions.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                11.4. Survival. Upon any termination of these Terms and
                Conditions under this Section 11, all provisions of these Terms
                and Conditions and its incorporated documents that by their
                nature should survive termination (including but not limited to
                Section 4 and Section 8 through 13) will survive, remaining in
                full force and effect.
              </span>
            </p>
          </li>
        </ul>
        <h5>
          <span style={{ color: "rgb(255, 255, 255)", }}>
            12. Miscellaneous
          </span>
        </h5>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.1. Your consent to Electronic Communications. Visiting any
                component of the Site, sending us emails, and completing online
                forms are all considered forms of electronic communication. You
                consent to receiving electronically all notices, disclosures,
                agreements, and other communications from us concerning your use
                of the Site and your rights and obligations under these Terms
                and Conditions, and you agree that any means of electronic
                communication satisfies any legal requirement that our
                communication to you be in writing. To the extent permitted by
                law, you waive any rights or requirements under any statutes,
                regulations, rules, ordinances, or other laws in any
                jurisdiction that may require an original signature or delivery
                or retention of non-electronic records, or for delivery of
                payments or electronic credits.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.2. No Waiver. Our failure to exercise or enforce any right or
                provision of these Terms and Conditions shall not operate as a
                waiver of such right or provision.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span className="clr-neutral-80 animate-line-3d">
            <br />
          </span>
        </p>
        <p>
          <span className="clr-neutral-80 animate-line-3d">
            12.3. Our Right to Assignment. We may assign any or all our rights
            and obligations under these Terms and Conditions to any other person
            or persons at any time.
          </span>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.4. Severability. If any provision of these Terms and
                Conditions is deemed unlawful, void, or unenforceable by a court
                or tribunal of competent jurisdiction, then that provision is
                deemed severable from these Terms and Conditions and does not
                affect the validity and enforceability of the remaining
                provisions.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.5. No Partnership. There is no joint venture, partnership,
                employment, or agency relationship created between us and you as
                a result of these Terms and Conditions or your use of any part
                of the Site.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.6. Choice of Law. These Terms and Conditions, their subject
                matter, and their formation (and any related non-contractual
                disputes or claims) are governed by the laws of the Cayman
                Islands. You and we both agree, subject to Section 13, to the
                exclusive jurisdiction of the courts of the Cayman Islands.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.7. Enforcing Your Rights or Providing Notice. A person who is
                not a party to these Terms and Conditions will have no right to
                enforce any of these Terms and Conditions against us. To resolve
                a complaint regarding your use of the Site, or to receive
                further information regarding its use, please contact us using
                the contact details provided in clause 1.3.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <br />
          </li>
          <li
            style={{
              listStyleType: "none",
              
              color: "rgb(255, 255, 255)",
            }}
          >
            <p>
                  <span   className="clr-neutral-80 animate-line-3d">
                12.8. Interpretation of Headings and Text. Section and clause
                headings used in these Terms and Conditions, and as they may be
                used in any other documents incorporated as part of these Terms
                and Conditions, are for ease of reference only. Headings are not
                binding and should not be interpreted together with the
                substantive provisions of these Terms and Conditions or any
                incorporated documents. Additionally, where the text of any
                provisions of or documents incorporated as part of these Terms
                and Conditions is bolded, you understand and agree that this
                text provides you with conspicuous notice and satisfies any
                legal requirement that notices, disclosures, or provisions be
                written in all capital letters.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: "none", fontSize: "11pt" }}>
            <br />
          </li>
        </ul>
      </div>
        </section>
        <Footer/>
    </div>
  );
}
