import React from "react";

export default function Developershero() {
  return (
    <div>
    <div className="hero-4 bg-grad-6">
  <div className="py-3">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">
            {/* <span className="d-inline-block py-1 px-2 rounded bg-white on-secondary-fixed-variant ff-1 fs-12 fw-bold flex-shrink-0"> New Featured </span> */}
            {/* <span className="d-block text-center text-md-start ff-1 fs-12 fw-semibold clr-white"> Chat with The Cloud Whitepaper Index - Langchain, Pinecone &amp; ChatGPT powered Q&amp;A </span> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="hero-4__content bg-black rounded-top-5 section-space-top">
    <div className="section-space-top section-space-sm-bottom">
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <h2 className="mb-6 text-center clr-white fw-extrabold animate-line-3d">
                <span className="d-inline-block gradient-flow gradient-flow--start">Build   </span> dApps On OwnXChain 
              </h2>
              <p className="mb-0 text-center fw-extrabold ff-1 fs-18 clr-white animate-line-3d">
             Start building decentralized applications (dApps) on OwnXChain with easily available resources & developer tools. 
              </p>
              <div className="d-flex justify-content-center">  <a href="https://ownx.gitbook.io/ownxchain-documentation/resources/getting-started" target="_blank" className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 mt-4 py-2 rounded fw-bold text-center"> Get Started </a>
</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-4">
          <div className="col-12">
            <div className="top-shadow section-space-sm-y rounded-4 bg-black position-relative z-1 overflow-hidden fadeIn_bottom">
              <div className="row g-4 align-items-center">
                <div className="col-md-6 col-xl-5">
                  <div className="px-4 px-lg-6 px-xl-15">
                    <h4 className="mb-6 clr-neutral-90"> Experience smooth development process with tailored methods, functions, & libraries</h4>
                    <p className="mb-10 clr-neutral-80 fs-14">OwnXChain offers a minimal development environment for launching blockchain-based applications. </p>
                   
                  </div>
                </div>
                <div className="col-md-6 col-xl-7">
                  <div className="text-center">
                    <img src="assets/img-1/experience_smooth_development.png" alt="image" className="img-fluid" />
                  </div>
                </div>
              </div>
              <img src="assets/img-1/dev-bg.png" alt="image" className="w-100 h-100 position-absolute inset-0 z-n1 pointer-none" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
}
