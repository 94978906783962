import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Stakinghero from './Stakinghero'
import Stakeyour from './Stakeyour'
import Stakingbenefits from './Stakingbenefits'
import Stakeon from './Stakeon'
import StakingWalle from './StakingWalle'

export default function Staking() {
  return (
    <div>
        <Header/>
        <Stakinghero/>
        <Stakeyour/>
        <Stakingbenefits/>
        <Stakeon/>
        <StakingWalle/>
        <Footer/>
    </div>
  )
}
