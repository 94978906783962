import React from "react";

export default function Dapp() {
  return (
    <div>
      <div className="featured-section section-space-top section-space-sm-bottom">  
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-4">
              <div className="feature-sidebar-list" id="featured-block-list">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="aiImgGenerateTab"
                  >
                    <div
                      className="accordion custom-accordion custom-accordion--faq mb-8"
                      id="faqAccordionOne"
                    >
                      <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button fs-20 fw-bold"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqAccordion1"
                            aria-expanded="true"
                          >
                            {" "}
                            How does the Type tone AI app work / Where do I
                            start?{" "}
                          </button>
                        </h2>
                        <div
                          id="faqAccordion1"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#faqAccordionOne"
                        >
                          <div className="accordion-body">
                            <ul className="list gap-4 mb-8">
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault2"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault3"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault3"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault4"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault4"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button fs-20 fw-bold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqAccordion2"
                            aria-expanded="false"
                          >
                            {" "}
                            Do you have languages other than English and Dutch?{" "}
                          </button>
                        </h2>
                        <div
                          id="faqAccordion2"
                          className="accordion-collapse collapse"
                          data-bs-parent="#faqAccordionOne"
                        >
                          <div className="accordion-body">
                            <ul className="list gap-4 mb-8">
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault5"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault5"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault6"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault6"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault7"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault7"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault8"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault8"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-bottom-0 border-start-0 border-end-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button fs-20 fw-bold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqAccordion3"
                            aria-expanded="false"
                          >
                            {" "}
                            What paid subscription would you recommend to me?{" "}
                          </button>
                        </h2>
                        <div
                          id="faqAccordion3"
                          className="accordion-collapse collapse"
                          data-bs-parent="#faqAccordionOne"
                        >
                          <div className="accordion-body">
                            <ul className="list gap-4 mb-8">
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault9"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault9"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault11"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault11"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input "
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault12"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault12"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault13"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault13"
                                  >
                                    Default radio
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                data-bs-spy="scroll"
                data-bs-target="#featured-block-list"
                data-bs-smooth-scroll="true"
                className="scrollspy-example"
                tabIndex={0}
              >
                <div className="featured-block" id="featured-block-1"></div>
                {/* featured-block end */}
                <div className="row">
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>

                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-6 col-xl-6 my-2">
                    <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
                      <div className="clr-neutral-50 fs-32">
                        <img src="assets/img/use-case-icon-1.png" alt="image" />
                      </div>
                      <h4 className="clr-neutral-90 fs-24 mt-10 mb-4">
                        Blog Writing
                      </h4>
                      <p className="clr-neutral-80 mb-0 fs-16">
                        AI can generate written content, including articles blog
                        posts descriptions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
