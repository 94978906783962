import React from "react";

export default function Walletmain() {
  return (
    <div>
      <div className="section-space-sm-y position-relative z-1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <div
                className="text-center fadeIn_bottom"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <img
                  src="assets/img-1/hundreds_of_dapps.png"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h3
                className="mb-6 fw-extrabold text-white animate-line-3d"
                style={{ perspective: "400px" }}
              >
                {" "}
                Hundreds of dApps, <br />
                <span className="clr-grad-4">One Wallet</span>
              </h3>
              <p className="mb-8 animate-text-from-right text-white">
                OwnX Wallet can be used to access hundreds of decentralized
                applications (dApps) within and beyond the OwnXChain ecosystem.
              </p>

              <div
                className="fadeIn_bottom"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              ></div>
            </div>
          </div>
        </div>
        <img
          src="assets/img-1/about-section-3-shape-3.png"
          alt="image"
          className="img-fluid position-absolute z-n1 position-center-y start-0"
          style={{opacity:"50%"}}
        />
      </div>
    </div>
  );
}
