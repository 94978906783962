import React from "react";

export default function Hero() {
  return (
    <>
      <div className="trigger-showcase position-relative z-1">
        {/* Hero Section Shapes */}
        <div className="hero-1-shapes">
          <img
            src="assets/img-1/hero-shape-left01.png"
            alt="image"
            className="img-fluid hero-1-shapes__img hero-1-shapes__img--1"
          />
          <img
            src="assets/img-1/hero-shape-right01.png"
            alt="image"
            className="img-fluid hero-1-shapes__img hero-1-shapes__img--2"
          />
          <img
            src="assets/img/hero-shape-ring-1.png"
            alt="image"
            className="img-fluid hero-1-shapes__img hero-1-shapes__img--3"
          />
          <img
            src="assets/img/hero-shape-ring-2.png"
            alt="image"
            className="img-fluid hero-1-shapes__img hero-1-shapes__img--4"
          />
        </div>
        {/* /Hero Section Shapes */}
        {/* Hero 1 */}
        <section className="hero-1">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-9 col-xxl-10 text-center">
                <h1 className="clr-white fw-extrabold mb-6 animate-line-3d">
                  The Layer 1 Modular
                  <span className="clr-grad-1"> Blockchain Network</span>
                </h1>
                <p className="fs-18 fw-bold clr-neutral-80 max-text-50 mx-auto mb-10 animate-text-from-bottom">
                  {" "}
                  OwnX is a decentralized blockchain network that serves an
                  application-specific infrastructure.{" "}
                </p>
                <div className=" d-inline-block w-100 max-text-60 mx-auto mb-4 position-relative z-1 overflow-hidden rounded fadeIn_bottom">
                  <div className="d-flex p-3 justify-content-around">
                    <a
                      href="https://ownx.gitbook.io/ownxchain-documentation"
                      target="_blank"
                      class="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                      Developer Tools{" "}
                    </a>
                    <a
                      href="https://ownx.gitbook.io/ownxchain-whitepaper"
                      target="_blank"
                      className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"
                    >
                      {" "}
                      Whitepaper{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/img-1/round_bg.png"
            alt="image"
            style={{ opacity: "0.5" }}
            className="img-fluid hero-1-shapes__img ani-shap hero-1-shapes__img--5"
          />
          <img
            src="assets/img-1/round_bg1.png"
            alt="image"
            className="img-fluid hero-1-shapes__img ani-shap hero-1-shapes__img--6"
          />
        </section>
        {/* /Hero 1 */}
        {/* Generated Image Showcase */}
        {/* /Generated Image Showcase */}
        <div className="breadcrumb-section-inner">
          <div className="container">
            {/* <div className="row justify-content-center">
                            <div className="col-xxl-7 col-xl-8">
                                <div className="text-center">
                                    <div className="d-inline-flex align-items-center py-2 px-4 bg-info-10 bg-opacity-3 rounded-1">
                                        <span className="fs-12 clr-white">WriteBot AI</span>
                                    </div>
                                    <h2 className="h3 fw-bold clr-neutral-90 mt-4">WriteBot Subscription Plans </h2>
                                </div>
                            </div>
                        </div> */}
            {/* <div className="pricing- mt-10 d-flex justify-content-center">
                            <ul className="pricing-5-list list list-sm-row align-items-center gap-3 fadeIn_bottom">
                                <li className="pricing-btn-5 active">
                                    <button type="button" className="link d-inline-flex py-2 px-3 fs-12 fw-bold clr-neutral-50 border-0 bg-transparent" id="selectMonthly">Monthly</button>
                                </li>
                                <li className="pricing-btn-5">
                                    <button type="button" className="link d-inline-flex py-2 px-3 fs-12 fw-bold clr-neutral-50 border-0 bg-transparent" id="selectYearly">Yearly</button>
                                </li>
                                <li className="pricing-btn-5">
                                    <button type="button" className="link d-inline-flex py-2 px-3 fs-12 fw-bold clr-neutral-50 border-0 bg-transparent" id="selectLifetime">Lieftime</button>
                                </li>
                                <li className="pricing-btn-5">
                                    <button type="button" className="link d-inline-flex py-2 px-3 fs-12 fw-bold clr-neutral-50 border-0 bg-transparent" id="selectPrepaid">Prepaid</button>
                                </li>
                                <li className="pricing-btn-5">
                                    <span className="d-inline-flex py-2 px-3 fs-12 fw-bold clr-white bg-primary-key rounded-1">Yearly 20% off</span>
                                </li>
                            </ul>
                        </div> */}
            <div className="mt-10">
              <div className="row gy-4">
                <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
                  <div className="pricing-5-card text-center">
                    <span className="pricing-5-card-name fs-16 fw-bold clr-neutral-90">
                      Utterly Fast{" "}
                    </span>
                    <p className="fs-14 clr-neutral-80 mt-3">
                      Capable of processing 5k transactions per second
                    </p>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <h3 className="pricing-5-card-price fw-extrabold mb-0 clr-neutral-90">
                        5,000+ TP
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
                  <div className="pricing-5-card popular text-center">
                    <span className="pricing-5-card-name fs-16 fw-bold clr-neutral-90">
                      Highly Scalable
                    </span>
                    <p className="fs-14 clr-neutral-80 mt-3">
                      Capacity of onboarding 16,500 Nodes effortlessly
                    </p>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <h3 className="pricing-5-card-price fw-extrabold mb-0 clr-neutral-90">
                        16k+ Node Spots
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xxl-4 fadein_bottom_39">
                  <div className="pricing-5-card text-center">
                    <span className="pricing-5-card-name fs-16 fw-bold clr-neutral-90">
                      Cost-efficient
                    </span>
                    <p className="fs-14 clr-neutral-80 mt-3">
                      It cost far less-than a cent for sending transactions
                    </p>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <h3 className="pricing-5-card-price fw-extrabold mb-0 clr-neutral-90">
                        ~$0.0001 Fee
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-space-sm-bottom section-space-sm-top">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-xl-8">
                        <h6 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d">
                          Discover block data &amp; network insights on
                          <a href="/coming-soon" className="clr-grad-1">
                            {" "}
                            OwnXChain explorer →{" "}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
