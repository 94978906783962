import React from 'react'
import Wallethero from './Wallethero'
import Walletmain from './Walletmain'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Mobilewallet from './Mobilewallet'
import Webwalletnew from './Webwalletnew'
import Hardwallet from './Hardwallet'

export default function Wallet() {
  return (
    <div>
      <Header/>
      <Wallethero/>
      <Walletmain/>
      <Webwalletnew/>
      {/* <Mobilewallet/> */}
      {/* <Hardwallet/> */}
      <Footer/>
    </div>
  )
}
