import React from "react";

export default function Stakeon() {
  return (
    <div>
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container my-10 mt-20">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">
                        Stake On Our Novel Proof <br /> -of-Alliance
                        <span className="clr-grad-4"> (PoA) Consensus </span>
                      </h3>
                      <p className="clr-neutral-80 mb-6">
                        OwnXChain uses Proof -of-Alliance (PoA) consensus
                        mechanism where every Validator is backed by a group of
                        Delegators. This duo secures blockchain consensus by
                        substantially staking & delegating their assets.
                      </p>
                      <div className="d-flex p-3 ">
                        <a
                          href="/proof-of-alliance"
                          class="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 py-3 rounded fw-bold text-center"
                        >
                          {" "}
                          {" "}
                          Read more
                          <span className="d-inline-block mx-1">
                            <i class="bi bi-arrow-up-right"></i>
                          </span>
                        </a>
                       
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/stake_on_our_novel.png"
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                      {/* <span className="blog-latest-label"> Latest News </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
