import React from 'react'

export default function FTMTokenhero() {
  return (
    <div>
  <div className="single-blog-area section-space-sm-top section-space-sm-bottom ">
        <div className="container section-space-top">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h3 class="h3 mb-9 clr-white fw-extrabold text-center">
                      OWNX <span className='clr-grad-4'> Token</span> 
                      </h3>
                      <p className="clr-neutral-80 mb-6 d-flex justify-content-center">
                      OwnXChain uses the native token OWNX for transaction gas, <br /> networking security (staking) & onchain governance. 
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/ownx_token.png"
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                      {/* <span className="blog-latest-label"> Latest News </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}
