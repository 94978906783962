import React from 'react'

export default function GamesSoon() {
  return (
    <div>
          <div className="blog-section-1 section-space-y bg-neutral-4">
        <div className="section-space-sm-bottom">
          <div className="container">
            <div className="row g-4 ">
              
              <div className="col-12">
                <h3 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d "> Games  </h3>
                <p className=''><span className="clr-grad-1 fs-3 "> (Coming Soon)</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13"style={{height:"400px"}}>
                <div className="d-flex align-items-center gap-2">
                  {/* <div className="w-12 h-2px bg-primary-key" /> */}
                  {/* <span className="d-block fw-bold clr-primary-key"> Build  </span> */}
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/super_soldier.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a className="link d-block clr-neutral-90 text-center mt-5">Super Soldie</a>
                  </h6>
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13"style={{height:"400px"}}>
                <div className="d-flex align-items-center gap-2">
                  {/* <div className="w-12 h-2px bg-primary-key" /> */}
                  {/* <span className="d-block fw-bold clr-primary-key"> Build  </span> */}
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/league_of_kingdoms.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a className="link d-block clr-neutral-90 text-center mt-5"> League of Kingdoms</a>
                  </h6>
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13"style={{height:"400px"}}>
                <div className="d-flex align-items-center gap-2">
                  {/* <div className="w-12 h-2px bg-primary-key" /> */}
                  {/* <span className="d-block fw-bold clr-primary-key"> Build  </span> */}
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/swords_wars.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a  className="link d-block clr-neutral-90 text-center mt-5">Swords Wars</a>
                  </h6>
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="blog-card-1 top-shadow px-6 py-10 position-relative z-1 overflow-hidden rounded-3 bg-neutral-10 bg-opacity-6 fadein_bottom_13" style={{height:"400px"}}>
                <div className="d-flex align-items-center gap-2">
                  {/* <div className="w-12 h-2px bg-primary-key" /> */}
                  {/* <span className="d-block fw-bold clr-primary-key"> Build  </span> */}
                </div>
                <div className="blog-card-1__img">
                  <img src="assets/img-1/fumble_guys.png" alt="image" className="img-fluid h-100 object-fit-cover" />
                </div>
                <div className="blog-card-1__body">
                  <h6 className="mb-6 fs-24">
                    <a href="blog-details.html" className="link d-block clr-neutral-90 text-center mt-5"> Fumble Guys</a>
                  </h6>
                
                </div>
              </div>
            </div>
          </div>
        </div>
            <img src="assets/img-1/blog-section-1-img-1.png" alt="image" className="img-fluid blog-section-1__img blog-section-1__img--1" />
        <img src="assets/img/blog-section-1-img-2.webp" alt="image" className="img-fluid blog-section-1__img blog-section-1__img--2" />
      </div>

    </div>
  )
}
