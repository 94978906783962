import React from 'react'

export default function Client() {
    return (
        <div className="section-space-sm-y">
            <div className="section-space-sm-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-xl-8">
                            <h4 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d">
                                <span className="clr-grad-1"> Partners </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-12">
                        <div className="scroller-x mb-4" data-direction="right" data-speed="slow">
                            <ul className="list list-row gap-4 scroller-x__list">
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/yahoo.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/cmc.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_gecko.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_tracking.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/be_in_crypto.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/dropstab.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/market_watch.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/blockworks.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/metamask.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/trust_wallet.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/crypto_potato.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_republic.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/crypto_news.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/blockchain_com.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/yahoo.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/cmc.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_gecko.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_tracking.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/be_in_crypto.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/dropstab.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                              
                            </ul>
                        </div>
                        <div className="scroller-x" data-direction="left" data-speed="slow">
                            <ul className="list list-row gap-4 scroller-x__list">
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/cmc.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_gecko.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_tracking.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/be_in_crypto.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/dropstab.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/market_watch.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/blockworks.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/metamask.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/trust_wallet.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/crypto_potato.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_republic.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/crypto_news.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/blockchain_com.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/blockchain_com.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/yahoo.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/cmc.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_gecko.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/coin_tracking.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/be_in_crypto.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                                <li>
                                    <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                                        <img src="assets/img-1/dropstab.png" alt="image" className="img-fluid" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
