import React from "react";

export default function Aboutour() {
  return (
    <div>
      <div className="section-space-sm-y">
        <div className="section-space-sm-bottom">
          <div className="container">
           
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <h4 class=" clr-neutral-90 fw-extrabold animate-line-3d"> Mission{" "}<span class="d-inline-block clr-grad-4 ff-4 "></span> </h4>
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/img-1/mission.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="100px"
                  />
                </div>
                <div className="mt-auto">
                  
                  <p className="clr-neutral-80 animate-line-3d">
                    The mission of OwnXChain is to revolutionize the blockchain
                    landscape by fostering diversity and accessibility. It
                    ultimately drives widespread adoption among the masses by
                    proposing a novel blockchain design. OwnXChain is dedicated
                    to pioneering innovative solutions that empower individuals
                    and businesses to harness the full potential of blockchain
                    technology, ensuring inclusivity, transparency, and
                    opportunity for all.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <h4 class=" clr-neutral-90 fw-extrabold animate-line-3d">   Vision{" "}{" "}<span class="d-inline-block clr-grad-4 ff-4 "></span> </h4>

              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                      src="assets/img-1/vision.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="100px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                   
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    Our vision at OwnXChain is to create a future where
                    blockchain technology is seamlessly integrated into everyday
                    life, enriching experiences, fostering trust, and empowering
                    individuals worldwide. Through our commitment to diversity,
                    inclusivity, and technological advancement, we strive to be
                    the catalyst for a decentralized ecosystem that
                    revolutionizes industries, drives economic growth, and
                    empowers global communities to thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4 my-5 mt-20">
            <h3 className="mb-0 text-center clr-neutral-90 fw-extrabold animate-line-3d">
            Values
              <span className="d-inline-block clr-grad-4 ff-4 ">
           
              </span>{" "}
           
            </h3>

            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/icon-1/innovation.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Innovation{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    OwnX constantly seeking new ways to push the boundaries of
                    blockchain technology and drive positive change in the
                    world.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/icon-1/inclusivity.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Inclusivity{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    OwnX believes in creating a diverse and inclusive ecosystem
                    where everyone has the opportunity to participate and
                    contribute, regardless of background or experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/img-1/transparencya.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Transparency{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    OwnX prioritizes transparency in all our operations,
                    ensuring openness, honesty, and accountability in our
                    interactions with stakeholders.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/img-1/empowerment.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Empowerment{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    OwnX is committed to empowering individuals and communities
                    by providing them with the tools, resources, and knowledge
                    they need to succeed in the blockchain space.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/img-1/collaboration.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Collaboration{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    {" "}
                    OwnX recognizes the power of collaboration and values
                    partnerships with like-minded organizations and individuals
                    who share our vision and values.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="bg-neutral-10 border border-neutral-17 rounded-4 p-4 p-md-8 h-100 fadeIn_bottom">
                <div className="rounded-4 overflow-hidden mb-7 fadeIn_bottom">
                  <img
                    src="assets/img-1/decentralization.png"
                    alt="image"
                    className="img-fluid  object-fit-cover"
                    width="50px"
                  />
                </div>
                <div className="mt-auto">
                  <div className="d-flex align-items-center gap-3 mb-2 fadeIn_bottom">
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-subtitle.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </span>
                    <span className="d-inline-block clr-primary-2 ff-1 fw-bold fs-18">
                      {" "}
                      Decentralization{" "}
                    </span>
                  </div>
                  <p className="clr-neutral-80 animate-line-3d">
                    {" "}
                    OwnX strives to make blockchain technology accessible to
                    everyone, removing barriers to entry and democratizing
                    access to opportunities for financial inclusion and
                    empowerment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
