import React from 'react'

export default function Blogopnehero() {
  return (
    <div>
              <div
        className="single-blog-area section-space-top section-space-sm-bottom"
        style={{ backgroundColor: "rgb(19, 19, 19)" }}
      >
        <div className="container">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="blog-latest-content">
                      <h4 className="h4 mb-6 fw-extrabold text-r">
                        <span className="link clr-neutral-90  ">
                          {" "}
                          What is OwnXChain?
                        </span>
                      </h4>
                   
                      <ul className="list list-row gap-4 flex-wrap mb-6 ">
  <li className="fadein_bottom_28" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <a href="#" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
      <i className="bi bi-twitter" />
    </a>
  </li>
  <li className="fadein_bottom_28" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <a href="#" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
      <i className="bi bi-behance" />
    </a>
  </li>
  <li className="fadein_bottom_28" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <a href="#" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
      <i className="bi bi-dribbble" />
    </a>
  </li>
  <li className="fadein_bottom_28" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
    <a href="#" className="link w-10 h-10 d-grid place-content-center :gradient-btn-3 position-relative z-1 rounded-3">
      <i className="bi bi-facebook" />
    </a>
  </li>
</ul>
<p className="clr-neutral-80 mb-6 ">
OwnXChain is a decentralized application platform that operates <br /> on  the distributed ledger technology (DLT). 
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <img src="assets/img-1/what_is_ownx_chain.png" alt="image" style={{height:"200px"}} class="img-fluid "/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
