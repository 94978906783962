import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import About from './About'
import Client from './Client'
import Product from './Product'
import WhatWeDo from './WhatWeDo'
import FAQ from './FAQ'
import Blog from './Blog'
import StartUsing from './StartUsing'
import Global from './Global'

export default function Index() {
  return (
    <>
      <div className="bg-black header-crossed">
        <Header />
        <Hero />
        <About />
        <Product />
        <WhatWeDo />
        <StartUsing />
        <Global/>
        <Blog />
        
        <Client />
        <FAQ />
        <Footer />
      </div>
    </>
  )
}
