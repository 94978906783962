import React from 'react'

export default function Researchhero() {
  return (
    <div>
        <div className="container  section-space-top">
  <div className="row justify-content-center">
    <div className="col-xxl-8 col-xl-8 text-center">

      <h2 className="h3 fw-bold clr-neutral-90 mt-4 max-text-32 ms-auto me-auto">OwnXChain Research</h2>
      <p className='clr-neutral-80 mb-0 fs-5'>Find out all the theoretically researched & explained resources you need to get started with OwnXChain. </p>
    </div>
  </div>
</div>

    </div>
  )
}
