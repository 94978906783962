import React from "react";

export default function Ecosystemtab() {
  return (
    <div>
      <div className="container">
        <div className="row mt-20">
          <div className="col-12">
            <ul
              className="nav nav-underline nav-bordered use-case-tab d-flex  "
              style={{ flexWrap: "wrap" }}
            >
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link active"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#aiSalesEnable"
                >
                  (10) All{" "}
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#learningDevelopment"
                >
                  {" "}
                  (4) Blockchain{" "}
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#customerService"
                >
                  (3) Defi
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#informationSecurity"
                >
                  (1) NFT{" "}
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#merketingTeam"
                >
                  (1) Community{" "}
                </button>
              </li>
              <li className="nav-item animate-text-from-right">
                <button
                  className="nav-link"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#Wallet"
                >
                  (1) Other{" "}
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="aiSalesEnable"
                role="tabpanel"
                aria-labelledby="aiSalesEnable"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-explorer.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Explorer
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/testnet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Testnet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/faucet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Faucet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-wallet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5"
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Wallet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-dex.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          OwnX DEX
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4 ">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/yield.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          OwnYield
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-1launchpad.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnX Launchpad
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-marketplace.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          NFT Marketplace
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-dao.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnDAO
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                      <img
                          src="assets/img-1/e-browse.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnX Browse
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="learningDevelopment"
                role="tabpanel"
                aria-labelledby="learningDevelopment"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-explorer.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Explorer
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                           <img
                          src="assets/img-1/testnet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Testnet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/faucet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Faucet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-wallet.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5"
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          Wallet
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="customerService"
                role="tabpanel"
                aria-labelledby="customerService"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                         <img
                          src="assets/img-1/e-dex.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          OwnX DEX
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/yield.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          OwnYield
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-1launchpad.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnX Launchpad
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="informationSecurity"
                role="tabpanel"
                aria-labelledby="informationSecurity"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-marketplace.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5"
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          NFT Marketplace
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="merketingTeam"
                role="tabpanel"
                aria-labelledby="merketingTeam"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                         <img
                          src="assets/img-1/e-dao.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnDAO
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="Wallet"
                role="tabpanel"
                aria-labelledby="Wallet"
                tabIndex={0}
              >
                <div className="pt-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 gy-4">
                      <div
                        className="ai-tools-card text-center overflow-hidden py-10 px-8 h-100 fadeIn_bottom"
                        data-tilt
                        data-tilt-max={0}
                        data-tilt-glare
                        data-tilt-max-glare="0.15"
                        style={{
                          willChange: "transform",
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform:
                            "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                          opacity: 1,
                        }}
                      >
                        <img
                          src="assets/img-1/e-browse.png"
                          alt="image"
                          className="img-fluid mt-2 mb-5 "
                        />
                        <h4 className="clr-neutral-90 fs-18 fw-semibold">
                          {" "}
                          OwnX Browse
                        </h4>
                        <img
                          src="assets/img/pricing-5-card-line.png"
                          alt="image"
                          className="img-fluid ai-tools-card-shape"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
