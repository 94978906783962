import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Researchhero from './Researchhero'
import Researchmain from './Researchmain'

export default function Research() {
  return (
    <div>
        <Header/>
        <Researchhero/>
        <Researchmain/>
        <Footer/>
    </div>
  )
}
