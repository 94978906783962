import React from 'react'

export default function Partnersmain() {
  return (
    <div>
      <div className="section-space-sm-y">
  <div className="section-space-sm-bottom">
  <div className="container p-0">
    <div className="row g-0">
      <div className="col-12">
      <h4 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d mb-2"> Wallet   Partners<span className="clr-grad-1"></span>
          </h4>
        <div className="scroller-x mb-4" data-direction="right" data-speed="fast">
          <ul className="list list-row gap-4 scroller-x__list">
          <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/phantom.png" alt="image" className="img-fluid" />
              </div>
            </li>
            
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/tokenpocket.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trezor.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trustwallet.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/exodus.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/ledger.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/metamask.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/phantom.png" alt="image" className="img-fluid" />
              </div>
            </li>
            
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/tokenpocket.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trezor.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trustwallet.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/exodus.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/ledger.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/metamask.png" alt="image" className="img-fluid" />
              </div>
            </li>
            
          </ul>
        </div>
        <h4 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d mb-2 mt-10"> Featured Crypto Exchanges <span className="clr-grad-1"></span>
          </h4>
        <div className="scroller-x" data-direction="left" data-speed="fast">
          <ul className="list list-row gap-4 scroller-x__list">
          <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/cake.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/gate_io.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/mexc.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/whitebit.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/xt_com.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/bingx.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/bitmax.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/bithumb.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/cake.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/gate_io.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/mexc.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/whitebit.png" alt="image" className="img-fluid" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-12">
      <h4 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d mb-2 mt-10"> Market Aggregators <span className="clr-grad-1"></span>
          </h4>
        <div className="scroller-x mb-4" data-direction="right" data-speed="fast">
          <ul className="list list-row gap-4 scroller-x__list">
          <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/cmc.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/blockfolio.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trading_view.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/dropstab.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coinlib.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coingecko.png" alt="image" className="img-fluid" />
              </div>
            </li>
          <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coin_ranking.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coin_paprika.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/cmc.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/blockfolio.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/trading_view.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/dropstab.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coinlib.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coingecko.png" alt="image" className="img-fluid" />
              </div>
            </li>
          </ul>
        </div>
        <h4 className="mb-0 clr-neutral-90 text-center fw-extrabold animate-line-3d mb-2 mt-10"> Media Partners  <span className="clr-grad-1"></span>
          </h4>
        <div className="scroller-x" data-direction="left" data-speed="fast">
          <ul className="list list-row gap-4 scroller-x__list">
          <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/decrypt.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/yahoo.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/beincrypto.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/benzinga.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coinmonk.png" alt="image" className="img-fluid" />
              </div>
            </li>
        
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_daily.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_potato.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_slate.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/decrypt.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/yahoo.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/beincrypto.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/benzinga.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/coinmonk.png" alt="image" className="img-fluid" />
              </div>
            </li>
        
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_daily.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_potato.png" alt="image" className="img-fluid" />
              </div>
            </li>
            <li>
              <div className="brand-btn d-inline-block py-4 px-10 rounded-pill bg-neutral-10">
                <img src="assets/icon-1/crypto_slate.png" alt="image" className="img-fluid" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>
  
</div>

    </div>
  )
}
