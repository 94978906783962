import React from "react";

export default function Brandkithero() {
  return (
    <div>
      <div className="single-blog-area section-space-top section-space-sm-bottom"style={{backgroundColor:"#131313"}}>
        <div className="container">
          <div className="swiper blog-single-slider" >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h4 className="h3 mb-6 fw-extrabold text-r">
                        <span className="link clr-neutral-90 "> Brand Kit</span>
                      </h4>
                      <p className="clr-neutral-80 mb-6 ">
                        All the designs & logos are copyrighted by OwnXChain. <br />
                        Make sure to use them in appropriate ways. 
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
