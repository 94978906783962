import React from 'react'

export default function Ecosystemhero() {
  return (
    <div>
     <section className="hero-1">
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col-md-10 col-lg-9 col-xxl-10 text-center">
        <h1 className="clr-white fw-extrabold mb-6 animate-line-3d">dApps & Platforms In   <span className="clr-grad-1">OwnXChain ecosystem</span>
        </h1>
        <p className="fs-18 fw-bold clr-neutral-80 max-text-50 mx-auto mb-10 animate-text-from-bottom"> OwnXChain has numerous decentralized applications (dApps) in the ecosystem that boost cryptocurrency space </p>
        
      </div>
    </div>
  </div>
</section>


    </div>
  )
}
