import React from 'react'

export default function Stakeyour() {
  return (
    <div>
        <div className="section-space-y">
  <div className="container">
    <div className="row g-4">
    
      <div className="col-lg-7 col-xxl-8">
        <div className="row g-4">
          <div className="col-12">
            <div className="overflow-hidden rounded-4 bg-neutral-10 ">
              <div className="text-center  py-30 ">
                <h3>
                  <a  className="link d-block text-white animate-line-3d">Stake your OWNX for 365 days & earn <span className='clr-grad-4'> up to ~16% APR.</span> 
</a>
                </h3>
              </div>
           
            
            </div>
          </div>
     
        </div>
      </div>
      <div className="col-lg-5 col-xxl-4">
        <div className="row">  
           <div className="col-12 fadeIn_bottom ">
            <div className="p-4 py-md-11 px-md-9 px-lg-4 px-xl-9 rounded-4 overflow-hidden position-relative z-1 bg-neutral-10"> 
             
              <h4 className="mb-2 fw-extrabold text-white">100,000,000 </h4>
              <h5 className="mb-0 clr-grad-4"> Total OWNX Staked	 </h5>
          
            </div>
          </div>
          <div className="col-12 fadeIn_bottom mt-3">
            <div className="p-4 py-md-11 px-md-9 px-lg-4 px-xl-9 rounded-4 overflow-hidden position-relative z-1 bg-neutral-10">
              
              <h4 className="mb-2 fw-extrabold clr-primary-10 text-white">16% APR </h4>
              <h5 className="mb-0 clr-grad-4"> Current Rewards
 </h5>
            </div>
          </div></div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
