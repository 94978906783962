import React from "react";

export default function Developersjoin() {
  return (
    <div>
      <div className="container  section-space-top">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2 className="h4 fw-bold clr-neutral-90 mt-4 ms-auto me-auto">
              We encourage developers to join our ecosystem & build useful
              applications
            </h2>
          </div>
        </div>
        <div className="row gy-4 justify-content-center mt-10">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
              <div className="clr-neutral-50 mb-2 fs-32">
              <img src="assets/icon-1/grants.png" alt="image" />
              </div>

              <p className="clr-neutral-80 mb-0 fs-16">
                Are you looking to fundings? Send us demo & get a chance to win
                equity-free grants.{" "}
              </p>
              <a
                href="https://forms.gle/e8aTz6SGMmUQWhHy6"
                target="_blank"
                class="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center mt-4 px-4 py-2 rounded fw-bold text-center"
              >
                {" "}
                Grants{" "}
                <span className="d-inline-block">
                  <i className="bi bi-arrow-up-right"></i>
                </span>{" "}
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <div className="ai-template-card p-4 p-sm-6 px-xxl-10 py-xxl-12 text-center text-xl-start h-100">
              <div className="clr-neutral-50 mb-2 fs-32">
              <img src="assets/icon-1/accelerator.png" alt="image" />

              </div>

              <p className="clr-neutral-80 mb-0 fs-16">
                Apply for our Accelerator Program to lead your dApp to the
                masses with the help of our team.
              </p>
              <a
                href="/accelerator"
                class="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center mt-4 px-4 py-2 rounded fw-bold text-center"
              >
                {" "}
                Accelerator{" "}
                <span className="d-inline-block">
                  <i className="bi bi-arrow-up-right"></i>
                </span>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
