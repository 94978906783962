import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function Commingsoon() {
  return (
    <div>
      <Header />
      <div className="section-space-top section-space-bottom">
        <div className="hero-4_content bg-blck rounded-top-5 section-space-top coming-soon ">
          <div className="container  d-flex justify-content-center section-space-top section-space-bottom ">
            <div>
              <h1 class="clr-white fw-extrabold mb-10 animate-line-3d">
                <span class="clr-grad-1">Stay Tuned</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
