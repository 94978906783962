import React from "react";

export default function Communityhero() {
  return (
    <div>
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container my-10 mt-20">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4 justify-content-center">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">
                      Vibrant 
                        <span className="clr-grad-4"> Community </span>
                      </h3>
                      <p className="clr-neutral-80 mb-6">
                      OwnXChain has a vibrant community of developers, traders & crypto advocates <br /> who drive blockchain adoption further to masses.
                      </p>
                      
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/icon-1/com-hero.svg"
                          alt="image"
                          style={{width:"80%"}}
                          className="img-fluid object-fit-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
