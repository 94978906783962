import React from "react";

export default function Partnershero() {
  return (
    <div>
      <div className="single-blog-area section-space-sm-top section-space-sm-bottom">
        <div className="container my-10 mt-20">
          <div className="swiper blog-single-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center gy-4">
                  <div className="col-lg-6">
                    <div className="blog-latest-content">
                      <h3 className="h3 mb-6 fw-extrabold clr-neutral-90">
                      OwnXChain
                        <span className="clr-grad-4">  Partners </span>
                      </h3>
                      <p className="clr-neutral-80 mb-6">
                      OwnX has a wide range of partners within its ecosystem. It features crypto wallets,centralized and decentralized exchanges, media platforms, price aggregators and more. 
                      </p>
                      <div className="d-flex p-3 ">
                      <a href="/coming-soon" class="gradient-btn-1 position-relative border z-1 link d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"> Become Partner </a>
                       
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-latest-thumb">
                      <div className="d-block">
                        <img
                          src="assets/img-1/parnerhero.png" 
                          alt="image"
                          className="img-fluid w-100 object-fit-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
