import React from "react";

export default function Acceleratormain() {
  return (
    <div>
      <div className="section-space-bottom  section-space-sm-top">
        <div className="container ">
          <div className="row ">
            <div className="col-md-10 col-xl-8 my-5">
              <h5 className="mb-0 max-text-24 clr-neutral-90 fw-extrabold animate-line-3d">
                {" "}
                How Does It Work?
              </h5>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
              <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
                <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
                  <div className="mb-6 d-inline-block mx-auto">
                    <img
                      src="assets/icon-1/apply.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="clr-neutral-90 fw-extrabold"> Apply</h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                	Apply for the accelerator program & submit required details with a live demo. 

                  </p>
                </div>
                <img
                  src="assets/img/gradient-bottom-half-border.png"
                  alt="image"
                  className="img-fluid w-100 position-absolute start-0 end-0 bottom-0 z-n1"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
              <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
                <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
                  <div className="mb-6 d-inline-block mx-auto">
                    <img
                      src="assets/icon-1/review.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="clr-neutral-90 fw-extrabold"> Review</h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                    Our team will review the application to shortlist for the next round.  
                  </p>
                </div>
                <img
                  src="assets/img/gradient-top-half-border.png"
                  alt="image"
                  className="img-fluid w-100 position-absolute start-0 end-0 top-0 z-n1"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
              <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
                <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
                  <div className="mb-6 d-inline-block mx-auto">
                    <img
                      src="assets/icon-1/screening.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="clr-neutral-90 fw-extrabold"> Screening
 </h6>
                  <p className="mb-0 clr-neutral-80">
                  A screening & demo day is scheduled with the team & developers. 

                  </p>
                </div>
                <img
                  src="assets/img/gradient-bottom-half-border.png"
                  alt="image"
                  className="img-fluid w-100 position-absolute start-0 end-0 bottom-0 z-n1"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 featured-lists fadein_bottom_23">
              <div className="p-3 position-relative z-1 bg-black rounded-3 border border-dashed border-neutral-80 border-opacity-4 h-100">
                <div className="d-flex flex-column justify-content-center px-4 py-12 rounded-3 bg-neutral-10 text-center h-100">
                  <div className="mb-6 d-inline-block mx-auto">
                    <img
                      src="assets/icon-1/funds_development.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <h6 className="clr-neutral-90 fw-extrabold"> Funds & Development
 </h6>
                  <p className="mb-0 clr-neutral-80">
                    {" "}
                    Funds, strategy & acceleration will be provided after successful onboarding.

                  </p>
                </div>
                <img
                  src="assets/img/gradient-top-half-border.png"
                  alt="image"
                  className="img-fluid w-100 position-absolute start-0 end-0 top-0 z-n1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
