import React from 'react'
import Blogopnehero from './Blogopnehero'
import Blogopnemain from './Blogopnemain'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'

export default function Blogopne() {
  return (
    <div>
        <Header/>
        <Blogopnehero/>
        <Blogopnemain/>
        <Footer/>
    </div>
  )
}
