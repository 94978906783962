import React from 'react'

export default function Stakingbenefits() {
  return (
    <div>
        <div className="why-choose-ai-section section-space-sm-y">
  <div className="section-space-sm-bottom">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10 col-xl-6">
          <h3 className="mb-0 text-center fw-extrabold clr-neutral-90 animate-line-3d">Staking Benefits </h3>
          <p class="clr-neutral-80 mb-6 mt-4">Staking unlocks a variety of benefits for a blockchain network as well as for stakers. It brings enormous advantages for sustainability & security of the whole ecosystem. </p>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row g-4">
      <div className="col-12">
        <div >
          <div className="row g-4 justify-content-center">
            <div className="col-lg-4 d-xl-none">
              <div className="d-none d-lg-inline-block">
               <img src="assets/img/choose-section-1-img-2.png" alt="image" className="img-fluid" />
              </div>
            </div>
         
            <div className="col-lg-4 d-xl-none">
              <div className="d-none d-lg-inline-block">
                <img src="assets/img/choose-section-1-img-3.png" alt="image" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-xl-4">
        <div className="px-lg-6 py-lg-8 rounded-3 fadeIn_bottom">
          <div className="d-grid place-content-center w-14 h-14 bg-neutral-variant-30 bg-opacity-5 rounded mb-6">
          <img src="assets/icon-1/staking_rewards.png" alt="image" className="img-fluid" />

          </div>
          <h6 className="clr-neutral-90 mb-2 reveal-text"> Staking Rewards </h6>
          <p className="mb-0 clr-neutral-80 fs-14 fw-medium animate-text-from-right"> Earn daily staking rewards with up to 16% returns annually. </p>
        </div>
      </div>
      <div className="col-md-4 col-xl-4">
        <div className="px-lg-6 py-lg-8 rounded-3  fadeIn_bottom">
          <div className="d-grid place-content-center w-14 h-14 bg-neutral-variant-30 bg-opacity-5 rounded mb-6">
            <img src="assets/icon-1/network_security.png" alt="image" className="img-fluid" />
          </div>
          <h6 className="clr-neutral-90 mb-2 reveal-text">Network Security  </h6>
          <p className="mb-0 clr-neutral-80 fs-14 fw-medium animate-text-from-right"> By staking OWNX, you will join & contribute to the security of OwnXChain.
 </p>
        </div>
      </div>
      <div className="col-md-4 col-xl-4">
        <div className="px-lg-6 py-lg-8 rounded-3 fadeIn_bottom">
          <div className="d-grid place-content-center w-14 h-14 bg-neutral-variant-30 bg-opacity-5 rounded mb-6">
            <img src="assets/icon-1/sustainability.png" alt="image" className="img-fluid" />
          </div>
          <h6 className="clr-neutral-90 mb-2 reveal-text"> Sustainability 
</h6>
          <p className="mb-0 clr-neutral-80 fs-14 fw-medium animate-text-from-right"> Staking makes blockchain’s economy sustainable for the long term. </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
