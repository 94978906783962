import React from 'react'

export default function Validatormain() {
  return (
    <div>
      <div className="pricing-section-3 section-space-sm-y">
  <div className="section-space-xsm-bottom">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="pricing-section-3__avatar-group">
         
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section-space-sm-bottom">
    <div className="container">
      <div className="row">
        <div className="col-xl-7">
          <h3 className="mb-0 clr-neutral-90 fw-extrabold animate-line-3d" style={{perspective: '400px'}}> Rewards For <span className="d-inline-block gradient-flow gradient-flow--start">  Validators</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row g-4">
      <div className="col-md-4 featured-lists fadeIn_bottom" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
        <div className="p-4 bg-black rounded-5 border border-dashed border-neutral-80 border-opacity-4 position-relative z-1">
          <div className="top-shadow bg-neutral-6 p-4 p-lg-6 p-xl-10 rounded-4">
            <div className="mb-10 text-center">
              <h5 className="mb-10 clr-neutral-90 text-start"> Block Rewards
 </h5>
 <p className="clr-neutral-80 mb-6 fs-5 text-start">Validators receive block-rewards for every block they produce on OwnXChain. Block rewards are provided by minting genesis OWNX coins from the blockchain network itself. 
</p>
            </div>
         
          </div>
        </div>
      </div>
      <div className="col-md-4 featured-lists fadeIn_bottom" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
        <div className="p-4 bg-black rounded-5 border border-dashed border-neutral-80 border-opacity-4 position-relative z-1">
          <div className="top-shadow bg-neutral-6 p-4 p-lg-6 p-xl-10 rounded-4">
            <div className="mb-10 text-center">
              <h5 className="mb-10 clr-neutral-90 text-start"> Transactions Rewards
 </h5>
 <p className="clr-neutral-80 mb-6 fs-5 text-start">All the transactions have associated fees and it consists of a portion of a portion which goes to Validators. These fees are proof that a Validator has verified & processed the transaction.
</p>
            </div>
         
          </div>
        </div>
      </div>
      <div className="col-md-4 featured-lists fadeIn_bottom" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1}}>
        <div className="p-4 bg-black rounded-5 border border-dashed border-neutral-80 border-opacity-4 position-relative z-1">
          <div className="top-shadow bg-neutral-6 p-4 p-lg-6 p-xl-10 rounded-4">
            <div className="mb-10 text-center">
              <h5 className="mb-10 clr-neutral-90 text-start">Staking Rewards
 </h5>
 <p className="clr-neutral-80 mb-6 fs-5 text-start">Additionally, validators are also eligible to receive staking rewards on their initial deposit amount. OwnXChain currently offers up to 16% APR on validator staking deposits. 
</p>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="assets/img-1/pricing-section-3-shape-1.png" alt="image" className="img-fluid d-none d-xl-block position-absolute top-0 start-0 pointer-none z-n1" />
</div>



    </div>
  )
}
