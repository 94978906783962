import React from 'react'

export default function Takansupply() {
  return (
    <div>
   <div className="section-space-sm-y position-relative z-1 ">
  <div className="section-space-sm-bottom">
    <div className="container">
      <div className="row justify-content-center section-space-top">
        <div className="col-md-10 col-xl-8">
          <h3 className="mb-0 clr-neutral-90 text-center animate-line-3d"> Utility </h3>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row g-4 justify-content-center">
      <div className="col-md-6 col-lg-3">
        <div className="gradient-card top-shadow px-6 py-10 rounded-3 d-flex gap-5 flex-column justify-content-between h-100 fadein_bottom_20">
          <img src="assets/img-1/transaction_gas.png" alt="image" className="img-fluid" />
          <div className="d-flex flex-column gap-3 text-center">
            <h6 className="mb-0 clr-neutral-90 fs-18 reveal-text">Transaction Gas</h6>
            <p className="mb-0 clr-neutral-80 fs-14 reveal-text"> Sending transaction on OwnXChain requires OWNX</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-3">
        <div className="gradient-card top-shadow px-6 py-10 rounded-3 d-flex gap-5 flex-column justify-content-between h-100 fadein_bottom_20">
          <img src="assets/img-1/staking.png" alt="image" className="img-fluid" />
          <div className="d-flex flex-column gap-3 text-center">
            <h6 className="mb-0 clr-neutral-90 fs-18 reveal-text"> Staking  </h6>
            <p className="mb-0 clr-neutral-80 fs-14 reveal-text"> Users can stake OWNX & secure OwnXChain </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-3">
        <div className="gradient-card top-shadow px-6 py-10 rounded-3 d-flex gap-5 flex-column justify-content-between h-100 fadein_bottom_20">
          <img src="assets/img-1/payments.png" alt="image" className="img-fluid" />
          <div className="d-flex flex-column gap-3 text-center">
            <h6 className="mb-0 clr-neutral-90 fs-18 reveal-text">Payments  </h6>
            <p className="mb-0 clr-neutral-80 fs-14 reveal-text"> Making payments while using OWNX as transfer of value </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-3">
        <div className="gradient-card top-shadow px-6 py-10 rounded-3 d-flex gap-5 flex-column justify-content-between h-100 fadein_bottom_20">
          <img src="assets/img-1/governance.png" alt="image" className="img-fluid" />
          <div className="d-flex flex-column gap-3 text-center">
            <h6 className="mb-0 clr-neutral-90 fs-18 reveal-text"> Governance </h6>
            <p className="mb-0 clr-neutral-80 fs-14 reveal-text"> Supporting upgrades & developments in ecosystem </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="assets/img/payment-section-shape-1.png" alt="image" className="img-fluid position-absolute pointer-none position-center" />
  <img src="assets/img/payment-section-shape-2.png" alt="image" className="img-fluid position-absolute z-n1 top-0 end-0" />
</div>


    </div>
  )
}
