/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";

export default function Header() {
  const [show, setShow] = useState(true);
  const [activeMenus, setActiveMenus] = useState({
    access: false,
    developers: false,
    learn: false,
    community: false,
  });

  const toggleMenu = (menu) => {
    setActiveMenus((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === menu ? !prevState[menu] : false;
        return acc;
      }, {});
      return newState;
    });
  };

  return (
    <>
      <header className="header header--1 header--fixed">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav
                className={
                  show
                    ? "menu d-lg-flex justify-content-lg-between align-items-lg-center py-3 py-lg-0"
                    : "menu d-lg-flex justify-content-lg-between align-items-lg-center py-3 py-lg-0 menu-open"
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <a
                    href="/"
                    className="logo link d-inline-flex align-items-center flex-shrink-0"
                  >
                    <img
                      src="assets/img/ownx_logo_light.png"
                      alt="logo"
                      className="img-fluid object-fit-contain"
                    />
                  </a>
                  <button
                    className="menu-toggle w-10 h-10 p-2 border-0 lh-1 bg-primary-50 clr-neutral-100 :clr-neutral-100 transition :bg-primary-300 rounded flex-shrink-0 d-lg-none order-sm-3 fs-24"
                    onClick={() => setShow(!show)}
                  >
                    <i className="bi bi-list" />
                  </button>
                </div>
                <div className="menu-nav d-flex align-items-lg-center flex-column flex-lg-row flex-grow-1 gap-4 pb-4 pb-lg-0 rounded">
                  <ul className="list list-lg-row mx-lg-auto">
                    <li className="menu-list">
                      <a
                        href="#yes"
                        className={`link menu-link has-sub menu-link-active ${
                          activeMenus.access ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("access")}
                      >
                        Access
                      </a>
                      <ul
                        className={`list menu-sub ${
                          activeMenus.access ? "d-block" : "d-none"
                        }`}
                        id="yes"
                      >
                        <li className="menu-sub-list">
                          <a
                            href="/wallet"
                            className="link menu-sub-link gap-3 gap-4"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-wallet.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            OwnX Wallet
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="/coming-soon"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-explorer.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            OwnXChain Explorer
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="/staking"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-staking.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Staking
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="/validator"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-validator.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Validators
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="/ecosystem"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-ecosystem.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Ecosystem
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-list">
                      <a
                        href="#"
                        className={`link menu-link has-sub ${
                          activeMenus.developers ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("developers")}
                      >
                        Developers
                      </a>
                      <ul
                        className={`list menu-sub ${
                          activeMenus.developers ? "d-block" : "d-none"
                        }`}
                      >
                        <li className="menu-sub-list">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-documentation/guide/run-a-node"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-run_a_node.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Run a node
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="https://forms.gle/e8aTz6SGMmUQWhHy6"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-grants.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Grants
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="/accelerator"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-accelerator.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Accelerator
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-documentation/api-reference/public-api-endpoints"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-apis.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            APIs
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-list">
                      <a
                        href="#"
                        className={`link menu-link has-sub ${
                          activeMenus.learn ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("learn")}
                      >
                        Learn
                      </a>
                      <ul
                        className={`list menu-sub ${
                          activeMenus.learn ? "d-block" : "d-none"
                        }`}
                      >
                        <li className="menu-sub-list">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-whitepaper"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-whitepaper.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Whitepaper
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="https://drive.google.com/file/d/1lUq3r852ZVIiJ0OadsgNiN6urX5Dgwin/view?usp=sharing"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-litepaper.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Litepaper
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a
                            href="https://ownx.gitbook.io/ownxchain-documentation/"
                            target="_blank"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-documentation.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-list">
                      <a
                        href="#"
                        className={`link menu-link has-sub ${
                          activeMenus.community ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("community")}
                      >
                        Community
                      </a>
                      <ul
                        className={`list menu-sub ${
                          activeMenus.community ? "d-block" : "d-none"
                        }`}
                      >
                        <li className="menu-sub-list">
                          <a
                            href="/governance"
                            className="link menu-sub-link gap-3"
                          >
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-governance.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Governance
                          </a>
                        </li>
                        <li className="menu-sub-list">
                          <a href="#" className="link menu-sub-link gap-3">
                            <span className="d-none d-lg-inline-block">
                              <img
                                src="assets/icon-1/h-blogs.png"
                                alt="image"
                                className="img-fluid"
                              />
                            </span>
                            Blogs
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="list list-lg-row gap-4 gap-lg-6">
                    {/* <li className="menu-list mx-4 mx-lg-0">
                                            <a href="login.html" className="animated-border-btn link d-inline-flex justify-content-center rounded overflow-hidden position-relative z-1">
                                                <span className="d-inline-block transition bg-neutral-10 :bg-primary-key clr-white px-6 py-2 fw-bold text-center rounded"> Login </span>
                                            </a>
                                        </li> */}
                    <li className="menu-list mx-4 mx-lg-0">
                      <a
                        href="/accelerator"
                        className="gradient-btn-1 position-relative z-1 link d-inline-flex justify-content-center px-4 py-2 rounded fw-bold text-center"
                      >
                        {" "}
                        Accelerator{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
