import React from "react";
import Header from "../Comman/Header";
import FTMTokenhero from "./FTMTokenhero";
import Tokansecurity from "./Tokansecurity";
import Tokantab from "./Tokantab";
import Takansupply from "./Takansupply";
import Footer from "../Comman/Footer";
import Tokanpre from "./Tokanpre";

export default function FTMToken() {
  return (
    <div>
      <Header />
      <FTMTokenhero />
      <Takansupply />
      <Tokanpre />
      <Tokansecurity />
      {/* <Tokantab/> */}
      <Footer />
    </div>
  );
}
