import React from 'react'

export default function Roadmapmain() {
  return (
    <div>
        <div className="featured-section section-space-top section-space-sm-bottom">
  <div className="container">
    <div className="row gy-5 d-flex justify-content-center">
     
      <div className="col-lg-4">
        <div data-bs-spy="scroll" data-bs-target="#featured-block-list" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
          <div className="featured-block" id="featured-block-1">
            <div className="d-flex flex-wrap align-items-center gap-6">
              <div className="w-14 h-14 rounded-circle d-flex justify-content-center align-items-center clr-primary-key featured-title-icon-bg h5 mb-0">
                <i className="bi bi-gem" />
              </div>
              <h3 className="h4 clr-neutral-90 fw-bold mb-0">2024</h3>
            </div>
            <div className="ps-lg-20">
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H1</h5>
                <ul className="clr-neutral-80 ">
                    <li>Research, Initialization & Whitepaper  </li>
                    <li>Website Launch</li>
                    <li className='clr-neutral-90'><b>OWNX Token Launch</b></li>
                    <li>Community Building</li>
                </ul>
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H2</h5>
                <ul className="clr-neutral-80 ">
                    <li><b>Blockchain Testnet Launch</b></li>
                    <li>Listing OWNX on Coin Tracking Platforms</li>
                    <li>Marketing Campaigns</li>
                    <li><b>OwnXChain Mainnet Launch</b></li>
                </ul>
              {/* <p className="clr-neutral-80 mt-3">lets you edit and write code with the AI. To edit, try selecting some code, click "Edit," and describe how the code should be changed. To generate completely new code, just type Command K without selecting anything. With great teams come great processes. Using our developer API, create and publish extensions to your organization.</p> */}
            </div>
          </div>{/* featured-block end */}
          <div className="featured-block" id="featured-block-2">
            <div className="d-flex flex-wrap align-items-center gap-6">
              <div className="w-14 h-14 rounded-circle d-flex justify-content-center align-items-center clr-primary-key featured-title-icon-bg h5 mb-0">
                <i className="bi bi-gem" />
              </div>
              <h3 className="h4 clr-neutral-90 fw-bold mb-0">2025</h3>
            </div>
            <div className="ps-lg-20">
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H1</h5>
                <ul className="clr-neutral-80 ">
                    <li><b>OWNX token listing on Exchange</b> </li>
                    <li>Validators Onboarding</li>
                    <li>Launching Liquidity Pools for OWNX</li>
                    <li><b>Web Wallet Launch</b></li>
                </ul>
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H2</h5>
                <ul className="clr-neutral-80 ">
                    <li><b>OwnXChain Explorer Launch</b> </li>
                    <li>Collaborations With Web3 Developer Firms</li>
                    <li>Liquidity Unlock & Market Making</li>
                    <li><b>Decentralized Exchange Launch</b></li>
                </ul>
              {/* <p className="clr-neutral-80 mt-3">lets you edit and write code with the AI. To edit, try selecting some code, click "Edit," and describe how the code should be changed. To generate completely new code, just type Command K without selecting anything. With great teams come great processes. Using our developer API, create and publish extensions to your organization.</p> */}
            </div>
          </div>{/* featured-block end */}
          <div className="featured-block" id="featured-block-3">
            <div className="d-flex flex-wrap align-items-center gap-6">
              <div className="w-14 h-14 rounded-circle d-flex justify-content-center align-items-center clr-primary-key featured-title-icon-bg h5 mb-0">
                <i className="bi bi-gem" />
              </div>
              <h3 className="h4 clr-neutral-90 fw-bold mb-0">2026</h3>
            </div>
            <div className="ps-lg-20">
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H1</h5>
                <ul className="clr-neutral-80 ">
                    <li>Staking Plans</li>
                    <li>Rewards for Early Contributors</li>
                    <li>Token Integration to Payment Gateways</li>
                    <li><b>Ecosystem Grants</b></li>
                </ul>
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H2</h5>
                <ul className="clr-neutral-80 ">
                    <li>KOLs Onboarding</li>
                    <li>Airdrop & Community Rewards</li>
                    <li>Tokenization Platform 
</li>
                    <li><b>Launch of NFT Marketplace </b>
</li>
                </ul>
              {/* <p className="clr-neutral-80 mt-3">lets you edit and write code with the AI. To edit, try selecting some code, click "Edit," and describe how the code should be changed. To generate completely new code, just type Command K without selecting anything. With great teams come great processes. Using our developer API, create and publish extensions to your organization.</p> */}
            </div>
          </div>{/* featured-block end */}
          <div className="featured-block" id="featured-block-4">
            <div className="d-flex flex-wrap align-items-center gap-6">
              <div className="w-14 h-14 rounded-circle d-flex justify-content-center align-items-center clr-primary-key featured-title-icon-bg h5 mb-0">
                <i className="bi bi-gem" />
              </div>
              <h3 className="h4 clr-neutral-90 fw-bold mb-0">2027</h3>
            </div>
            <div className="ps-lg-20">
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H1</h5>
                <ul className="clr-neutral-80 ">
                    <li><b>OwnXChain Mobile Wallet Launch</b></li>
                    <li>OwnXChain integrations to Leading Crypto Exchanges</li>
                    <li>Airdrop Release </li>
                    <li>DeFi Banking Platform Launch </li>
                </ul>
                <h5 className='clr-neutral-90 fw-bold mb-0 mt-3'> H2</h5>
                <ul className="clr-neutral-80 ">
                    <li><b>OwnxChain Cross-Bridge Launch</b> </li>
                    <li>Governance Initiatives</li>
                    <li>Ecosystem Expansion
</li>
                 
                </ul>
              {/* <p className="clr-neutral-80 mt-3">lets you edit and write code with the AI. To edit, try selecting some code, click "Edit," and describe how the code should be changed. To generate completely new code, just type Command K without selecting anything. With great teams come great processes. Using our developer API, create and publish extensions to your organization.</p> */}
            </div>
          </div>{/* featured-block end */}
          <div className="featured-block" id="featured-block-5">
            <div className="d-flex flex-wrap align-items-center gap-6">
              <div className="w-14  d-flex justify-content-center align-items-center clr-primary-key featured-title-icon-bg h5 mb-0">
              </div>
            </div>
        
          </div>{/* featured-block end */}
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
